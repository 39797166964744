import axios from 'axios';
import { toolUtils } from '../../../../utils/toolUtils.js'
import { Tag, Typography, Popconfirm, message, Spin, Modal, Button, Row, Col, Image } from 'antd';
import SubmittedPaperTypeUpsertPage from './SubmittedPaperTypeUpsertPage.js'
import moment from 'moment';
import {
    CloseOutlined,
    CheckCircleOutlined,
    DragOutlined
} from '@ant-design/icons';

export default {
    generateSubmittedPaperTypeMoveMenu: function (props, setFuncMap) {
        let menu = this.generateSubmittedPaperTypeMenu(props, setFuncMap)
        return [
            {
                title: ' ',
                dataIndex: 'sort',
                key: 'sortIcon',
                width: 60,
                render: (lang) => {
                    return <Tag color="#d8780a" key={lang}>
                        <DragOutlined />
                    </Tag>
                }
            }, ...menu]
    },
    generateSubmittedPaperTypeMenu: function (props, setFuncMap) {
        const { Link } = Typography;
        return [
            {
                title: ' ',
                dataIndex: 'sequence',
                key: 'sequence',
                width: 20,
            },
            {
                title: '稿件類別',
                dataIndex: 'name',
                key: 'name',
                width: 400,
            },
            {
                title: "",
                dataIndex: 'id',
                key: 'id',
                width: 120,
                render: (_, render) => {
                    return <><Button size="small" style={{ marginRight: "5px" }}
                        onClick={() => { this.openSubmittedPaperTypeUpdatePage(props.conferenceUid, render.id, "更新稿件類別", setFuncMap) }}>編輯</Button>
                        <Popconfirm
                            placement="left"
                            title={`確認要刪除 ${render.name}?`}
                            onConfirm={() => { this.deleteSubmittedPaperType(render.id, props, setFuncMap) }}
                            okText="Yes"
                            cancelText="No"
                        ><Button size="small" type="primary" danger><CloseOutlined /></Button>
                        </Popconfirm>
                    </>
                }
            }]
    },
    getSubmittedPaperTypes: async function (props, setFuncMap) {
        try {
            setFuncMap.setIsTableLoading(true)
            const res = await this.getSubmittedPaperTypesAction(props.conferenceUid)
            let submittedPaperTypes = res.data
            submittedPaperTypes = this.getSortedSubmittedPaperTypes(submittedPaperTypes)
            submittedPaperTypes = submittedPaperTypes.map((submittedPaperType, i) => {
                return { sequence: (i + 1), ...submittedPaperType }
            })
            setFuncMap.setSubmittedPaperTypes(submittedPaperTypes)
            setFuncMap.setIsTableLoading(false)
            setFuncMap.setIsPageLoading(false)
        } catch (error) {
            setFuncMap.setIsTableLoading(false)
            // message.error(error.response.data)
        }
    }, getSubmittedPaperTypesAction: (conferenceUid) => {
        return axios({
            method: 'GET',
            url: `/conference/${conferenceUid}/submitted-paper-types`,
            headers: {
                "role": "topUser"
            }
        })
    },
    getConference: async function (props, setFuncMap) {
        try {
            const res = await this.getConferenceByUidAction(props.conferenceUid)
            props.setContentHeader(res.data.name)
            setFuncMap.setIsPageLoading(false)
        } catch (error) {
        }
    },
    getConferenceByUidAction: (conferenceUid) => {
        return axios({
            method: 'GET',
            url: `/conference/${conferenceUid}`,
            headers: {
                "role": "topUser"
            }
        })
    },
    openSubmittedPaperTypeCreatePage: (conferenceUid, modalName, setFuncMap) => {
        setFuncMap.setIsModalOpen(true)
        setFuncMap.setModalTitle(modalName)
        setFuncMap.setModalContent(<SubmittedPaperTypeUpsertPage conferenceUid={conferenceUid} submittedPaperTypeData={null} setFuncMap={setFuncMap} />)
    },
    openSubmittedPaperTypeUpdatePage: (conferenceUid, id, modalName, setFuncMap) => {
        setFuncMap.setIsModalOpen(true)
        setFuncMap.setModalTitle(modalName)
        setFuncMap.setModalContent(<SubmittedPaperTypeUpsertPage conferenceUid={conferenceUid} id={id} submittedPaperTypes={null} setFuncMap={setFuncMap} />)
    },
    getSortedSubmittedPaperTypes: function (submittedPaperTypes) {
        return submittedPaperTypes.sort((a, b) => a.sort - b.sort)
    },
    updateSubmittedPaperTypesSort: async function (payload, props, setFuncMap) {
        try {
            setFuncMap.setIsButtonSortLoading(true)
            setFuncMap.setIsTableLoading(true)
            const res = await this.updateSubmittedPaperTypesSortAction(payload, props.conferenceUid)
            await this.getSubmittedPaperTypes(props, setFuncMap)
            setFuncMap.setSubmittedPaperTypesSortChange([])
            setFuncMap.setIsButtonSortLoading(false)
            setFuncMap.setIsTableLoading(false)
        } catch (error) {
        }
    },
    updateSubmittedPaperTypesSortAction: (payload, conferenceUid) => {
        return axios({
            method: 'POST',
            url: `/conference/${conferenceUid}/submitted-paper-types/sort`,
            headers: {
                "role": "topUser"
            },
            data: payload
        })
    },
    deleteSubmittedPaperType: async function (id, props, setFuncMap) {
        try {
            setFuncMap.setIsTableLoading(true)
            const res = await this.deleteSubmittedPaperTypeAction(id, props.conferenceUid)
            await this.getSubmittedPaperTypes(props, setFuncMap)
            setFuncMap.setIsTableLoading(false)
        } catch (error) {
        }
    },
    deleteSubmittedPaperTypeAction: (id, conferenceUid) => {
        return axios({
            method: 'DELETE',
            url: `/conference/${conferenceUid}/submitted-paper-type/${id}`,
            headers: {
                "role": "topUser"
            }
        })
    }

};
