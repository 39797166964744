import axios from 'axios';
import { Tag, Typography, Popconfirm, message, Spin, Modal, Button, Row, Col, Card } from 'antd';
import {
  CloseOutlined,
  CheckCircleOutlined,
  DragOutlined
} from '@ant-design/icons';

export default {
  getRegistrationFee: async function (setFuncMap) {
    try {
      const res = await this.getRegistrationFeeAction()
      setFuncMap.setRegistrationFee(res.data)
      setFuncMap.setIsPageLoading(false)
    } catch (error) {
    }
  },
  getRegistrationFeeAction: () => {
    return axios({
      method: 'GET',
      url: `/site/registration-fee`
    })
  }
};
