export default {
    langsWithAll: [{
        key: "All"
    }, {
        key: "CN",
        color: "#d3adf7"
    }, {
        key: "EN",
        color: "#b7eb8f"
    }],
    langs: [{
        key: "CN",
        color: "#d3adf7"
    }, {
        key: "EN",
        color: "#b7eb8f"
    }]
}