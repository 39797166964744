import React, { useEffect, useState } from 'react';
// import 'antd/dist/antd.css';
import { Table, Typography, message, Spin, Modal, Button } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import efn from './ConferencesFns.js'
import './Conferences.scss';
const { Text } = Typography;
function Conference(props) {
  const navigate = useNavigate()
  const location = useLocation()
  const [conferences, setConferences] = useState([])
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [waitUpdateData, setWaitUpdateData] = useState({ before: null, after: null })
  const setFuncMap = { setConferences, setIsPageLoading, setIsTableLoading, setIsModalOpen, setWaitUpdateData }
  const columnsParams = {
    conferences, setFuncMap, location, navigate, getReferenceByUid: efn.getReferenceByUid
  }
  const columns = efn.generateConferenceColumns(columnsParams)

  useEffect(() => {
    efn.getConferences(setFuncMap)
    props.setContentHeader(<></>)

  }, []);

  return (
    <Spin spinning={isPageLoading}>
      <div className="Conferences">
        <Spin spinning={isTableLoading}>
          <Table dataSource={conferences} columns={columns} />
        </Spin>
        <Modal title="變更研討會名稱" open={isModalOpen}
          onOk={() => {
            efn.updateReferenceNameByUid({
              waitUpdateData, conferences
            }, setFuncMap)
          }}
          onCancel={() => { setIsModalOpen(false) }}>
          {`${waitUpdateData.before} => ${waitUpdateData.after}`}
        </Modal>
      </div>
    </Spin>
  );
}


export default Conference;
