import React, { useState, useEffect } from 'react';
import { DataContext } from '../../../../Context/DataContext.js';
import efn from './CallForPapersGroupUpdatePageFns.js'
import DraggableTagList from './DraggableTagList.js'
import pfn from './CallForPapersFns.js'
import { toolUtils } from '../../../../utils/toolUtils.js'
import { Spin, Form, Collapse, Upload, DatePicker, Select, Input, Button, Row, Col } from 'antd';
import {
  UploadOutlined
} from '@ant-design/icons';

const { TextArea } = Input;
const { Option } = Select;

const CallForPapersGroupUpdatePage = (props) => {
  const {t} = React.useContext(DataContext)
  const [form] = Form.useForm();
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [groupCompleteData, setGroupCompleteData] = useState({
    group: { types: [] },
    unsetTypes: []
  })
  const [fileList, setFileList] = useState([])
  const setFuncMap = {
    setIsModalLoading,
    setFileList,
    setGroupCompleteData,
    setFieldsValue: form.setFieldsValue, t,
    ...props.setFuncMap
  }
  const onFinish = values => {
    efn.updateCallForPapersGroup(props.conferenceUid, values, groupCompleteData, setFuncMap)
  };
  useEffect(() => {
    if (props.id) {
      setIsModalLoading(true)
      efn.getCallForPapersGroupCompleteById(props.conferenceUid, props.id, setFuncMap)
      setIsModalLoading(false)
    }
  }, [props.id])
  return (
    <Spin spinning={isModalLoading}>
      <Form
        form={form}
        layout="horizontal"
        onFinish={onFinish}
      >
        <Form.Item
          label="徵稿群組"
          name="name"
          rules={[
            {
              required: true,
              message: "This field is required."
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Row>
          <Col span={24}>
            <Collapse style={{ marginBottom: "8px" }} defaultActiveKey={["groupTypes"]}
              items={[{
                key: 'groupTypes',
                label: `徵稿類型 ${groupCompleteData.group.types.length > 0 ? `[${groupCompleteData.group.types.length}]` : ""}`,
                children: <DraggableTagList groupCompleteData={groupCompleteData} setFuncMap={setFuncMap} />
                // <div className="types">
                //   {groupCompleteData.group.types.map(type => {
                //     return efn.generateRemoveTypeFromGroupItem(groupCompleteData, type, setFuncMap)
                //   })}
                // </div>,
              }]} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Collapse style={{ marginBottom: "8px" }}
              items={[{
                key: 'notSetInGroupTypes',
                label: `未設置徵稿類型 ${groupCompleteData.unsetTypes.length > 0 ? `[${groupCompleteData.unsetTypes.length}]` : ""}`,
                children: <div className="types">
                  {groupCompleteData.unsetTypes.map((unsetType, index) => {
                    return efn.generateAddTypeToGroupItem(groupCompleteData, unsetType, setFuncMap)
                  })}
                </div>,
              }]} />
          </Col>
        </Row>
        <Row justify={"center"} gutter={16}>
          <Col>
            <Form.Item>
              <Button type="default" onClick={() => {
                setFuncMap.setIsModalOpen(false)
              }}>
                取消
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              {props.id ?
                <Button type="primary" htmlType="submit">
                  更新
                </Button> :
                <Button type="primary" htmlType="submit">
                  建立
                </Button>}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>{props.id && <Form.Item
            name="id"
            hidden
          >
            <Input type="hidden" />
          </Form.Item>}
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default CallForPapersGroupUpdatePage;