import React, { useState, useEffect } from 'react';
import efn from './AnnouncementUpsertPageFns.js'
import { toolUtils } from '../../../../utils/toolUtils'
import CKEditorUtil from '../../../../utils/ckEditorUtil'
import { Spin, Form, Switch, DatePicker, Select, Input, Button, Row, Col } from 'antd';
import { DataContext } from '../../../../Context/DataContext.js';

const { TextArea } = Input;
const { Option } = Select;

const AnnouncementUpsertPage = (props) => {
  const {t} = React.useContext(DataContext)
  const [form] = Form.useForm();
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [editorData, setEditorData] = useState();
  const setFuncMap = {
    setIsModalLoading,
    setEditorData,
    setFieldsValue: form.setFieldsValue,
    t,
    ...props.setFuncMap
  }
  const onFinish = values => {
    console.log('Form values:', values);
    values["content"] = editorData
    if (props.id) {
      efn.updateAnnouncement(props.conferenceUid, values, setFuncMap)
    }
    else {
      efn.createAnnouncement(props.conferenceUid, values, setFuncMap)
    }
  };
  useEffect(() => {
    if (props.id) {
      setIsModalLoading(true)
      efn.getAnnouncementById(props.conferenceUid, props.id, setFuncMap)
      setIsModalLoading(false)
    }
  }, [props.id])

  return (
    <Spin spinning={isModalLoading}>
      <Form
        form={form}
        layout="horizontal"
        onFinish={onFinish}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="開始顯示時間"
              name="startAt"
            >
              <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="結束顯示時間"
              name="endAt"
            >
              <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label="標題"
          name="title"
          rules={[{ required: true, message: '請輸入標題' }]}
        >
          <Input />
        </Form.Item>
        <CKEditorUtil setFuncMap={setFuncMap} defaultValue={editorData} />
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="語系"
              name="lang"
              rules={[{ required: true, message: '請選擇語系' }]}
            >
              <Select placeholder="請選擇語系">
                <Option value="EN">EN</Option>
                <Option value="CN">CN</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="是否置頂"
              name="isTop"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="是否顯示"
              name="isShow"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row justify={"center"} gutter={16}>
          <Col>
            <Form.Item>
              <Button type="default" onClick={() => {
                setFuncMap.setIsModalOpen(false)
              }}>
                取消
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              {props.id ?
                <Button type="primary" htmlType="submit">
                  更新
                </Button> :
                <Button type="primary" htmlType="submit">
                  建立
                </Button>}
            </Form.Item>
          </Col>
        </Row>
        {props.id && <Form.Item
          name="id"
          hidden
        >
          <Input type="hidden" />
        </Form.Item>}
      </Form>
    </Spin>
  );
};

export default AnnouncementUpsertPage;