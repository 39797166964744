import axios from 'axios';
import { toolUtils } from '../../../../utils/toolUtils.js'
import pfn from './SubmissionPaperFns.js'
import statusCode from '../../../../enum/submissionPaperStatusEnum.js'
export default {
  approveSubmissionPaper: async function (conferenceUid, id, status, setFuncMap) {
    try {
      setFuncMap.setIsModalLoading(true)
      if (["WAIT_VERIFY", "REWAIT_VERIFY"].indexOf(statusCode[status])>-1) {
        const res = await this.approveSubmissionPaperAction(conferenceUid, id)
      }
      else {
        const res = await this.approveSubmissionPaperFinalVersionAction(conferenceUid, id)
      }
      pfn.getSubmissionPapers(conferenceUid, setFuncMap)
      setFuncMap.setIsModalLoading(false)
      setFuncMap.message.open({
        key: "create",
        type: 'success',
        content: 'Update success',
        duration: 2,
      });
      setFuncMap.setModalConfig({
        isOpen: false,
      })
    } catch (error) {
      setFuncMap.setIsModalLoading(false)
      setFuncMap.message.open({
        type: 'error',
        content: setFuncMap.t(`error.${error.response.status}`),
        duration: 2,
      });
      console.log(error)
    }
  },
  approveSubmissionPaperAction: (conferenceUid, id) => {
    return axios.put(`/conference/${conferenceUid}/submission-paper/${id}/approve`, {}, {
      headers: {
        "role": "topUser"
      }
    })
  },
  approveSubmissionPaperFinalVersionAction: (conferenceUid, id) => {
    return axios.put(`/conference/${conferenceUid}/submission-paper/${id}/approve/final-version`, {}, {
      headers: {
        "role": "topUser"
      }
    })
  },
  rejectSubmissionPaper: async function (conferenceUid, reason, id, status, setFuncMap) {
    if (!reason) {
      setFuncMap.message.open({
        type: 'error',
        content: '請輸入理由',
        duration: 2,
      });
      return
    }
    try {
      setFuncMap.setIsModalLoading(true)
      if (["WAIT_VERIFY", "REWAIT_VERIFY"].indexOf(statusCode[status])>-1) {
        const res = await this.rejectSubmissionPaperAction(conferenceUid, { reason }, id)
      }
      else {
        const res = await this.rejectSubmissionPaperFinalVersionAction(conferenceUid, { reason }, id)
      }

      pfn.getSubmissionPapers(conferenceUid, setFuncMap)
      setFuncMap.setIsModalLoading(false)
      setFuncMap.message.open({
        type: 'success',
        content: 'Update success',
        duration: 2,
      });
      setFuncMap.setModalConfig({
        isOpen: false,
      })
    } catch (error) {
      setFuncMap.setIsModalLoading(false)
      setFuncMap.message.open({
        type: 'error',
        content: setFuncMap.t(`error.${error.response.status}`),
        duration: 2,
      });
      console.log(error)
    }
  },
  rejectSubmissionPaperAction: (conferenceUid, payload, id) => {
    return axios.put(`/conference/${conferenceUid}/submission-paper/${id}/reject`, payload, {
      headers: {
        "role": "topUser"
      }
    })
  },
  rejectSubmissionPaperFinalVersionAction: (conferenceUid, payload, id) => {
    return axios.put(`/conference/${conferenceUid}/submission-paper/${id}/reject/final-version`, payload, {
      headers: {
        "role": "topUser"
      }
    })
  },
}