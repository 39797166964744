import axios from 'axios';
import { toolUtils } from '../../../../utils/toolUtils.js'
import pfn from './ReviewResultTypeFns.js'
import { message, Upload } from 'antd';
export default {
  createReviewResultType: async function (conferenceUid, values, setFuncMap) {
    let payload = {
      "name": values.name,
    }
    setFuncMap.setIsModalLoading(true)
    const res = await this.createReviewResultTypeAction(conferenceUid, payload)
    pfn.getReviewResultTypes({ conferenceUid }, setFuncMap)
    setFuncMap.setIsModalLoading(false)
    setFuncMap.setIsModalOpen(false)
    setFuncMap.message.open({
      key: "create",
      type: 'success',
      content: 'Create success',
      duration: 2,
    });
  },
  createReviewResultTypeAction: (conferenceUid, payload) => {
    return axios.post(`/conference/${conferenceUid}/review-result-type`, payload, {
      headers: {
        "role": "topUser"
      }
    })
  },
  getReviewResultTypeById: async function (conferenceUid, id, setFuncMap) {
    try {
      const res = await this.getReviewResultTypeByIdAction(conferenceUid, id);
      let data = res.data
      if (data) {
        setFuncMap.setFieldsValue(data)
      }
    }
    catch (e) {

    }

  },
  getReviewResultTypeByIdAction: (conferenceUid, id) => {
    return axios.get(`/conference/${conferenceUid}/review-result-type/${id}`, {
      headers: {
        "role": "topUser"
      }
    })
  },
  updateReviewResultType: async function (conferenceUid, values, setFuncMap) {
    let payload = {
      "id": values.id,
      "name": values.name,
    }
    setFuncMap.setIsModalLoading(true)
    console.log({ payload })
    const res = await this.updateReviewResultTypeAction(conferenceUid, payload)
    pfn.getReviewResultTypes({ conferenceUid }, setFuncMap)
    setFuncMap.setIsModalLoading(false)
    setFuncMap.setIsModalOpen(false)
    setFuncMap.message.open({
      key: "update",
      type: 'success',
      content: 'Update success',
      duration: 2,
    });
  },
  updateReviewResultTypeAction: (conferenceUid, payload) => {
    return axios.post(`/conference/${conferenceUid}/review-result-type/${payload.id}`, payload, {
      headers: {
        "role": "topUser"
      }
    })
  }
}