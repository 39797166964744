import React, { useState, useEffect } from 'react';
import efn from './AdminSubmissionPaperUpsertPageFns.js'
import { useNavigate, useLocation } from 'react-router-dom';
import { Spin, Form, Checkbox, Switch, DatePicker, Select, Input, Button, Row, Col } from 'antd';
import { toolUtils } from '../../../../utils/toolUtils.js'
import { DataContext } from '../../../../Context/DataContext.js';
import './AdminSubmissionPaperUpsertPage.scss';
const { TextArea } = Input;
const { Option } = Select;

const AdminSubmissionPaperUpsertPage = (props) => {
  const { t } = React.useContext(DataContext)
  const [form] = Form.useForm();
  const [isModalLoading, setIsModalLoading] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const [selectedCallForPaperGroupId, setSelectedCallForPaperGroupId] = useState(-1)
  const [paperTypeConfig, setPaperTypeConfig] = useState({
    paperTypes: [],
    callForPaperGroups: []
  })
  const [callForPaperTypes, setCallForPaperTypes] = useState([])
  const [userSubmissionPaperData, setUserSubmissionPaperData] = useState({})
  const [selectedValue, setSelectedValue] = useState(null);
  const [cnAbstractHelp, setCnAbstractHelp] = useState({
    helpWord: null,
    status: "success"
  })
  const [enAbstractHelp, setEnAbstractHelp] = useState({
    helpWord: null,
    status: "success"
  })
  const setFuncMap = {
    setIsModalLoading,
    form,
    setFieldsValue: form.setFieldsValue,
    setPaperTypeConfig,
    setCallForPaperTypes,
    setSelectedCallForPaperGroupId,
    navigate,
    setSelectedValue,
    t,
    setUserSubmissionPaperData,
    cnAbstractHelp, setCnAbstractHelp,
    enAbstractHelp, setEnAbstractHelp,
    ...props.setFuncMap
  }
  const handleCheckboxChange = (value) => {
    if (selectedValue === value) {
      setSelectedValue(null); // 重复点击取消选择
    } else {
      setSelectedValue(value); // 选择新的 Checkbox
    }
  };
  const onFinish = values => {
    console.log('Form values:', values);
    efn.adminUpdateUserSubmissionPaper(props.conferenceUid, values, setFuncMap)
  };
  useEffect(() => {
    setIsModalLoading(true)
    efn.getSubmissionPaperById(props.id, props.conferenceUid, setFuncMap)
    setIsModalLoading(false)
  }, [])

  useEffect(() => {
    form.setFieldsValue({
      defaultContactAuthor: selectedValue
    })
    efn.initUserSubmissionPaperFormValidation(setFuncMap)
  }, [selectedValue])

  let filteredCallForPaperTypes = (selectedCallForPaperGroupId > -1) ? callForPaperTypes.filter(callForPaperType => {
    return callForPaperType.groupId === selectedCallForPaperGroupId
  }) : callForPaperTypes

  return (
    <Spin spinning={isModalLoading}>
      <div className="AdminSubmissionPaperUpsertPage">
        <Form
          form={form}
          layout="horizontal"
          onFinish={onFinish}
          onValuesChange={() => {
            efn.initUserSubmissionPaperFormValidation(setFuncMap)
          }}
        >
          <Row gutter={16}>
            <Col span={24}>
              {efn.generateSubmissionPaperAttribute(paperTypeConfig, filteredCallForPaperTypes, userSubmissionPaperData, setFuncMap).map((item, key) => {
                return item.class ? <Row key={key} gutter={16}>
                  {item.items.map((card, key) => {
                    return <Col key={key} span={8}>
                      <div className={card.class}>
                        {card.items.map((attr, key) => {
                          return <Form.Item key={key}
                            name={attr.name}
                            label={attr.label}
                            validateStatus={attr.validateStatus}
                            help={attr.help}
                            rules={[{ required: attr.required, message: `${t("home.user.paper.content.hint.word")}${attr.label}` }, ...attr.rules]}
                          >
                            {attr.type === "text" && <Input disabled={attr.disabled} placeholder={attr.label} size="large" />}
                            {attr.type === "checkbox" && <Checkbox
                              disabled={attr.disabled}
                              checked={selectedValue === attr.name}
                              onChange={() => handleCheckboxChange(attr.name)}
                            ></Checkbox>}
                          </Form.Item>
                        })}
                      </div>
                    </Col>
                  })}
                </Row> :
                  <Form.Item key={key}
                    name={item.name}
                    label={item.label}
                    validateStatus={item.validateStatus}
                    help={item.help}
                    rules={[{ required: item.required, message: `${t("home.user.paper.content.hint.word")}${item.label}` }, ...item.rules]}
                  >
                    {item.type === "text" && <Input disabled={item.disabled} placeholder={item.label} size="large" />}
                    {item.type === "text-tag" && <Select
                      disabled={item.disabled}
                      mode="tags"
                      placeholder={item.placeHolder || item.label}
                      style={{
                        width: '100%',
                      }}
                      tokenSeparators={[';']}
                      options={[]}
                    />}
                    {item.type === "select" && <Select disabled={item.disabled} showSearch filterOption={toolUtils.filterOption} onChange={item.onChange} options={item.options} />}
                    {item.type === "textarea" && <Input.TextArea
                      disabled={item.disabled}
                      autoSize={{ minRows: 5, maxRows: 10 }}
                      placeholder={item.label} />}
                  </Form.Item>
              })}
              <Form.Item
                name="defaultContactAuthor"
                hidden
              >
                <Input type="hidden" />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={"center"} gutter={16}>
            <Col>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {t("home.user.paper.content.button.update")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="id"
            hidden
          >
            <Input type="hidden" />
          </Form.Item>
        </Form>
      </div>
    </Spin>
  );
};

export default AdminSubmissionPaperUpsertPage;