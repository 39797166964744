import axios from 'axios';
import { toolUtils } from '../../../../utils/toolUtils'
import { Tag, Typography, Popconfirm, message, Spin, Modal, Button, Row, Col, Card } from 'antd';
import AnnouncementUpsertPage from './AnnouncementUpsertPage.js'
import moment from 'moment';
import {
    CloseOutlined,
    CheckCircleOutlined,
    DragOutlined
} from '@ant-design/icons';

export default {
    generateAnnouncementMoveMenu: function (props, setFuncMap) {
        let menu = this.generateAnnouncementMenu(props, setFuncMap)
        return [
            {
                title: ' ',
                dataIndex: 'sort',
                key: 'sortIcon',
                width: 60,
                render: (lang) => {
                    return <Tag color="#d8780a" key={lang}>
                        <DragOutlined />
                    </Tag>
                }
            }, ...menu]
    },
    generateAnnouncementMenu: function (props, setFuncMap) {
        const { Text } = Typography;
        return [
            {
                title: '語系',
                dataIndex: 'lang',
                key: 'lang',
                width: 60,
                render: (lang) => {
                    return <Tag color={lang === "EN" ? "green" : "purple"} key={lang}>
                        {lang ? lang.toUpperCase() : null}
                    </Tag>
                }
            },
            {
                title: '置頂',
                dataIndex: 'isTop',
                key: 'isTop',
                width: 60,
                render: (isTop) => {
                    return isTop === 1 ? <Text type="warning"><CheckCircleOutlined /></Text> : <></>
                }
            },
            {
                title: '顯示',
                dataIndex: 'isShow',
                key: 'isShow',
                width: 60,
                render: (isShow) => {
                    return isShow === 1 ? <Text type="warning"><CheckCircleOutlined /></Text> : <></>
                }
            },
            {
                title: '標題',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: '顯示時間',
                dataIndex: 'startAt',
                key: 'startAt',
                width: 150,
                render: (_, render) => {
                    return this.generateShowTimeRangeContent(render)
                }
            }, {
                title: "",
                dataIndex: 'id',
                key: 'id',
                width: 120,
                render: (_, render) => {
                    return <><Button size="small" style={{ marginRight: "5px" }}
                        onClick={() => { this.openAnnouncementUpdatePage(props.conferenceUid, render.id, "更新公告", setFuncMap) }}>編輯</Button>
                        <Popconfirm
                            placement="left"
                            title={`確認要刪除 ${render.title}?`}
                            onConfirm={() => { this.deleteAnnouncement(render.id, props, setFuncMap) }}
                            okText="Yes"
                            cancelText="No"
                        ><Button size="small" type="primary" danger><CloseOutlined /></Button>
                        </Popconfirm>
                    </>
                }
            }]
    },
    generateShowTimeRangeContent: function (render) {
        if (render.startAt && render.endAt) {
            return <><Tag color="gold">開始 {moment(render.startAt).format("YYYY-MM-DD HH:mm:ss")}</Tag><Tag color="red">結束 {moment(render.endAt).format("YYYY-MM-DD HH:mm:ss")}</Tag></>
        }

        if (render.startAt) {
            return <Tag color="gold">開始 {moment(render.startAt).format("YYYY-MM-DD HH:mm:ss")}</Tag>
        }

        if (render.endAt) {
            return <Tag color="gold">結束 {moment(render.endAt).format("YYYY-MM-DD HH:mm:ss")}</Tag>
        }

        return <Tag color="lightgrey">未設置</Tag>

    },
    getAnnouncements: async function (props, setFuncMap) {
        try {
            setFuncMap.setIsTableLoading(true)
            const res = await this.getAnnouncementsAction(props.conferenceUid)
            let announcements = res.data
            setFuncMap.setAnnouncements(announcements)
            setFuncMap.setIsTableLoading(false)
            setFuncMap.setIsPageLoading(false)
        } catch (error) {
            setFuncMap.setIsTableLoading(false)
            // message.error(error.response.data)
        }
    }, getAnnouncementsAction: (conferenceUid) => {
        return axios({
            method: 'GET',
            url: `/conference/${conferenceUid}/announcements`,
            headers: {
                "role": "topUser"
            }
        })
    },
    getConference: async function (props, setFuncMap) {
        try {
            const res = await this.getConferenceByUidAction(props.conferenceUid)
            props.setContentHeader(res.data.name)
            setFuncMap.setIsPageLoading(false)
        } catch (error) {
        }
    },
    getConferenceByUidAction: (conferenceUid) => {
        return axios({
            method: 'GET',
            url: `/conference/${conferenceUid}`,
            headers: {
                "role": "topUser"
            }
        })
    },
    openAnnouncementCreatePage: (conferenceUid, modalName, setFuncMap) => {
        setFuncMap.setIsModalOpen(true)
        setFuncMap.setModalTitle(modalName)
        setFuncMap.setModalContent(<AnnouncementUpsertPage conferenceUid={conferenceUid} announcementData={null} setFuncMap={setFuncMap} />)
    },
    openAnnouncementUpdatePage: (conferenceUid, id, modalName, setFuncMap) => {
        setFuncMap.setIsModalOpen(true)
        setFuncMap.setModalTitle(modalName)
        setFuncMap.setModalContent(<AnnouncementUpsertPage conferenceUid={conferenceUid} id={id} announcementData={null} setFuncMap={setFuncMap} />)
    },
    getLangSortedAnnouncements: function (announcements, lang) {
        return announcements.filter(a => { return a.lang == lang }).sort((a, b) => a.sort - b.sort)
    },
    updateAnnouncementsSort: async function (mode, payload, props, setFuncMap) {
        try {
            setFuncMap.setIsButtonSortLoading(true)
            setFuncMap.setIsTableLoading(true)
            const res = await this.updateAnnouncementsSortAction(payload, props.conferenceUid)
            await this.getAnnouncements(props, setFuncMap)
            if (mode === "EN") {
                setFuncMap.setEnSortChange([])
            }
            if (mode === "EN") {
                setFuncMap.setCnSortChange([])
            }
            setFuncMap.setIsButtonSortLoading(false)
            setFuncMap.setIsTableLoading(false)
        } catch (error) {
        }
    },
    updateAnnouncementsSortAction: (payload, conferenceUid) => {
        return axios({
            method: 'POST',
            url: `/conference/${conferenceUid}/announcements/sort`,
            headers: {
                "role": "topUser"
            },
            data: payload
        })
    },
    deleteAnnouncement: async function (id, props, setFuncMap) {
        try {
            setFuncMap.setIsTableLoading(true)
            const res = await this.deleteAnnouncementAction(id, props.conferenceUid)
            await this.getAnnouncements(props, setFuncMap)
            setFuncMap.setIsTableLoading(false)
        } catch (error) {
        }
    },
    deleteAnnouncementAction: (id, conferenceUid) => {
        return axios({
            method: 'DELETE',
            url: `/conference/${conferenceUid}/announcement/${id}`,
            headers: {
                "role": "topUser"
            }
        })
    }

};
