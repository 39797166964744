import React, { useEffect, useState } from 'react';
// import 'antd/dist/antd.css';
import { Table, Radio, message, Spin, Modal, Button, Row, Col, Card } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import efn from './PaymentWayFns'
import langObj from '../TopUserLang'
import './PaymentWay.scss';
import DraggableTable from './DraggableTable'

function PaymentWay(props) {
  const navigate = useNavigate()
  const location = useLocation()
  const [paymentWays, setPaymentWays] = useState([])
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState("")
  const [modalContent, setModalContent] = useState()
  const [paymentWaysSortChange, setPaymentWaysSortChange] = useState([])
  const [isButtonSortLoading, setIsButtonSortLoading] = useState(false)
  const setFuncMap = {
    setPaymentWays, setIsPageLoading, setIsModalOpen,
    setIsTableLoading, setModalTitle, setModalContent,
    setPaymentWaysSortChange,
    message, setIsButtonSortLoading
  }
  const moveColumns = efn.generatePaymentWayMoveMenu(props, setFuncMap)
  useEffect(() => {

    efn.getPaymentWays(props, setFuncMap)
    efn.getConference(props, setFuncMap)

  }, []);

  return (
    <Spin spinning={isPageLoading}>
      <div className="PaymentWay">
        <Row gutter={16} justify="end">
          {paymentWaysSortChange.length > 0 && <Col>
            <Button type="primary" loading={isButtonSortLoading}
              onClick={() => { efn.updatePaymentWaysSort(paymentWaysSortChange, props, setFuncMap) }}>更新排序</Button>
          </Col>}
          <Col>
            <Button type="primary" onClick={() => { efn.openPaymentWayCreatePage(props.conferenceUid, "建立繳款方式", setFuncMap) }}>建立繳款方式</Button>
          </Col>
        </Row>
        <Spin spinning={isTableLoading}>          
          <DraggableTable dataSource={paymentWays}
            columns={moveColumns}
            setFuncMap={setFuncMap}/>

        </Spin>
        <Modal wrapClassName="modal-theme" width="80%" title={modalTitle} open={isModalOpen} footer={[]} destroyOnClose
          onCancel={() => { setIsModalOpen(false) }}>
          {modalContent}
        </Modal>
      </div>
    </Spin>
  );
}



export default PaymentWay;
