import React, { useCallback, useState, useEffect } from 'react';
// import 'antd/dist/antd.css';
import { Form, Input, Button, Typography, Row, Col, Layout, Card, message } from 'antd';
import { localStorageUtils } from '../../../../utils/localStorageUtils'
import axios from 'axios';
import './Login.scss';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import queryString from 'query-string';

const { Title, Text } = Typography;
const { Footer } = Layout;


function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  let params = queryString.parse(location.search)
  const [isLogin, setIsLogin] = useState(false)
  useEffect(() => {
    if (params.status) {
      message.error(params.status);
    }
    if (params.account) {
      form.setFieldsValue({
        account: params.account
      })
    }
  }, [params.status, params.account])
  const onFinish = (values) => {
    // console.log('Received values of form: ', values);
    let account = values.account
    let password = values.password
    setIsLogin(true)
    axios.post('/reviewer/login', {
      account: account,
      pwd: password
    }).then(function (response) {
      setIsLogin(false)
      localStorageUtils.save("reviewer", response.data)
      if (response.data.isChangePwd > 0) {
        navigate(`/mnt/reviewer/reset-password${params.fromPath ? `?fromPath=${params.fromPath}` : ""}`)
      }
      else {
        if (params.fromPath) {
          navigate(params.fromPath);
        }
        else {
          navigate('/mnt/reviewer/');
        }
      }

      console.log(response);
    }).catch(function (error) {
      message.error('Login Failed!');
      setIsLogin(false)
      console.log(error);
    });
  };
  return (
    <div className="ReviewerLogin">
      <Layout style={{ minHeight: '100vh' }}>
        <Row justify="center" align="middle" style={{ height: 'calc(100vh - 69px)', background: '#f5f5f5' }}>
          <Col xs={22} sm={16} md={12} lg={8} xl={6}>
            <Card style={{ borderRadius: '8px' }}>
              <Title level={2} style={{ textAlign: 'center', marginBottom: '24px' }}>ICEI Reviewer Sign in</Title>
              <Form
                name="google_login"
                form={form}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                layout="vertical"
              >
                <Form.Item
                  name="account"
                  rules={[{ required: true, message: 'Please input your account!' }]}
                >
                  <Input placeholder="Account" size="large" />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[{ required: true, message: 'Please input your password!' }]}
                >
                  <Input.Password placeholder="Password" size="large" />
                </Form.Item>
                <Form.Item>
                  <Button loading={isLogin} type="primary" htmlType="submit" style={{ width: '100%', marginTop: '32px' }} size="large">
                    Log in
                  </Button>
                </Form.Item>
              </Form>
              <div style={{ textAlign: 'center' }}>
                <Text>Forgot your password? <Link to="/mnt/reviewer/forget-password">Reset it here</Link></Text>
                <br />
                {/* <Text>Don't have an account? <a href="#">Sign up now</a></Text> */}
              </div>
            </Card>
          </Col>
        </Row>
        <Footer style={{ textAlign: 'center' }}>©2024 Your Company Name. All Rights Reserved.</Footer>
      </Layout>
    </div>
  );
}

export default Login;
