import axios from 'axios';
import { toolUtils } from '../../../../utils/toolUtils.js'
import { Tag, Typography, Popconfirm, message, Spin, Modal, Button, Row, Col, Image } from 'antd';
import NationalityUpsertPage from './NationalityUpsertPage.js'
import moment from 'moment';
import {
    CloseOutlined,
    CheckCircleOutlined,
    DragOutlined
} from '@ant-design/icons';

export default {
    generateNationalityMoveMenu: function (props, setFuncMap) {
        let menu = this.generateNationalityMenu(props, setFuncMap)
        return [
            {
                title: ' ',
                dataIndex: 'sort',
                key: 'sortIcon',
                width: 60,
                render: (lang) => {
                    return <Tag color="#d8780a" key={lang}>
                        <DragOutlined />
                    </Tag>
                }
            }, ...menu]
    },
    generateNationalityMenu: function (props, setFuncMap) {
        const { Link } = Typography;
        return [
            {
                title: ' ',
                dataIndex: 'sequence',
                key: 'sequence',
                width: 20,
            },
            {
                title: '國籍',
                dataIndex: 'name',
                key: 'name',
                width: 400,
            },
            {
                title: "",
                dataIndex: 'id',
                key: 'id',
                width: 120,
                render: (_, render) => {
                    return <><Button size="small" style={{ marginRight: "5px" }}
                        onClick={() => { this.openNationalityUpdatePage(props.conferenceUid, render.id, "更新國籍", setFuncMap) }}>編輯</Button>
                        <Popconfirm
                            placement="left"
                            title={`確認要刪除 ${render.name}?`}
                            onConfirm={() => { this.deleteNationality(render.id, props, setFuncMap) }}
                            okText="Yes"
                            cancelText="No"
                        ><Button size="small" type="primary" danger><CloseOutlined /></Button>
                        </Popconfirm>
                    </>
                }
            }]
    },
    getNationalities: async function (props, setFuncMap) {
        try {
            setFuncMap.setIsTableLoading(true)
            const res = await this.getNationalitiesAction(props.conferenceUid)
            let nationalities = res.data
            nationalities = this.getSortedNationalities(nationalities)
            nationalities = nationalities.map((nationality, i) => {
                return { sequence: (i + 1), ...nationality }
            })
            setFuncMap.setNationalities(nationalities)
            setFuncMap.setIsTableLoading(false)
            setFuncMap.setIsPageLoading(false)
        } catch (error) {
            setFuncMap.setIsTableLoading(false)
            // message.error(error.response.data)
        }
    }, getNationalitiesAction: (conferenceUid) => {
        return axios({
            method: 'GET',
            url: `/conference/${conferenceUid}/nationalities`,
            headers: {
                "role": "topUser"
            }
        })
    },
    getConference: async function (props, setFuncMap) {
        try {
            const res = await this.getConferenceByUidAction(props.conferenceUid)
            props.setContentHeader(res.data.name)
            setFuncMap.setIsPageLoading(false)
        } catch (error) {
        }
    },
    getConferenceByUidAction: (conferenceUid) => {
        return axios({
            method: 'GET',
            url: `/conference/${conferenceUid}`,
            headers: {
                "role": "topUser"
            }
        })
    },
    openNationalityCreatePage: (conferenceUid, modalName, setFuncMap) => {
        setFuncMap.setIsModalOpen(true)
        setFuncMap.setModalTitle(modalName)
        setFuncMap.setModalContent(<NationalityUpsertPage conferenceUid={conferenceUid} nationalityData={null} setFuncMap={setFuncMap} />)
    },
    openNationalityUpdatePage: (conferenceUid, id, modalName, setFuncMap) => {
        setFuncMap.setIsModalOpen(true)
        setFuncMap.setModalTitle(modalName)
        setFuncMap.setModalContent(<NationalityUpsertPage conferenceUid={conferenceUid} id={id} nationalityData={null} setFuncMap={setFuncMap} />)
    },
    getSortedNationalities: function (nationalities) {
        return nationalities.sort((a, b) => a.sort - b.sort)
    },
    updateNationalitiesSort: async function (payload, props, setFuncMap) {
        try {
            setFuncMap.setIsButtonSortLoading(true)
            setFuncMap.setIsTableLoading(true)
            const res = await this.updateNationalitiesSortAction(payload, props.conferenceUid)
            await this.getNationalities(props, setFuncMap)
            setFuncMap.setNationalitesSortChange([])
            setFuncMap.setIsButtonSortLoading(false)
            setFuncMap.setIsTableLoading(false)
        } catch (error) {
        }
    },
    updateNationalitiesSortAction: (payload, conferenceUid) => {
        return axios({
            method: 'POST',
            url: `/conference/${conferenceUid}/nationalities/sort`,
            headers: {
                "role": "topUser"
            },
            data: payload
        })
    },
    deleteNationality: async function (id, props, setFuncMap) {
        try {
            setFuncMap.setIsTableLoading(true)
            const res = await this.deleteNationalityAction(id, props.conferenceUid)
            await this.getNationalities(props, setFuncMap)
            setFuncMap.setIsTableLoading(false)
        } catch (error) {
        }
    },
    deleteNationalityAction: (id, conferenceUid) => {
        return axios({
            method: 'DELETE',
            url: `/conference/${conferenceUid}/nationality/${id}`,
            headers: {
                "role": "topUser"
            }
        })
    }

};
