import React, { useEffect, useState } from 'react';
// import 'antd/dist/antd.css';
import { Input, Alert, message, Spin, List, Row, Col, Modal } from 'antd';
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom';
import { DataContext } from '../../../Context/DataContext';

import efn from './AnnouncementDetailFns'

function AnnouncementDetail(props) {
    const { t } = React.useContext(DataContext)
    const [content, setContent] = useState("")
    const [isPageLoading, setIsPageLoading] = useState(false)
    let setFuncMap = {
        setContent, setIsPageLoading, t
        , ...props.setFuncMap
    }
    useEffect(() => {
        efn.getAnnouncement(props.id, setFuncMap)
    }, []);
    return (
        <Spin spinning={isPageLoading}>
            <div style={{ overflow: "auto" }} dangerouslySetInnerHTML={{ __html: content }}></div>
        </Spin>
    );
}



export default AnnouncementDetail;
