import axios from 'axios';
import { toolUtils } from '../../../../utils/toolUtils'
import { Table, Tag, Typography, message, Spin, Modal, Button } from 'antd';
import {
  HighlightOutlined,
  CheckOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined
} from '@ant-design/icons';
import ReviewSubmissionPaperDetailPage from './ReviewSubmissionPaperDetailPage'
export default {
  getWaitReviewSubmissionPapers: async function (setFuncMap) {
    try {
      setFuncMap.setIsContentLoading(true)
      const res = await this.getWaitReviewSubmissionPapersAction()
      setFuncMap.setSubmissionPapers(res.data.map((item, index) => {
        item.index = index + 1
        return item
      }))
      setFuncMap.setIsContentLoading(false)
    } catch (error) {
      setFuncMap.setIsContentLoading(false)
      // message.error(error.response.data)
    }
  },
  getWaitReviewSubmissionPapersAction: () => {
    return axios({
      method: 'GET',
      url: `/review-papers`,
      headers: {
        "role": "reviewer"
      }
    })
  },
  getReviewSubmissionPaperInfo: async function (setFuncMap) {
    try {
      setFuncMap.setIsInfoLoading(true)
      const res = await this.getReviewSubmissionPaperInfoAction()
      setFuncMap.setReviewNote(res.data.content)
      setFuncMap.setIsInfoLoading(false)
    } catch (error) {
      setFuncMap.setIsInfoLoading(false)
      // message.error(error.response.data)
    }
  },
  getReviewSubmissionPaperInfoAction: () => {
    return axios({
      method: 'GET',
      url: `/review-paper/info`,
      headers: {
        "role": "reviewer"
      }
    })
  },
  updateReferenceNameByUid: async function (payload, setFuncMap) {
    const { waitUpdateData, reviewSubmissionPapers } = payload
    let instance = JSON.parse(JSON.stringify(reviewSubmissionPapers))
    let conf = instance.find(reviewSubmissionPaper => {
      return reviewSubmissionPaper.uid === waitUpdateData.uid
    })
    try {
      setFuncMap.setIsModalOpen(false)
      conf.isUpdateName = true
      conf.isNotAvailableEnter = true
      setFuncMap.setReviewSubmissionPapers(instance)
      await this.updateReviewSubmissionPaperAction(waitUpdateData)
      let cloneInstance = toolUtils.clone(instance)
      let cloneConf = cloneInstance.find(reviewSubmissionPaper => {
        return reviewSubmissionPaper.uid === waitUpdateData.uid
      })
      cloneConf.name = waitUpdateData.after
      cloneConf.isUpdateName = false
      cloneConf.isNotAvailableEnter = false
      setFuncMap.setReviewSubmissionPapers(cloneInstance)
    }
    catch (e) {

    }
  },
  updateReviewSubmissionPaperAction: (waitUpdateData) => {
    return axios.put(`/conference/${waitUpdateData.uid}`, {
      "name": waitUpdateData.after
    }, {
      headers: {
        "role": "topUser"
      }
    })
  },
  generateReviewSubmissionPaperColumns: function (setFuncMap) {
    return [
      {
        title: ' ',
        dataIndex: 'index',
        width: 10,
        key: 'index',
      },
      {
        title: '稿件編號',
        dataIndex: 'paperKey',
        key: 'paperKey',
        width: 100
      },
      {
        title: '類型',
        dataIndex: 'callForPaperType',
        key: 'callForPaperType',
        width: 250,
        render: (_, render) => {
          return <><div style={{ color: "#53a336", fontSize: "15px" }}>{render.callForPaperType}</div>
            <div><i>
              {render.paperType}
            </i></div></>
        }

      },
      {
        title: '標題',
        dataIndex: 'title',
        key: 'title',
        width: 300,
        render: (_, render) => {
          return <><div>{render.titleCn}</div><div>{render.titleEn}</div></>
        }
      },
      {
        title: '分數',
        dataIndex: 'score',
        key: 'score',
        width: 50,
        render: (_, render) => {
          return <><div><Tag color="#6cb5f9">
            分數: {render.score}
          </Tag></div>
            <div><Tag color={render.result === -1 ? "#db3700" : "#6cb5f9"}>
              結果: {render.result === -1 ? "不接受(Reject)" : render.reviewResultName}
            </Tag></div></>
        }
      },
      {
        title: ' ',
        dataIndex: 'id',
        key: 'id',
        width: 50,
        render: (_, render) => {
          return <Button type="primary" onClick={() => {
            this.openSubmissionPaperUpdatePage(render.id, "", setFuncMap)
          }}>審核</Button>
        }
      }]
  },
  openSubmissionPaperUpdatePage: (id, modalName, setFuncMap) => {
    setFuncMap.setModalConfig({
      isOpen: true,
      title: modalName,
      content: <ReviewSubmissionPaperDetailPage id={id} setFuncMap={setFuncMap} />
    })
  },
}