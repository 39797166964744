import React, { useState, useEffect } from 'react';
import efn from './ReviewerUpsertPageFns.js'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Spin, Form, Switch, DatePicker, Select, Input, Button, Row, Col } from 'antd';

const { TextArea } = Input;
const { Option } = Select;

const ReviewerUpsertPage = (props) => {
  const [form] = Form.useForm();
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [editorData, setEditorData] = useState();
  const navigate = useNavigate()
  const location = useLocation()
  const setFuncMap = {
    setIsModalLoading,
    setEditorData,
    setFieldsValue: form.setFieldsValue,
    navigate,
    ...props.setFuncMap
  }
  const onFinish = values => {
    if (props.id) {
      efn.updateReviewer(props.conferenceUid, values, setFuncMap)
    }
    else {
      efn.createReviewer(props.conferenceUid, values, setFuncMap)
    }
  };
  useEffect(() => {
    if (props.id) {
      setIsModalLoading(true)
      efn.getReviewerById(props.id, props.conferenceUid, setFuncMap)
      setIsModalLoading(false)
    }
  }, [props.id])

  return (
    <Spin spinning={isModalLoading}>
      <Form
        form={form}
        layout="horizontal"
        onFinish={onFinish}
      >
        <Row gutter={16}>
          <Col span={24}>
            {generateReviewerSignUpAttribute(props.id).map((item, key) => {
              return <Form.Item key={key}
                name={item.name}
                label={item.label}
                rules={[{ required: item.required, message: `請輸入${item.label}` }, ...item.rules]}
              >
                {item.type === "text" && <Input placeholder={item.label} disabled={item.disabled} size="large" />}
                {item.type === "pwd" && <Input.Password placeholder={item.label} size="large" />}
              </Form.Item>
            })}
          </Col>
        </Row>
        <Row justify={"center"} gutter={16}>
          <Col>
            <Form.Item>
              <Button type="default" onClick={() => {
                setFuncMap.setModalConfig({
                  isOpen: false,
                })
              }}>
                取消
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              {props.id ?
                <Button type="primary" htmlType="submit">
                  更新
                </Button> :
                <Button type="primary" htmlType="submit">
                  建立
                </Button>}
            </Form.Item>
          </Col>
        </Row>
        {props.id && <Form.Item
          name="id"
          hidden
        >
          <Input type="hidden" />
        </Form.Item>}
      </Form>
    </Spin>
  );
};



const generateReviewerSignUpAttribute = function (id) {
  if (id) {
    return updateReviewer()
  }

  return signUp()
}

const signUp = function () {
  return [{
    label: "帳號",
    type: "text",
    name: "account",
    required: true,
    rules: []
  },
  {
    label: "信箱",
    type: "text",
    name: "email",
    required: false,
    rules: [{ type: "email", message: 'It\'s not email format' }]
  },
  {
    label: "密碼",
    type: "pwd",
    name: "pwd",
    required: true,
    rules: []
  },
  {
    label: "姓名",
    type: "text",
    name: "name",
    required: true,
    rules: []
  },
  {
    label: "單位",
    type: "text",
    name: "unit",
    required: true,
    rules: []
  }]
}

const updateReviewer = function () {
  return [{
    label: "帳號",
    type: "text",
    disabled: true,
    name: "account",
    required: true,
    rules: []
  },
  {
    label: "信箱",
    type: "text",
    name: "email",
    required: false,
    rules: [{ type: "email", message: 'It\'s not email format' }]
  },
  {
    label: "姓名",
    type: "text",
    name: "name",
    required: true,
    rules: []
  },
  {
    label: "單位",
    type: "text",
    name: "unit",
    required: true,
    rules: []
  }]
}

export default ReviewerUpsertPage;