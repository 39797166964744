import React, { useEffect, useState } from 'react';
// import 'antd/dist/antd.css';
import { Table, Radio, message, Spin, Modal, Button, Row, Col, Badge, Popconfirm } from 'antd';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import efn from './ApprovedSubmissionPapersFns'

function ApprovedSubmissionPapers(props) {
  const [submissionPapers, setSubmissionPapers] = useState([])
  const [isTableLoading, setIsTableLoading] = useState(false)
  const setFuncMap = {
    message, setSubmissionPapers,
    setIsTableLoading,
    ...props.setFuncMap
  }
  const columns = efn.generateSubmissionPaperMenu()

  useEffect(() => {
    efn.getApprovedPapers(props.conferenceUid, setFuncMap)
  }, []);
  return (
    <div className="SubmissionPaper">
      <Spin spinning={isTableLoading}>
        <Row>
          <Col span={24}>
            <Table dataSource={submissionPapers} columns={columns} />
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default ApprovedSubmissionPapers;
