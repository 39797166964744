import React, { useEffect, useState } from 'react';
// import 'antd/dist/antd.css';
import { Input, Select, message, Spin, Typography, Button, Row, Col, Card, Form } from 'antd';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { toolUtils } from '../../../../../utils/toolUtils'
import { DataContext } from '../../../../../Context/DataContext';
import efn from './ProfileFns'
import './Profile.scss';

function Profile(props) {
  const {t} = React.useContext(DataContext)
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [nationalities, setNationalities] = useState([])
  const [profile, setProfile] = useState({})
  const location = useLocation();
  const [form] = Form.useForm();
  const setFuncMap = {
    message, setIsButtonLoading, setIsPageLoading, setNationalities, setProfile, t,
    setFieldsValue: form.setFieldsValue, ...props.setFuncMap
  }
  const onFinish = (values) => {
    efn.updateUserProfile(values, setFuncMap)
  };
  useEffect(() => {
    efn.getNationalities(setFuncMap)
  }, [])
  useEffect(() => {
    if (nationalities.length > 0) {
      efn.getUserProfile(setFuncMap)
    }
  }, [nationalities, location.search])

  return (
    <Spin spinning={isPageLoading}>
      <div className="Profle">
        <Form
          form={form}
          onFinish={onFinish}
          layout="horizontal"
        >
          {efn.generateProfileAttributes(nationalities).map((item, key) => {
            return <Form.Item key={key}
              name={item.name}
              label={item.label}
              rules={[{ required: item.required, message: `請輸入${item.label}` }, ...item.rules]}
            >
              {item.type == "hidden" && <Form.Item name={item.name} hidden><Input type="hidden" /></Form.Item>}
              {item.type == "text" && <Typography.Text size="large">{form.getFieldValue(item.name)}</Typography.Text>}
              {item.type == "input" && <Input placeholder={item.label} size="large" />}
              {item.type == "select" && <Select showSearch={true} options={item.options} filterOption={toolUtils.filterOption} />}
            </Form.Item>
          })}
          <Form.Item>
            <Row gutter={16} justify={'space-around'}>
              <Col>
                <Button loading={isButtonLoading}
                  type="primary" htmlType="submit" style={{ background: "#fea4a4" }} size="large">
                  Update
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  );
}

export default Profile;
