import axios from 'axios';
import { toolUtils } from '../../../../utils/toolUtils.js'
import pfn from './NationalityFns.js'
import { message, Upload } from 'antd';
export default {
  createNationality: async function (conferenceUid, values, setFuncMap) {
    let payload = {
      "name": values.name,
    }
    try{
      setFuncMap.setIsModalLoading(true)
      const res = await this.createNationalityAction(conferenceUid, payload)
      pfn.getNationalities({ conferenceUid }, setFuncMap)
      setFuncMap.setIsModalLoading(false)
      setFuncMap.setIsModalOpen(false)
      setFuncMap.message.open({
        key: "create",
        type: 'success',
        content: 'Create success',
        duration: 2,
      });
    }
    catch(error){
      setFuncMap.message.open({
        type: 'error',
        content: setFuncMap.t(`error.${error.response.status}`, { data: payload.name }),
        duration: 2,
      });
      setFuncMap.setIsModalLoading(false)
    }
    
  },
  createNationalityAction: (conferenceUid, payload) => {
    return axios.post(`/conference/${conferenceUid}/nationality`, payload, {
      headers: {
        "role": "topUser"
      }
    })
  },
  getNationalityById: async function (conferenceUid, id, setFuncMap) {
    try {
      const res = await this.getNationalityByIdAction(conferenceUid, id);
      let data = res.data
      if (data) {
        setFuncMap.setFieldsValue(data)
      }
    }
    catch (e) {

    }

  },
  getNationalityByIdAction: (conferenceUid, id) => {
    return axios.get(`/conference/${conferenceUid}/nationality/${id}`, {
      headers: {
        "role": "topUser"
      }
    })
  },
  updateNationality: async function (conferenceUid, values, setFuncMap) {
    let payload = {
      "id": values.id,
      "name": values.name,
    }
    try{
      setFuncMap.setIsModalLoading(true)
      console.log({ payload })
      const res = await this.updateNationalityAction(conferenceUid, payload)
      pfn.getNationalities({ conferenceUid }, setFuncMap)
      setFuncMap.setIsModalLoading(false)
      setFuncMap.setIsModalOpen(false)
      setFuncMap.message.open({
        key: "update",
        type: 'success',
        content: 'Update success',
        duration: 2,
      });
    }
    catch(error){
      setFuncMap.message.open({
        type: 'error',
        content: setFuncMap.t(`error.${error.response.status}`, { data: payload.name }),
        duration: 2,
      });
      setFuncMap.setIsModalLoading(false)
    }
    
  },
  updateNationalityAction: (conferenceUid, payload) => {
    return axios.post(`/conference/${conferenceUid}/nationality/${payload.id}`, payload, {
      headers: {
        "role": "topUser"
      }
    })
  }  
}