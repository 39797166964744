import React, { useState, useEffect } from 'react';
import efn from './UserSubmissionPaperUpsertPageFns.js'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Spin, Form, Checkbox, Switch, DatePicker, Select, Input, Button, Row, Col } from 'antd';
import { toolUtils } from '../../../../../utils/toolUtils.js'
import { DataContext } from '../../../../../Context/DataContext.js';
import './UserSubmissionPaperUpsertPage.scss';
const { TextArea } = Input;
const { Option } = Select;

const UserSubmissionPaperUpsertPage = (props) => {
  const { t } = React.useContext(DataContext)
  const [form] = Form.useForm();
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [editorData, setEditorData] = useState();
  const { action, id } = useParams();
  const navigate = useNavigate()
  const location = useLocation()
  const [selectedCallForPaperGroupId, setSelectedCallForPaperGroupId] = useState(-1)
  const [paperTypeConfig, setPaperTypeConfig] = useState({
    paperTypes: [],
    callForPaperGroups: []
  })
  const [callForPaperTypes, setCallForPaperTypes] = useState([])
  const [userSubmissionPaperData, setUserSubmissionPaperData] = useState({})
  const [selectedValue, setSelectedValue] = useState(null);
  const [cnAbstractHelp, setCnAbstractHelp] = useState({
    helpWord: null,
    status: "success"
  })
  const [enAbstractHelp, setEnAbstractHelp] = useState({
    helpWord: null,
    status: "success"
  })
  const setFuncMap = {
    setIsModalLoading,
    setEditorData,
    form,
    setFieldsValue: form.setFieldsValue,
    setPaperTypeConfig,
    setCallForPaperTypes,
    setSelectedCallForPaperGroupId,
    navigate,
    setSelectedValue,
    t,
    setUserSubmissionPaperData,
    cnAbstractHelp, setCnAbstractHelp,
    enAbstractHelp, setEnAbstractHelp,
    ...props.setFuncMap
  }
  const handleCheckboxChange = (value) => {
    if (selectedValue === value) {
      setSelectedValue(null); // 重复点击取消选择
    } else {
      setSelectedValue(value); // 选择新的 Checkbox
    }
  };
  const onFinish = values => {
    console.log('Form values:', values);
    values["content"] = editorData
    if (id) {
      efn.updateUserSubmissionPaper(values, setFuncMap)
    }
    else {
      efn.createUserSubmissionPaper(values, setFuncMap)
    }
  };
  useEffect(() => {
    setIsModalLoading(true)
    efn.getUserSubmissionPaperById(id, action, setFuncMap)
    setIsModalLoading(false)
  }, [id, location.search])

  useEffect(() => {
    form.setFieldsValue({
      defaultContactAuthor: selectedValue
    })
    efn.initUserSubmissionPaperFormValidation(setFuncMap)
  }, [selectedValue])

  let filteredCallForPaperTypes = (selectedCallForPaperGroupId > -1) ? callForPaperTypes.filter(callForPaperType => {
    return callForPaperType.groupId === selectedCallForPaperGroupId
  }) : callForPaperTypes

  return (
    <Spin spinning={isModalLoading}>
      <div className="UserSubmissionPaperUpsertPage">
        <Form
          form={form}
          layout="horizontal"
          onFinish={onFinish}
          disabled={action === "view"}
          onValuesChange={() => {
            efn.initUserSubmissionPaperFormValidation(setFuncMap)
          }}
        >
          <Row gutter={16}>
            <Col span={24}>
              {efn.generateSubmissionPaperAttribute(paperTypeConfig, filteredCallForPaperTypes, userSubmissionPaperData, setFuncMap).map((item, key) => {
                return item.class ? <Row gutter={16}>
                  {item.items.map((card, key) => {
                    return <Col key={key} span={8}>
                      <div className={card.class}>
                        {card.items.map((attr, key) => {
                          return <Form.Item key={key}
                            name={attr.name}
                            label={attr.label}
                            validateStatus={attr.validateStatus}
                            help={attr.help}
                            rules={[{ required: attr.required, message: `${t("home.user.paper.content.hint.word")}${attr.label}` }, ...attr.rules]}
                          >
                            {attr.type === "text" && <Input placeholder={attr.label} size="large" />}
                            {attr.type === "checkbox" && <Checkbox
                              checked={selectedValue === attr.name}
                              onChange={() => handleCheckboxChange(attr.name)}
                            ></Checkbox>}
                          </Form.Item>
                        })}
                      </div>
                    </Col>
                  })}
                </Row> :
                  <Form.Item key={key}
                    name={item.name}
                    label={item.label}
                    validateStatus={item.validateStatus}
                    help={item.help}
                    rules={[{ required: item.required, message: `${t("home.user.paper.content.hint.word")}${item.label}` }, ...item.rules]}
                  >
                    {item.type === "text" && <Input placeholder={item.label} size="large" />}
                    {item.type === "text-tag" && <Select
                      mode="tags"
                      placeholder={item.placeHolder || item.label}
                      style={{
                        width: '100%',
                      }}
                      tokenSeparators={[';']}
                      options={[]}
                    />}
                    {item.type === "select" && <Select showSearch filterOption={toolUtils.filterOption} onChange={item.onChange} options={item.options} />}
                    {item.type === "textarea" && <Input.TextArea
                      autoSize={{ minRows: 5, maxRows: 10 }}
                      placeholder={item.label} />}
                  </Form.Item>
              })}
              <Form.Item
                name="defaultContactAuthor"
                hidden
              >
                <Input type="hidden" />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={"center"} gutter={16}>
            {["edit", "new"].indexOf(action) > -1 && <><Col>
              <Form.Item>
                <Button type="default" onClick={() => {
                  navigate("/submission-paper")
                }}>
                  {t("home.user.paper.content.button.cancel")}
                </Button>
              </Form.Item>
            </Col>
              <Col>
                <Form.Item>
                  {id ?
                    <Button type="primary" htmlType="submit">
                      {t("home.user.paper.content.button.update")}
                    </Button> :
                    <Button type="primary" htmlType="submit">
                      {t("home.user.paper.content.button.create")}
                    </Button>}
                </Form.Item>
              </Col></>}
          </Row>
          {id && <Form.Item
            name="id"
            hidden
          >
            <Input type="hidden" />
          </Form.Item>}
        </Form>
      </div>
    </Spin>
  );
};

export default UserSubmissionPaperUpsertPage;