import React, { useEffect, useState } from 'react';
// import 'antd/dist/antd.css';
import { Input, Switch, message, Spin, Upload, Button, Row, Col, Form, DatePicker, Modal } from 'antd';
import {
  UploadOutlined,
} from '@ant-design/icons';
import { toolUtils } from '../../../../utils/toolUtils';
import { useNavigate, useLocation } from 'react-router-dom';
import efn from './ConfigFns'
import CKEditorUtil from '../../../../utils/ckEditorUtil'
import ApprovedSubmissionPapers from './ApprovedSubmissionPapers'
import './Config.scss';


function Config(props) {
  const navigate = useNavigate()
  const location = useLocation()
  const { TextArea } = Input
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isContentLoading, setIsContentLoading] = useState(false)
  const [editorData, setEditorData] = useState();
  const [fileList, setFileList] = useState([])
  const [form] = Form.useForm();
  const [modalConfig, setModalConfig] = useState({
    isOpen: false,
    title: "",
    content: ""
  })
  const setFuncMap = {
    message, setIsPageLoading, setIsContentLoading, setEditorData, setFileList, setModalConfig
  }

  useEffect(() => {

    efn.getConference(props, setFuncMap)
    efn.getConfigs(props, form, setFuncMap)

  }, []);

  const onFinish = values => {
    console.log({ values })
    values["Footer_value"] = editorData
    efn.updateConfig(values, props, setFuncMap)
  };
  const colSpan = 12
  return (
    <Spin spinning={isPageLoading}>
      <div className="Config">
        <Spin spinning={isContentLoading}>
          <Form
            form={form}
            layout="horizontal"
            onFinish={onFinish}
          >
            {generateConfigAttribute(efn, props.conferenceUid, setFuncMap).map((item, key) => {
              return <Row key={key} justify={'center'} gutter={16}>
                {item.type === "dateTime" && <Col span={colSpan}>
                  <Form.Item
                    label={item.label}
                    name={`${item.key}_value`}
                    initialValues={null}
                  >
                    <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                  </Form.Item>
                  <Form.Item
                    hidden
                    name={`${item.key}_id`}
                  >
                    <Input type="hidden" />
                  </Form.Item>
                </Col>}
                {item.type === "date" && <Col span={colSpan}>
                  <Form.Item
                    label={item.label}
                    name={`${item.key}_value`}
                    initialValues={null}
                  >
                    <DatePicker format="YYYY-MM-DD" />
                  </Form.Item>
                  <Form.Item
                    hidden
                    name={`${item.key}_id`}
                  >
                    <Input type="hidden" />
                  </Form.Item>
                </Col>}
                {item.type === "boolean" && <Col span={colSpan}>
                  <Form.Item
                    label={item.label}
                    name={`${item.key}_value`}
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                  <Form.Item
                    hidden
                    name={`${item.key}_id`}
                  >
                    <Input type="hidden" />
                  </Form.Item>
                </Col>}
                {item.type === "ckeditor" && <Col span={colSpan}>
                  <Form.Item
                    label={item.label}
                    name={`${item.key}_value`}
                  >
                    <CKEditorUtil setFuncMap={setFuncMap} defaultValue={editorData} />
                  </Form.Item>
                  <Form.Item
                    hidden
                    name={`${item.key}_id`}
                  >
                    <Input type="hidden" />
                  </Form.Item>
                </Col>}
                {item.type === "file" && <Col span={colSpan}>
                  <Form.Item
                    label={item.label}
                    name={`${item.key}_value`}
                  >
                    <Upload {...efn.generateUploadProps(form, fileList, setFuncMap)}>
                      <Button icon={<UploadOutlined />}></Button>
                    </Upload>
                  </Form.Item>
                  <Form.Item
                    hidden
                    name={`${item.key}_id`}
                  >
                    <Input type="hidden" />
                  </Form.Item>
                </Col>}
                {item.type === "number" && <Col span={colSpan}>
                  <Form.Item
                    label={item.label}
                    name={`${item.key}_value`}
                    rules={item.rules}
                  >
                    <Input type="text" />
                  </Form.Item>
                  <Form.Item
                    hidden
                    name={`${item.key}_id`}
                  >
                    <Input type="hidden" />
                  </Form.Item>
                </Col>}
              </Row>
            })}
            <Form.Item
              name="id"
              style={{ minHeight: 0, margin: 0 }}
            >
            </Form.Item>
            <Row justify={"center"} gutter={16}>
              <Col>
                <Button type="primary" htmlType="submit">
                  更新
                </Button>
              </Col>
            </Row>

          </Form>
        </Spin>
        <Modal wrapClassName="modal-theme"
          width={"80%"}
          title={modalConfig.title}
          open={modalConfig.isOpen}
          footer={[]}
          destroyOnClose
          onCancel={() => {
            setModalConfig({
              isOpen: false,
            })
          }}>
          {modalConfig.content}
        </Modal>
      </div>
    </Spin>
  );
}

const generateConfigAttribute = function (efn, conferenceUid, setFuncMap) {
  return [{
    label: "上傳封面圖片",
    type: "file",
    key: "BannerIamge",
    rules: []
  }, {
    label: "開放用戶註冊",
    type: "boolean",
    key: "IsRegistrationICEIAvailable",
    rules: []
  }, {
    label: "報名截止日期",
    type: "date",
    key: "RegistrationDeadline",
    rules: []
  }, {
    label: "投稿截止日期",
    type: "date",
    key: "SubmissionDeadline",
    rules: []
  }, {
    label: "開啟用戶進行投稿",
    type: "boolean",
    key: "StopSubmitPaperSignal",
    rules: []
  }, {
    label: "系統停止投稿截止時間",
    type: "dateTime",
    key: "StopSubmitPaperDateTime",
    rules: []
  }, {
    label: "審稿截止日期",
    type: "date",
    key: "ReviewDeadline",
    rules: []
  }, {
    label: "公布審稿結果日期",
    type: "date",
    key: "PublishReviewResultDeadline",
    rules: []
  }, {
    label: "定稿上傳截止日",
    type: "date",
    key: "FinalSubmissionDeadline",
    rules: []
  }, {
    label: <><Button style={{ marginRight: "8px" }} onClick={() => {
      setFuncMap.setModalConfig({
        isOpen: true,
        title: "目前已通過稿件",
        content: <ApprovedSubmissionPapers conferenceUid={conferenceUid} setFuncMap={setFuncMap} />
      })
    }}>查看目前通過稿件</Button>公告審稿結果頁面</>,
    type: "boolean",
    key: "IsPublishReviewResult",
    rules: []
  }, {
    label: "英文摘要字數最大限制",
    type: "number",
    key: "ENAbstractCount",
    rules: [{ required: true, message: `請輸入數字` }, ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value) {
          return Promise.resolve();
        }
        if (!toolUtils.checkNumber(value)) {
          return Promise.reject(new Error(`請輸入數字`));
        }
        return Promise.resolve();
      },
    })]
  }, {
    label: "中文摘要字數最大限制",
    type: "number",
    key: "CNAbstractCount",
    rules: [{ required: true, message: `請輸入數字` }, ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value) {
          return Promise.resolve();
        }
        if (!toolUtils.checkNumber(value)) {
          return Promise.reject(new Error(`請輸入數字`));
        }
        return Promise.resolve();
      },
    })]
  }, {
    label: "Footer 設置",
    type: "ckeditor",
    key: "Footer",
    rules: []
  }]
}

export default Config;
