import axios from 'axios';
import { toolUtils } from '../../../../utils/toolUtils'
import { Tag, Typography, Popconfirm, message, Spin, Modal, Button, Row, Col, Card } from 'antd';
import ReviewerUpsertPage from './ReviewerUpsertPage.js'
import moment from 'moment';
import {
    CloseOutlined,
    CheckCircleOutlined,
    DragOutlined
} from '@ant-design/icons';

export default {
    generateReviewerMenu: function (props, setFuncMap, fn) {
        const { Text } = Typography;
        return [
            {
                title: '帳號',
                dataIndex: 'account',
                key: 'account',
            },
            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '單位',
                dataIndex: 'unit',
                key: 'unit',
            },
            {
                title: '信箱',
                dataIndex: 'email',
                key: 'email',
            },
            {
                title: "",
                dataIndex: 'id',
                key: 'id',
                width: 200,
                render: (_, render) => {
                    return <Row justify={"end"} gutter={4}>
                        <Col>
                            <Button size="small" style={{ marginRight: "5px" }}
                                onClick={() => { this.openReviewerUpdatePage(props.conferenceUid, render.id, "編輯審稿人員", setFuncMap) }}>編輯</Button>
                        </Col>
                        <Col>
                            <Popconfirm
                                placement="left"
                                title={`確認要刪除 ${render.name}?`}
                                onConfirm={() => {
                                    this.deleteReviewer(props.conferenceUid, render.id, setFuncMap)
                                }}
                                okText="Yes"
                                cancelText="No"
                            ><Button size="small" type="primary" danger><CloseOutlined /></Button>
                            </Popconfirm>
                        </Col>
                    </Row>
                }
            }]
    },
    getReviewers: async function (conferenceUid, setFuncMap) {
        try {
            setFuncMap.setIsTableLoading(true)
            const res = await this.getReviewersAction(conferenceUid)
            let reviewers = res.data
            setFuncMap.setReviewers(reviewers)
            setFuncMap.setIsTableLoading(false)
            setFuncMap.setIsPageLoading(false)
        } catch (error) {
            setFuncMap.setIsPageLoading(false)
            setFuncMap.setIsTableLoading(false)
            // message.error(error.response.data)
        }
    }, getReviewersAction: (conferenceUid) => {
        return axios({
            method: 'GET',
            url: `/conference/${conferenceUid}/reviewers`,
            headers: {
                "role": "topUser"
            }
        })
    },
    getLangSortedReviewers: function (reviewers, lang) {
        return reviewers.filter(a => { return a.lang == lang }).sort((a, b) => a.sort - b.sort)
    },
    deleteReviewer: async function (conferenceUid, id, setFuncMap) {
        try {
            setFuncMap.setIsTableLoading(true)
            const res = await this.deleteReviewerAction(conferenceUid, id)
            await this.getReviewers(conferenceUid, setFuncMap)
            setFuncMap.setIsTableLoading(false)
        } catch (error) {
            setFuncMap.setIsTableLoading(false)
        }
    },
    deleteReviewerAction: (conferenceUid, id) => {
        return axios({
            method: 'DELETE',
            url: `/conference/${conferenceUid}/reviewer/${id}`,
            headers: {
                "role": "topUser"
            }
        })
    },
    submitVerifyReviewerAction: (id) => {
        return axios({
            method: 'PUT',
            url: `/submission-paper/${id}/sumbit-verify`,
            headers: {
                "role": "user"
            }
        })
    },
    openReviewerCreatePage: (conferenceUid, modalName, setFuncMap) => {
        setFuncMap.setModalConfig({
            isOpen: true,
            title: modalName,
            content: <ReviewerUpsertPage conferenceUid={conferenceUid} setFuncMap={setFuncMap} />
        })
    },
    openReviewerUpdatePage: (conferenceUid, id, modalName, setFuncMap) => {
        setFuncMap.setModalConfig({
            isOpen: true,
            title: modalName,
            content: <ReviewerUpsertPage id={id} conferenceUid={conferenceUid} setFuncMap={setFuncMap} />
        })
    },

};
