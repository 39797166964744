import React, { useEffect, useState } from 'react';
// import 'antd/dist/antd.css';
import { Input, Radio, message, Spin, Modal, Button, Row, Col, Form } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import efn from './RegistrationFeeFns'
import RegistrationFeeUpsertPage from './RegistrationFeeUpsertPage'
import langObj from '../TopUserLang'
import './RegistrationFee.scss';


function RegistrationFee(props) {
  const navigate = useNavigate()
  const location = useLocation()
  const { TextArea } = Input
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isContentLoading, setIsContentLoading] = useState(false)
  const [registrationFeeMap, setRegistrationFeeMap] = useState({})
  const [mode, setMode] = useState('CN');
  const [form] = Form.useForm();
  const setFuncMap = {
    message, setMode, setIsPageLoading, setIsContentLoading, setRegistrationFeeMap
  }
  const handleModeChange = (e) => {
    setMode(e.target.value);
  };
  useEffect(() => {

    efn.getConference(props, setFuncMap)
    efn.getRegistrationFees(props, setFuncMap)

  }, []);

  return (
    <Spin spinning={isPageLoading}>
      <div className="RegistrationFee">
        <Spin spinning={isContentLoading}>
          <Radio.Group
            onChange={handleModeChange}
            value={mode}
            style={{
              marginBottom: 8,
            }}
          >
            {langObj.langs.map((lang, key) => {
              return <Radio.Button key={key} value={lang.key} style={{ background: lang.color }}>{lang.key}</Radio.Button>
            })}
          </Radio.Group>
          {langObj.langs.map((lang, key) => {
            return <div className={`upsert-page ${mode != lang.key ? "hide" : ""}`}>
              <RegistrationFeeUpsertPage
                key={key} lang={lang.key} conferenceUid={props.conferenceUid}
                registrationFeeMap={registrationFeeMap} setFuncMap={setFuncMap} /></div>
          })}
        </Spin>
      </div>
    </Spin>
  );
}



export default RegistrationFee;
