import axios from 'axios';
import pfn from './ReviewNoteFns.js'
import { toolUtils } from '../../../../utils/toolUtils.js'

export default {
  updateReviewNote: async function (payload, props, setFuncMap) {
    try {
      setFuncMap.setIsContentLoading(true)
      if (payload.id) {
        await this.updateReviewNoteByIdAction(payload, props.conferenceUid)
      }
      else {
        await this.updateReviewNoteAction(payload, props.conferenceUid)
      }
      let res = await pfn.getReviewNotesAction(props.conferenceUid)
      setFuncMap.setIsContentLoading(false)
      setFuncMap.setLangReviewNote(res.data.find(item => { return item.lang === payload.lang }))
    } catch (error) {
    }
  },
  updateReviewNoteAction: (payload, conferenceUid) => {
    return axios({
      method: 'POST',
      url: `/conference/${conferenceUid}/review-note`,
      headers: {
        "role": "topUser"
      },
      data: payload
    })
  },
  updateReviewNoteByIdAction: (payload, conferenceUid) => {
    return axios({
      method: 'POST',
      url: `/conference/${conferenceUid}/review-note/${payload.id}`,
      headers: {
        "role": "topUser"
      },
      data: payload
    })
  },
  getReviewNote: async function (props, setFuncMap) {
    try {
      setFuncMap.setIsContentLoading(true)
      const res = await this.getReviewNotesAction(props.conferenceUid)
      let result = {}
      res.data.forEach(item => {
        result[item.lang] = item
      })
      setFuncMap.setReviewNoteMap(result)
      setFuncMap.setIsContentLoading(false)
    } catch (error) {
    }
  },
  getReviewNoteByIdAction: (conferenceUid) => {
    return axios({
      method: 'GET',
      url: `/conference/${conferenceUid}/review-note`,
      headers: {
        "role": "topUser"
      }
    })
  },
};
