import React, { useCallback, useState, useEffect } from 'react';
// import 'antd/dist/antd.css';
import { Form, Input, Button, Typography, Row, Col, Layout, Card, message } from 'antd';
import { localStorageUtils } from '../../../../utils/localStorageUtils'
import axios from 'axios';
import './ForgetPassword.scss';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';

const { Title, Text } = Typography;
const { Footer } = Layout;


function ForgetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSending, setIsSending] = useState(false)
  const onFinish = (values) => {
    let account = values.account
    console.log({ values })
    setIsSending(true)
    axios.put('/top_user/forget_pwd', {
      account: account,
    }).then(function (response) {
      message.open({
        type: 'success',
        content: 'Send new password to your email!',
        duration: 2,
      })
      setIsSending(false)
      navigate(`/mnt/top-user/login?account=${account}`);
    }).catch(function (error) {
      message.open({
        type: 'error',
        content: error.response.data.message,
        duration: 2,
      })
      setIsSending(false)
      console.log(error);
    });
  };
  return (
    <div className="TopUserForgetPassword">
      <Layout style={{ minHeight: '100vh' }}>
        <Row justify="center" align="middle" style={{ height: 'calc(100vh - 69px)', background: '#f5f5f5' }}>
          <Col xs={22} sm={16} md={12} lg={8} xl={6}>
            <Card style={{ borderRadius: '8px' }}>
              <Title level={2} style={{ textAlign: 'center', marginBottom: '24px' }}>ICEI Admin Forget Password</Title>
              <Form
                name="google_login"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                layout="vertical"
              >
                <Form.Item
                  name="account"
                  rules={[{ required: true, message: 'Please input your account!' }, { type: "email", message: 'It\'s not email format' }]}
                >
                  <Input placeholder="Account" size="large" />
                </Form.Item>
                <Form.Item>
                  <Button loading={isSending} type="primary" htmlType="submit" style={{ width: '100%', marginTop: '32px' }} size="large">
                    Send
                  </Button>
                </Form.Item>
              </Form>
              <div style={{ textAlign: 'center' }}>
                <Text>I have an account? <Link to="/mnt/top-user/">Login now</Link></Text>
              </div>
            </Card>
          </Col>
        </Row>
        <Footer style={{ textAlign: 'center' }}>©2024 Your Company Name. All Rights Reserved.</Footer>
      </Layout>
    </div>
  );
}

export default ForgetPassword;
