import React, { useEffect, useState } from 'react';
// import 'antd/dist/antd.css';
import { DataContext } from '../../../Context/DataContext';
import { Input, Radio, message, Spin, Modal, Button, Row, Col, Form } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import efn from './IntroductionFns'
import './Introduction.scss';
import moment from 'moment';


function Introduction(props) {
    const { globalData } = React.useContext(DataContext)
    const navigate = useNavigate()
    const location = useLocation()
    const { TextArea } = Input
    const [introduction, setIntroduction] = useState({})
    const [isPageLoading, setIsPageLoading] = useState(true)
    const setFuncMap = {
        message, setIsPageLoading, setIntroduction
    }
    useEffect(() => {
        efn.getIntroduction(setFuncMap)
    }, [globalData.langChangeTime]);
    return (
        <Spin spinning={isPageLoading}>
            <div className="UserIntroduction">
                {introduction.lastUpdateAt && <div className="last-update-at">{`最後更新時間 ${moment(introduction.lastUpdateAt).format("yyyy-MM-DD HH:mm:ss")}`}</div>}
                <div className="main" dangerouslySetInnerHTML={{ __html: introduction.content }}></div>
            </div>
        </Spin>
    );
}



export default Introduction;
