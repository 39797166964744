import axios from 'axios';
import { toolUtils } from '../../../../utils/toolUtils.js'
import { Tag, Typography, Popconfirm, message, Spin, Modal, Button, Row, Col, Image } from 'antd';
import PaymentWayUpsertPage from './PaymentWayUpsertPage.js'
import moment from 'moment';
import {
    CloseOutlined,
    CheckCircleOutlined,
    DragOutlined
} from '@ant-design/icons';

export default {
    generatePaymentWayMoveMenu: function (props, setFuncMap) {
        let menu = this.generatePaymentWayMenu(props, setFuncMap)
        return [
            {
                title: ' ',
                dataIndex: 'sort',
                key: 'sortIcon',
                width: 60,
                render: (lang) => {
                    return <Tag color="#d8780a" key={lang}>
                        <DragOutlined />
                    </Tag>
                }
            }, ...menu]
    },
    generatePaymentWayMenu: function (props, setFuncMap) {
        const { Link } = Typography;
        return [
            {
                title: ' ',
                dataIndex: 'sequence',
                key: 'sequence',
                width: 20,
            },
            {
                title: '繳款方式',
                dataIndex: 'name',
                key: 'name',
                width: 400,
            },
            {
                title: "",
                dataIndex: 'id',
                key: 'id',
                width: 120,
                render: (_, render) => {
                    return <><Button size="small" style={{ marginRight: "5px" }}
                        onClick={() => { this.openPaymentWayUpdatePage(props.conferenceUid, render.id, "更新繳款方式", setFuncMap) }}>編輯</Button>
                        <Popconfirm
                            placement="left"
                            title={`確認要刪除 ${render.name}?`}
                            onConfirm={() => { this.deletePaymentWay(render.id, props, setFuncMap) }}
                            okText="Yes"
                            cancelText="No"
                        ><Button size="small" type="primary" danger><CloseOutlined /></Button>
                        </Popconfirm>
                    </>
                }
            }]
    },
    getPaymentWays: async function (props, setFuncMap) {
        try {
            setFuncMap.setIsTableLoading(true)
            const res = await this.getPaymentWaysAction(props.conferenceUid)
            let paymentWays = res.data
            paymentWays = this.getSortedPaymentWays(paymentWays)
            paymentWays = paymentWays.map((paymentWay, i) => {
                return { sequence: (i + 1), ...paymentWay }
            })
            setFuncMap.setPaymentWays(paymentWays)
            setFuncMap.setIsTableLoading(false)
            setFuncMap.setIsPageLoading(false)
        } catch (error) {
            setFuncMap.setIsTableLoading(false)
            // message.error(error.response.data)
        }
    }, getPaymentWaysAction: (conferenceUid) => {
        return axios({
            method: 'GET',
            url: `/conference/${conferenceUid}/payment-ways`,
            headers: {
                "role": "topUser"
            }
        })
    },
    getConference: async function (props, setFuncMap) {
        try {
            const res = await this.getConferenceByUidAction(props.conferenceUid)
            props.setContentHeader(res.data.name)
            setFuncMap.setIsPageLoading(false)
        } catch (error) {
        }
    },
    getConferenceByUidAction: (conferenceUid) => {
        return axios({
            method: 'GET',
            url: `/conference/${conferenceUid}`,
            headers: {
                "role": "topUser"
            }
        })
    },
    openPaymentWayCreatePage: (conferenceUid, modalName, setFuncMap) => {
        setFuncMap.setIsModalOpen(true)
        setFuncMap.setModalTitle(modalName)
        setFuncMap.setModalContent(<PaymentWayUpsertPage conferenceUid={conferenceUid} paymentWayData={null} setFuncMap={setFuncMap} />)
    },
    openPaymentWayUpdatePage: (conferenceUid, id, modalName, setFuncMap) => {
        setFuncMap.setIsModalOpen(true)
        setFuncMap.setModalTitle(modalName)
        setFuncMap.setModalContent(<PaymentWayUpsertPage conferenceUid={conferenceUid} id={id} paymentWays={null} setFuncMap={setFuncMap} />)
    },
    getSortedPaymentWays: function (paymentWays) {
        return paymentWays.sort((a, b) => a.sort - b.sort)
    },
    updatePaymentWaysSort: async function (payload, props, setFuncMap) {
        try {
            setFuncMap.setIsButtonSortLoading(true)
            setFuncMap.setIsTableLoading(true)
            const res = await this.updatePaymentWaysSortAction(payload, props.conferenceUid)
            await this.getPaymentWays(props, setFuncMap)
            setFuncMap.setPaymentWaysSortChange([])
            setFuncMap.setIsButtonSortLoading(false)
            setFuncMap.setIsTableLoading(false)
        } catch (error) {
        }
    },
    updatePaymentWaysSortAction: (payload, conferenceUid) => {
        return axios({
            method: 'POST',
            url: `/conference/${conferenceUid}/payment-ways/sort`,
            headers: {
                "role": "topUser"
            },
            data: payload
        })
    },
    deletePaymentWay: async function (id, props, setFuncMap) {
        try {
            setFuncMap.setIsTableLoading(true)
            const res = await this.deletePaymentWayAction(id, props.conferenceUid)
            await this.getPaymentWays(props, setFuncMap)
            setFuncMap.setIsTableLoading(false)
        } catch (error) {
        }
    },
    deletePaymentWayAction: (id, conferenceUid) => {
        return axios({
            method: 'DELETE',
            url: `/conference/${conferenceUid}/payment-way/${id}`,
            headers: {
                "role": "topUser"
            }
        })
    }

};
