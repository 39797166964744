import React, { useEffect, useState } from 'react';
// import 'antd/dist/antd.css';
import { Table, Radio, message, Spin, Modal, Button, Row, Col, Card } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import efn from './NationalityFns'
import langObj from '../TopUserLang'
import './Nationality.scss';
import DraggableTable from './DraggableTable'

function Nationality(props) {
  const navigate = useNavigate()
  const location = useLocation()
  const [nationalities, setNationalities] = useState([])
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState("")
  const [modalContent, setModalContent] = useState()
  const [nationalitesSortChange, setNationalitesSortChange] = useState([])
  const [isButtonSortLoading, setIsButtonSortLoading] = useState(false)
  const setFuncMap = {
    setNationalities, setIsPageLoading, setIsModalOpen,
    setIsTableLoading, setModalTitle, setModalContent,
    setNationalitesSortChange,
    message, setIsButtonSortLoading
  }
  const moveColumns = efn.generateNationalityMoveMenu(props, setFuncMap)
  useEffect(() => {

    efn.getNationalities(props, setFuncMap)
    efn.getConference(props, setFuncMap)

  }, []);

  return (
    <Spin spinning={isPageLoading}>
      <div className="Nationality">
        <Row gutter={16} justify="end">
          {nationalitesSortChange.length > 0 && <Col>
            <Button type="primary" loading={isButtonSortLoading}
              onClick={() => { efn.updateNationalitiesSort(nationalitesSortChange, props, setFuncMap) }}>更新排序</Button>
          </Col>}
          <Col>
            <Button type="primary" onClick={() => { efn.openNationalityCreatePage(props.conferenceUid, "建立國籍", setFuncMap) }}>建立國籍</Button>
          </Col>
        </Row>
        <Spin spinning={isTableLoading}>          
          <DraggableTable dataSource={nationalities}
            columns={moveColumns}
            setFuncMap={setFuncMap}/>

        </Spin>
        <Modal wrapClassName="modal-theme" width="80%" title={modalTitle} open={isModalOpen} footer={[]} destroyOnClose
          onCancel={() => { setIsModalOpen(false) }}>
          {modalContent}
        </Modal>
      </div>
    </Spin>
  );
}



export default Nationality;
