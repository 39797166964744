import axios from 'axios';
import { Tag, Typography, Popconfirm, message, Spin, Modal, Button, Row, Col, Card } from 'antd';
import {
  CloseOutlined,
  CheckCircleOutlined,
  DragOutlined
} from '@ant-design/icons';

export default {
  getIntroduction: async function (setFuncMap) {
    try {
      const res = await this.getIntroductionAction()
      setFuncMap.setIntroduction(res.data)
      setFuncMap.setIsPageLoading(false)
    } catch (error) {
    }
  },
  getIntroductionAction: () => {
    return axios({
      method: 'GET',
      url: `/site/introduction`
    })
  }
};
