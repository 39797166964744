import axios from 'axios';
import { Tag, Typography, Popconfirm, message, Spin, Modal, Button, Row, Col, Card } from 'antd';
import {
  CloseOutlined,
  CheckCircleOutlined,
  DragOutlined
} from '@ant-design/icons';

export default {
  getApprovedPapers: async function (setFuncMap) {
    try {
      const res = await this.getApprovedPapersAction()
      let records = res.data
      let sortedRecords = records.sort((a, b) => {
        if (a.sort !== b.sort) {
          return a.sort - b.sort;  // 依 sort 值從小到大排序
        }
        return a.paperKey.localeCompare(b.paperKey);  // 若 sort 相同，依 key 字母順序排序
      });

      setFuncMap.setApprovedPapers(sortedRecords)
      setFuncMap.setIsPageLoading(false)
    } catch (error) {
      console.log({error})
    }
  },
  getApprovedPapersAction: () => {
    return axios({
      method: 'GET',
      url: `/site/paper-approved`
    })
  },
  generateApprovedSubmissionPaperMenu: function () {
    return [
      {
        title: ' ',
        dataIndex: 'title',
        key: 'title',
        render: (_, render) => {
          return <>
            <div style={{ fontWeight: "bold" }}>{render.resultName}</div>
            <div>{render.callForPaperType}</div>
            <div>
              <i style={{ color: "blue" }}><div style={{ fontWeight: "bold" }}>{render.paperKey}</div></i>
              <div style={{ fontWeight: "bold" }}>{render.titleCn}</div>
              <div style={{ fontWeight: "bold" }}>{render.titleEn}</div>
            </div>
            <div>
              {render.author1 && <Tag>{render.author1}</Tag>}
              {render.author2 && <Tag>{render.author2}</Tag>}
              {render.author3 && <Tag>{render.author3}</Tag>}
              {render.author4 && <Tag>{render.author4}</Tag>}
              {render.author5 && <Tag>{render.author5}</Tag>}
              {render.author6 && <Tag>{render.author6}</Tag>}
            </div>
          </>
        }
      }]
  }
};
