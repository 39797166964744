import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Table } from 'antd';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

const type = 'DraggableRow';

const DraggableRow = ({ index, moveRow, className, style, ...restProps }) => {
  const ref = useRef();
  const [, drop] = useDrop({
    accept: type,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveRow(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <tr
      ref={ref}
      className={className}
      style={{
        ...style,
        backgroundColor: isDragging ? 'grey' : 'white',
        color: isDragging ? 'grey' : 'black',
        cursor: 'move',
      }}
      {...restProps}
    />
  );
};

const DraggableTable = ({ dataSource: initialDataSource, columns, mode, setFuncMap }) => {
  const [dataSource, setDataSource] = useState(initialDataSource);
  const [originalOrder, setOriginalOrder] = useState(initialDataSource.map(item => `${item.id}${item.sort}`));
  const [currentOrder, setCurrentOrder] = useState(initialDataSource.map(item => `${item.id}${item.sort}`));
  useEffect(() => {
    setOriginalOrder(initialDataSource.map(item => `${item.id}${item.sort}`))
    setDataSource(initialDataSource)
    // if (JSON.stringify(initialDataSource.map(item => `${item.id}${item.sort}`)) != JSON.stringify(originalOrder)) {
    //   setDataSource(initialDataSource)
    // }
  }, [initialDataSource])
  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = dataSource[dragIndex];
      const updatedDataSource = update(dataSource, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });
      setDataSource(updatedDataSource);
      if (updatedDataSource.map(item => `${item.id}${item.sort}`).join(",") === originalOrder.join(",")) {
        resetSortData(mode, setFuncMap)
      }
      else {
        setFuncMap.setSubmittedPaperTypesSortChange(updatedDataSource.map((item, index) => {
          return {
            id: item.id, sort: index + 1
          }
        }))
      }
      setCurrentOrder(updatedDataSource.map(item => `${item.id}${item.sort}`));
    },
    [dataSource],
  );
  return (
    <DndProvider backend={HTML5Backend}>
      <Table
        columns={columns}
        dataSource={dataSource}
        components={{
          body: {
            row: DraggableRow,
          },
        }}
        onRow={(record, index) => ({
          index,
          moveRow,
        })}
      />
      {/* <div style={{ marginTop: '20px' }}>
        <h3>Original Order: {originalOrder.join(', ')}</h3>
        <h3>Current Order: {currentOrder.join(', ')}</h3>
      </div> */}
    </DndProvider>
  );
};

const resetSortData = function (mode, setFuncMap) {
  if (mode === "CN") {
    setFuncMap.setCnSortChange([])
  }
  if (mode === "EN") {
    setFuncMap.setEnSortChange([])
  }
}


export default DraggableTable;