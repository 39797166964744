import axios from 'axios';
import { toolUtils } from '../../../../utils/toolUtils'
import { Table, Typography, message, Spin, Modal, Button } from 'antd';
import {
  HighlightOutlined,
  CheckOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined
} from '@ant-design/icons';
export default {
  getConferences: async function (setFuncMap) {
    try {
      setFuncMap.setIsTableLoading(true)
      const res = await this.getConferencesAction(1, 100)
      let conferencesCom = res.data.datas.map((data, k) => {
        return {
          isUpdateName: false,
          isNotAvailableEnter: false,
          ...data
        }
      })
      setFuncMap.setConferences(conferencesCom)
      setFuncMap.setIsTableLoading(false)
      setFuncMap.setIsPageLoading(false)
    } catch (error) {
      setFuncMap.setIsTableLoading(false)
      // message.error(error.response.data)
    }
  },
  getConferencesAction: (page, size) => {
    return axios({
      method: 'GET',
      url: `/conferences?page=${page}&size=${size}`,
      headers: {
        "role": "topUser"
      }
    })
  },
  updateReferenceNameByUid: async function (payload, setFuncMap) {
    const { waitUpdateData, conferences } = payload
    let instance = JSON.parse(JSON.stringify(conferences))
    let conf = instance.find(conference => {
      return conference.uid === waitUpdateData.uid
    })
    try {
      setFuncMap.setIsModalOpen(false)
      conf.isUpdateName = true
      conf.isNotAvailableEnter = true
      setFuncMap.setConferences(instance)
      await this.updateConferenceAction(waitUpdateData)
      let cloneInstance = toolUtils.clone(instance)
      let cloneConf = cloneInstance.find(conference => {
        return conference.uid === waitUpdateData.uid
      })
      cloneConf.name = waitUpdateData.after
      cloneConf.isUpdateName = false
      cloneConf.isNotAvailableEnter = false
      setFuncMap.setConferences(cloneInstance)
    }
    catch (e) {
  
    }
  },
  updateConferenceAction: (waitUpdateData) => {
    return axios.put(`/conference/${waitUpdateData.uid}`, {
      "name": waitUpdateData.after
    }, {
      headers: {
        "role": "topUser"
      }
    })
  },
  generateConferenceColumns: function (params) {
    const { Text } = Typography
    const {conferences, setFuncMap, location, navigate, getReferenceByUid} = params
    const NameCell = function (props) {
      let render = props.render
      return <Spin spinning={render.isUpdateName}><Text editable={{
        icon: <HighlightOutlined />,
        tooltip: 'click to edit text',
        onChange: (text) => {
          let conf = getReferenceByUid(render.uid, conferences)
          if (text !== conf.name) {
            setFuncMap.setWaitUpdateData({
              uid: conf.uid,
              before: conf.name,
              after: text
            })
            setFuncMap.setIsModalOpen(true)
          }
        },
        enterIcon: <CheckOutlined />,
      }}>
        {render.name}
      </Text></Spin>
    }
    return [
      {
        title: '研討會名稱',
        dataIndex: 'name',
        key: 'name',
        width: 500,
        render: (_, render) => {
          return <NameCell render={render} />
        },
      },
      {
        title: '狀態',
        dataIndex: 'isActive',
        key: 'isActive',
        align: 'center',
        render: (val) => {
          return <>{val === 0 ? <PauseCircleOutlined fill="#000000" /> : <PlayCircleOutlined />}</>
        }
      },
      {
        title: '最後更新時間',
        dataIndex: 'lastUpdateAt',
        key: 'lastUpdateAt',
      },
      {
        title: '建立時間',
        dataIndex: 'createAt',
        key: 'createAt',
      },
      {
        title: ' ',
        dataIndex: 'uid',
        key: 'uid',
        render: (_, record) => {
          return <Button onClick={() => {
            navigate(`${location.pathname}/${record.uid}`)
          }} disabled={record.isNotAvailableEnter}>前往編輯</Button>
        }
      }]
  },
  getReferenceByUid: (uid, conferences) => {
    let instance = JSON.parse(JSON.stringify(conferences))
    return instance.find(conference => {
      return conference.uid === uid
    })
  }
}