import axios from 'axios';
import pfn from './RegistrationFeeFns.js'
import { toolUtils } from '../../../../utils/toolUtils.js'

export default {
  updateRegistrationFee: async function (payload, props, setFuncMap) {
    try {
      setFuncMap.setIsContentLoading(true)
      if (payload.id) {
        await this.updateRegistrationFeeByIdAction(payload, props.conferenceUid)
      }
      else {
        await this.updateRegistrationFeeAction(payload, props.conferenceUid)
      }
      let res = await pfn.getRegistrationFeesAction(props.conferenceUid)
      setFuncMap.setIsContentLoading(false)
      setFuncMap.setLangRegistrationFee(res.data.find(item => { return item.lang === payload.lang }))
    } catch (error) {
    }
  },
  updateRegistrationFeeAction: (payload, conferenceUid) => {
    return axios({
      method: 'POST',
      url: `/conference/${conferenceUid}/registration-fee`,
      headers: {
        "role": "topUser"
      },
      data: payload
    })
  },
  updateRegistrationFeeByIdAction: (payload, conferenceUid) => {
    return axios({
      method: 'POST',
      url: `/conference/${conferenceUid}/registration-fee/${payload.id}`,
      headers: {
        "role": "topUser"
      },
      data: payload
    })
  },
  getRegistrationFee: async function (props, setFuncMap) {
    try {
      setFuncMap.setIsContentLoading(true)
      const res = await this.getRegistrationFeesAction(props.conferenceUid)
      let result = {}
      res.data.forEach(item => {
        result[item.lang] = item
      })
      setFuncMap.setLecturerMap(result)
      setFuncMap.setIsContentLoading(false)
    } catch (error) {
    }
  },
  getRegistrationFeeByIdAction: (conferenceUid) => {
    return axios({
      method: 'GET',
      url: `/conference/${conferenceUid}/registration-fee`,
      headers: {
        "role": "topUser"
      }
    })
  },
};
