import React, { useEffect, useState } from 'react';
// import 'antd/dist/antd.css';
import { Table, Radio, message, Spin, Modal, Button, Row, Col, Card } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import efn from './SubmittedPaperTypeFns'
import langObj from '../TopUserLang'
import './SubmittedPaperType.scss';
import DraggableTable from './DraggableTable'

function SubmittedPaperType(props) {
  const navigate = useNavigate()
  const location = useLocation()
  const [submittedPaperTypes, setSubmittedPaperTypes] = useState([])
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState("")
  const [modalContent, setModalContent] = useState()
  const [submittedPaperTypesSortChange, setSubmittedPaperTypesSortChange] = useState([])
  const [isButtonSortLoading, setIsButtonSortLoading] = useState(false)
  const setFuncMap = {
    setSubmittedPaperTypes, setIsPageLoading, setIsModalOpen,
    setIsTableLoading, setModalTitle, setModalContent,
    setSubmittedPaperTypesSortChange,
    message, setIsButtonSortLoading
  }
  const moveColumns = efn.generateSubmittedPaperTypeMoveMenu(props, setFuncMap)
  useEffect(() => {

    efn.getSubmittedPaperTypes(props, setFuncMap)
    efn.getConference(props, setFuncMap)

  }, []);

  return (
    <Spin spinning={isPageLoading}>
      <div className="SubmittedPaperType">
        <Row gutter={16} justify="end">
          {submittedPaperTypesSortChange.length > 0 && <Col>
            <Button type="primary" loading={isButtonSortLoading}
              onClick={() => { efn.updateSubmittedPaperTypesSort(submittedPaperTypesSortChange, props, setFuncMap) }}>更新排序</Button>
          </Col>}
          <Col>
            <Button type="primary" onClick={() => { efn.openSubmittedPaperTypeCreatePage(props.conferenceUid, "建立稿件類別", setFuncMap) }}>建立稿件類別</Button>
          </Col>
        </Row>
        <Spin spinning={isTableLoading}>          
          <DraggableTable dataSource={submittedPaperTypes}
            columns={moveColumns}
            setFuncMap={setFuncMap}/>

        </Spin>
        <Modal wrapClassName="modal-theme" width="80%" title={modalTitle} open={isModalOpen} footer={[]} destroyOnClose
          onCancel={() => { setIsModalOpen(false) }}>
          {modalContent}
        </Modal>
      </div>
    </Spin>
  );
}



export default SubmittedPaperType;
