import React, { useState, useEffect } from 'react';
import efn from './SubmissionPaperAdminFns.js'
import { DataContext } from '../../../../Context/DataContext.js';
import { Popconfirm, Form, Typography, DatePicker, Select, Input, Button, Row, Col } from 'antd';

const SubmissionPaperAdminAccept = (props) => {
  const {t} = React.useContext(DataContext)
  let setFuncMap = {
    t,
    ...props.setFuncMap
  }
  const [rejectReason, setRejectReason] = useState()
  return (
    <Row gutter={16}>
      <Col style={{ display: "flex" }}>
        <Popconfirm
          placement="left"
          title={`確認要接受?`}
          onConfirm={() => {
            efn.approveSubmissionPaper(props.conferenceUid, props.id, props.status, setFuncMap)
          }}
          okText="Yes"
          cancelText="No"
        ><Button type="primary">接受</Button>
        </Popconfirm>
      </Col>
      <Col style={{ display: "flex" }}>
        <Popconfirm
          placement="left"
          title={`確認要拒絕?`}
          onConfirm={() => {
            efn.rejectSubmissionPaper(props.conferenceUid, rejectReason, props.id, props.status, setFuncMap)
          }}
          okText="Yes"
          cancelText="No"
        ><Button type="primary" style={{ marginRight: "8px" }} danger>拒絕</Button>
        </Popconfirm>
        <Input style={{ flex: 1 }} onKeyUp={(value) => {
          setRejectReason(value.currentTarget.value)
        }}></Input>
      </Col>
    </Row>
  );
};

export default SubmissionPaperAdminAccept;