import React, { useCallback, useRef, useState, useEffect } from 'react';
import { localStorageUtils } from '../../../utils/localStorageUtils.js';
import { socketUtils } from '../../../utils/socketUtils.js'
import { useLocation, useParams, Link as DOMLink, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import './Dashboard.scss';
import menuItems from './DashboardMenuItems.js';
import Conferences from './Conference/Conferences.js';
import Conference from './Conference/Conference.js';
import Announcement from './Announcement/Announcement.js';
import Introdution from './Introdution/Introdution.js'
import Lecturer from './Lecturer/Lecturer.js'
import InviteInfo from './InviteInfo/InviteInfo.js'
import RegistrationFee from './RegistrationFee/RegistrationFee.js'
import SubmissionNote from './SubmissionNote/SubmissionNote.js'
import ReviewNote from './ReviewNote/ReviewNote.js'
import Agenda from './Agenda/Agenda.js'
import File from './File/File.js'
import Link from './Link/Link.js'
import Nationality from './Nationality/Nationality.js'
import SubmittedPaperType from './SubmittedPaperType/SubmittedPaperType.js'
import ReviewResultType from './ReviewResultType/ReviewResultType.js'
import PaymentWay from './PaymentWay/PaymentWay.js'
import Bank from './Bank/Bank.js'
import CallForPapers from './CallForPapers/CallForPapers.js'
import Config from './Config/Config.js'
import Reviewer from './Reviewer/Reviewer.js'
import User from './User/User.js'
import SubmissionPaper from './SubmissionPaper/SubmissionPaper.js'
import { Layout, Menu, Button, Tooltip, Typography, Dropdown, Avatar, Divider, Space, theme } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  LaptopOutlined,
  NotificationOutlined,
  DownOutlined,
} from '@ant-design/icons';

const { Header, Sider, Content, Footer } = Layout;
const { Text, Title } = Typography;
const { useToken } = theme;
const Dashboard = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [defaultValue, setDefaultValue] = useState({});
  const [contentHeader, setContentHeader] = useState(<></>);
  const { token } = useToken();
  const { module, conferenceUid, feature } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };
  const menuStyle = {
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 0'
  };
  const items = [
    {
      key: '1',
      label: defaultValue.account
    },
    {
      key: '2',
      label: (<a href="#" onClick={() => {
        localStorageUtils.remove("topUser")
        navigate(`/mnt/top-user/login`)
      }}>Logout</a>),
    }
  ];
  useEffect(() => {
    // 這段代碼只會在組件首次渲染後執行一次
    let topUser = localStorageUtils.get("topUser")
    if (topUser) {
      if (topUser.isChangePwd > 0) {
        navigate(`/mnt/top-user/reset-password?fromPath=${location.pathname}`)
      }
      else {
        setDefaultValue({ account: topUser.account })
      }
    }
    else {
      navigate(`/mnt/top-user/login`)
    }

  }, []); // 空數組作為依賴項數組

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const menuDefaultKey = getDefaultMenuSetting(location.pathname)
  console.log({ menuDefaultKey })
  const isConferenceHome = (conferenceUid && !module && !feature)
  console.log({ isConferenceHome: isConferenceHome ? [] : null })
  return (
    <div className="Dashboard">
      <Layout style={{ height: '100vh' }}>
        <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed}
          style={{ display: "flex", flexDirection: "column" }}>
          <div className="logo" style={{ height: '32px', margin: '16px' }}>
            <Tooltip title={defaultValue.account}>
              <Text ellipsis={true} style={{ color: '#ffffff' }}>{defaultValue.account}</Text>
            </Tooltip>
          </div>
          <Menu theme="dark"
            defaultOpenKeys={[menuDefaultKey.openKey]}
            defaultSelectedKeys={[menuDefaultKey.key]}
            mode="inline"
            items={menuItems(conferenceUid, location.pathname)}
            style={{ display: "flex", flexDirection: "column", overflow: "auto" }} />
        </Sider>
        <Layout className="site-layout">
          <Content style={{ margin: '4px', display: "flex", flexDirection: "column", flex: 1 }}>
            <Header className="site-layout-background"
              style={{ height: '32px', padding: '0 8px', background: '#fff', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Title level={5} style={{ margin: 0 }}>{contentHeader}</Title>
              <Dropdown placement="bottomRight"
                menu={{ items, }}
                dropdownRender={(menu) => (
                  <div style={contentStyle}>
                    {React.cloneElement(menu, {
                      style: menuStyle,
                    })}
                    {/* <Divider
                      style={{
                        margin: 0,
                      }}
                    />
                    <Space
                      style={{
                        padding: 8,
                      }}
                    >
                      <Button type="primary">Click me!</Button>
                    </Space> */}
                  </div>
                )}>
                <Button
                  type="link"
                  className="ant-dropdown-link"
                  style={{ display: 'flex', alignItems: 'center' }}
                  onClick={e => e.preventDefault()}>
                  <Avatar style={{ backgroundColor: '#87d068', width: '16px', height: '16px', fontSize: '0.5rem', marginRight: '10px' }} icon={<UserOutlined />} />
                  <Text ellipsis={true} style={{ width: '100px', color: '#434343', fontSize: '0.8rem' }}>{defaultValue.account}</Text>
                  <DownOutlined />
                </Button>
              </Dropdown>
            </Header>
            <div className="site-layout-background"
              style={{ display: "flex", flexDirection: "column", flex: 1, overflow: "auto", padding: 8 }}>
              {module === "conferences" && <Conferences setContentHeader={setContentHeader} />}
              {isConferenceHome && <Conference setContentHeader={setContentHeader} conferenceUid={conferenceUid} />}
              {module === "setting" && <>
                {feature === "announcements" && <Announcement setContentHeader={setContentHeader} conferenceUid={conferenceUid} />}
                {feature === "nationalities" && <Nationality setContentHeader={setContentHeader} conferenceUid={conferenceUid} />}
                {feature === "submitted-paper-types" && <SubmittedPaperType setContentHeader={setContentHeader} conferenceUid={conferenceUid} />}
                {feature === "review-result-types" && <ReviewResultType setContentHeader={setContentHeader} conferenceUid={conferenceUid} />}
                {feature === "payment-ways" && <PaymentWay setContentHeader={setContentHeader} conferenceUid={conferenceUid} />}
                {feature === "banks" && <Bank setContentHeader={setContentHeader} conferenceUid={conferenceUid} />}
                {feature === "call-for-papers-types" && <CallForPapers setContentHeader={setContentHeader} conferenceUid={conferenceUid} />}
                {feature === "configs" && <Config setContentHeader={setContentHeader} conferenceUid={conferenceUid} />}
              </>}
              {module === "website" && <>
                {feature === "introdution" && <Introdution setContentHeader={setContentHeader} conferenceUid={conferenceUid} />}
                {feature === "lecturer" && <Lecturer setContentHeader={setContentHeader} conferenceUid={conferenceUid} />}
                {feature === "invite-info" && <InviteInfo setContentHeader={setContentHeader} conferenceUid={conferenceUid} />}
                {feature === "agenda" && <Agenda setContentHeader={setContentHeader} conferenceUid={conferenceUid} />}
                {feature === "registration-fee" && <RegistrationFee setContentHeader={setContentHeader} conferenceUid={conferenceUid} />}
                {feature === "submission-note" && <SubmissionNote setContentHeader={setContentHeader} conferenceUid={conferenceUid} />}
                {feature === "review-note" && <ReviewNote setContentHeader={setContentHeader} conferenceUid={conferenceUid} />}
                {feature === "files" && <File setContentHeader={setContentHeader} conferenceUid={conferenceUid} />}
                {feature === "links" && <Link setContentHeader={setContentHeader} conferenceUid={conferenceUid} />}
              </>}
              {module === "review" && <>
                {feature === "accounts" && <Reviewer setContentHeader={setContentHeader} conferenceUid={conferenceUid} />}
                {feature === "submission-papers" && <SubmissionPaper setContentHeader={setContentHeader} conferenceUid={conferenceUid} />}
              </>}
              {module === "general" && <>
                {feature === "accounts" && <User setContentHeader={setContentHeader} conferenceUid={conferenceUid} />}                
              </>}
            </div>
          </Content>
          <Footer style={{ textAlign: 'center', padding: '4px 0' }}>©2024 ICEI 教育創新國際學術研討會. All Rights Reserved.</Footer>
        </Layout>
      </Layout>
    </div>
  );
};

const getDefaultMenuSetting = function (path) {
  let pathArr = path.split("/")
  pathArr.pop()
  return {
    key: path,
    openKey: `${pathArr.join('/')}`
  }
}

export default Dashboard;

