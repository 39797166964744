import React, { useState, useEffect } from 'react';
import efn from './UserSubmissionPaperFinalVersionUpsertPageFns.js'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Spin, Form, Switch, Upload, Select, Input, Button, Row, Col } from 'antd';
import { toolUtils } from '../../../../../utils/toolUtils.js'
import { DataContext } from '../../../../../Context/DataContext.js';
import {
  UploadOutlined,
} from '@ant-design/icons';
import cnTemplate from './public/cnTemplate.docx'
import enTemplate from './public/enTemplate.docx'

const { TextArea } = Input;
const { Option } = Select;

const UserSubmissionPaperFinalVersionUpsertPage = (props) => {
  const { t } = React.useContext(DataContext)
  const [form] = Form.useForm();
  const [isModalLoading, setIsModalLoading] = useState(false)
  const { subAction, id, subId } = useParams();
  const [cnFileList, setCnFileList] = useState([])
  const [isCnShowUpload, setIsCnShowUpload] = useState(isNaN(parseInt(subId)))
  const [enFileList, setEnFileList] = useState([])
  const [isEnShowUpload, setIsEnShowUpload] = useState(isNaN(parseInt(subId)))
  const navigate = useNavigate()
  const location = useLocation()
  const colSpan = 12
  const setFuncMap = {
    setIsModalLoading,
    setCnFileList, setIsCnShowUpload,
    setEnFileList, setIsEnShowUpload,
    setFieldsValue: form.setFieldsValue,
    navigate,
    t,
    ...props.setFuncMap
  }
  const onFinish = values => {
    console.log('Form values:', values);
    values.submissionPaperId = parseInt(id)
    efn.upsertFinalVersionPaper(values, setFuncMap)

  };
  useEffect(() => {
    setIsModalLoading(true)
    if (!isNaN(parseInt(subId))) {
      efn.getUserSubmissionPaperFinalVersionById(subId, setFuncMap)
    }
    setIsModalLoading(false)
  }, [subId, location.search])

  useEffect(() => {
    setIsCnShowUpload(true)
  }, [cnFileList])

  useEffect(() => {
    setIsEnShowUpload(true)
  }, [enFileList])


  return (
    <Spin spinning={isModalLoading}>
      <Form
        form={form}
        layout="horizontal"
        onFinish={onFinish}
      >
        <Row gutter={16}>
          <Col span={12}>{generateTipContent()}</Col>
          <Col span={12}>
            <Row>
              <Col span={24}>{generateDownloadCNTemplateButton(cnTemplate)}</Col>
              <Col span={24}>{generateDownloadENTemplateButton(enTemplate)}</Col>
            </Row>
            {efn.generateFinalVersionItmes(efn, t, setFuncMap).map((item, key) => {
              return <Row key={key} justify={'center'} gutter={16}>
                {item.type === "checkbox" && <Col span={colSpan}>
                  <Form.Item
                    label={item.label}
                    name={`${item.key}`}
                    valuePropName="checked"
                    rules={item.rules}
                  >
                    <Switch />
                  </Form.Item>
                </Col>}
                {item.type === "file" && <Col span={colSpan}>
                  <Form.Item
                    label={item.label}
                    name={`${item.key}`}
                    rules={item.rules}
                    style={{ height: "50px" }}
                  >
                    {item.key == "cnFinalVersion" && isCnShowUpload && <Upload {...efn.generateUploadProps(form, item.key, cnFileList, setFuncMap)}>
                      <Button icon={<UploadOutlined />}></Button>
                    </Upload>}
                    {item.key == "enFinalVersion" && isEnShowUpload && <Upload {...efn.generateUploadProps(form, item.key, enFileList, setFuncMap)}>
                      <Button icon={<UploadOutlined />}></Button>
                    </Upload>}
                  </Form.Item>
                </Col>}
                {item.type === "hidden" && <Col span={colSpan}>
                  <Form.Item
                    label=' '
                    name={`${item.key}`}
                    hidden
                  >
                    <Input type="hidden" />
                  </Form.Item>
                </Col>}
              </Row>
            })}
          </Col>
        </Row>
        <Row justify={"center"} gutter={16}>
          {["final-version"].indexOf(subAction) > -1 && <><Col>
            <Form.Item>
              <Button type="default" onClick={() => {
                navigate("/submission-paper")
              }}>
                取消
              </Button>
            </Form.Item>
          </Col>
            <Col>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  儲存
                </Button>
              </Form.Item>
            </Col></>}
        </Row>
        {id && <Form.Item
          name="id"
          hidden
        >
          <Input type="hidden" />
        </Form.Item>}
      </Form>
    </Spin>
  );
};

const generateDownloadCNTemplateButton = function (template) {
  return <button type="button"
    onClick={() => {
      const link = document.createElement('a');
      link.href = template;
      link.target = '_blank';
      link.download = "[文章ID]手冊摘要定稿格式.docx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }}
    className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
  >
    [文章ID]手冊摘要定稿格式.docx
  </button>
}

const generateDownloadENTemplateButton = function (template) {
  return <button type="button"
    onClick={() => {
      const link = document.createElement('a');
      link.href = template;
      link.target = '_blank';
      link.download = "[Paper no]the Format for the Final Version of the Abstract Style.docx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }}
    className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
  >
    [Paper no]the Format for the Final Version of the Abstract Style.docx
  </button>
}

const generateTipContent = function () {
  return <div>
    <h3 style={{ color: "#ff7b33" }}>注意事項</h3>
    <ul>
      <li>摘要定稿路徑：研討會網站→登入使用選單→我的稿件→上傳定稿</li>
      <li>定稿檔案請上傳word檔</li>
      <li>英語稿件為必要，中文為稿件為選項</li>
      <li>請根據發表語言上傳，中文發表者須分開上傳中、英文定稿檔案</li>
      <li>摘要投稿上傳中文稿件視為中文發表，若需更改發表語言請來信稿件編號等進行告知</li>
      <li>請務必參考並使用手冊摘要定稿格式！</li>
      <li>請勿於定稿內提供任何表格、圖片，一律僅接受文字內容。</li>
      <li>海報發表者除上傳定稿外，請於發表當日自行攜帶A1大小之海報於指定時間完成張貼，底稿請參考檔案下載，謝謝！</li>
    </ul>

    <h3 style={{ color: "#ff7b33" }}>Important Notes</h3>
    <ul>
      <li>Steps: The website of the seminar→ Log in→ My Manascript→Upload final version paper.</li>
      <li>Please upload the final version in a Word document.</li>
      <li>An English manuscript is mandatory. Please upload according to the presentation language.</li>
      <li>Please ensure to refer to and use the finalized format for the handbook summary.</li>
      <li>Do not include any tables or images in the final draft; only text content will be accepted.</li>
      <li>Besides uploading the final version, poster presenters are requested to bring an A1-sized poster on the day of the presentation and complete the posting at the designated time. Please refer to the file download for the template. Thank you!</li>
    </ul>
  </div>
}

export default UserSubmissionPaperFinalVersionUpsertPage;