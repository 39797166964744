import React, { useState, useEffect } from 'react';
import efn from './SubmissionPaperDetailPageFns.js'
import SubmissionPaperAdminAccept from './SubmissionPaperAdminAccept.js'
import SubmissionPaperAdminReviewerSetting from './SubmissionPaperAdminReviewerSetting.js'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import statusCode from '../../../../enum/submissionPaperStatusEnum.js'
import { Spin, Form, Typography, Tag, Select, Input, Button, Row, Col } from 'antd';

const { TextArea } = Input;
const { Option } = Select;

const SubmissionPaperDetailPage = (props) => {
  const [form] = Form.useForm();
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [initialFormValues, setInitialFormValues] = useState({});
  const [abstractHelpData, setabstractHelpData] = useState({ cn: "", en: "" });
  const navigate = useNavigate()
  const location = useLocation()
  const setFuncMap = {
    setIsModalLoading,
    setFieldsValue: form.setFieldsValue,
    navigate,
    setabstractHelpData,
    setInitialFormValues,
    ...props.setFuncMap
  }
  useEffect(() => {
    setIsModalLoading(true)
    efn.getSubmissionPaperById(props.id, props.conferenceUid, setFuncMap)
    setIsModalLoading(false)
  }, [])
  useEffect(() => {
    // 设置初始值
    form.setFieldsValue(initialFormValues);
  }, [form]);
  return (
    <Spin spinning={isModalLoading}>
      {["CREATED", "VERIFY_REJECT"].indexOf(statusCode[initialFormValues.status]) > -1 && <Row gutter={16}>
        <Col span={24}>
          {basicInfo(form, abstractHelpData, efn)}
        </Col>
      </Row>}
      {["WAIT_VERIFY", "REWAIT_VERIFY"].indexOf(statusCode[initialFormValues.status]) > -1 && <Row gutter={16}>
        <Col span={24}>
          <SubmissionPaperAdminAccept status={initialFormValues.status} conferenceUid={props.conferenceUid} setFuncMap={setFuncMap} id={props.id} />
        </Col>
        <Col span={24}>
          {basicInfo(form, abstractHelpData, efn)}
        </Col>
      </Row>}
      {["SUCCESS"].indexOf(statusCode[initialFormValues.status]) > -1 &&
        <Row gutter={16} style={{ height: "70vh" }}>
          <Col span={12} style={{ overflow: "auto", height: "100%" }}>
            {basicInfo(form, abstractHelpData, efn)}
          </Col>
          <Col span={11} style={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <SubmissionPaperAdminReviewerSetting result={initialFormValues.result} setFuncMap={setFuncMap} id={props.id} conferenceUid={props.conferenceUid} />
          </Col>
        </Row>}
      {["FINALVERSION_REJECT"].indexOf(statusCode[initialFormValues.status]) > -1 &&
        <Row gutter={16}>
          <Col span={24}>
            {basicInfo(form, abstractHelpData, efn)}
          </Col>
        </Row>}
      {["WAIT_FINALVERSION_VERIFY", "REWAIT_FINALVERSION_VERIFY"].indexOf(statusCode[initialFormValues.status]) > -1 && <Row gutter={16}>
        <Col span={24}>
          <SubmissionPaperAdminAccept status={initialFormValues.status} conferenceUid={props.conferenceUid} setFuncMap={setFuncMap} id={props.id} />
        </Col>
        <Col span={24}>
          {basicInfo(form, abstractHelpData, efn)}
        </Col>
      </Row>}
    </Spin>
  );
};

const basicInfo = function (form, abstractHelpData, efn) {
  return <Form
    form={form}
    layout="horizontal"
  >
    <Row gutter={16}>
      <Col span={24}>
        {efn.generateSubmissionPaperAttribute(abstractHelpData).map((item, key) => {
          return <Form.Item key={key} style={{ borderBottom: "1px dotted #848383", alignItems: 'flex-start' }}
            name={item.name}
            label={<span style={{ whiteSpace: 'pre-wrap', display: 'block', fontWeight: 'bold' }}>{item.label}</span>}
            rules={[{ required: item.required, message: `請輸入${item.label}` }, ...item.rules]}
            labelCol={{ span: 24 }}  // 设置 label 的宽度
            wrapperCol={{ span: 24 }} // 设置表单项内容的宽度
          >
            {item.type == "text-tag" ?
              <Typography.Text size="large" style={{ whiteSpace: 'pre-wrap' }} >
                {form.getFieldValue(item.name) && form.getFieldValue(item.name).split(";").map((word, k) => {
                  return <Tag color="#868986" key={k}>{word}</Tag>
                })}
              </Typography.Text>
              : <Typography.Text size="large" style={{ whiteSpace: 'pre-wrap' }} >{form.getFieldValue(item.name)}</Typography.Text>}

          </Form.Item>
        })}
      </Col>
    </Row>
  </Form>
}

export default SubmissionPaperDetailPage;