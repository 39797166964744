import React, { useEffect, useState } from 'react';
// import 'antd/dist/antd.css';
import { Table, Radio, message, Spin, Modal, Button, Row, Col, Card } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import efn from './FileFns'
import langObj from '../TopUserLang'
import './File.scss';
import DraggableTable from './DraggableTable'

function File(props) {
  const navigate = useNavigate()
  const location = useLocation()
  const [files, setFiles] = useState([])
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState("")
  const [modalContent, setModalContent] = useState()
  const [cnSortChange, setCnSortChange] = useState([])
  const [enSortChange, setEnSortChange] = useState([])
  const [isButtonSortLoading, setIsButtonSortLoading] = useState(false)
  const [cnFiles, setCnFiles] = useState([])
  const [mode, setMode] = useState('All');
  const handleModeChange = (e) => {
    setMode(e.target.value);
  };
  const setFuncMap = {
    setFiles, setIsPageLoading, setIsModalOpen,
    setIsTableLoading, setModalTitle, setModalContent,
    message, setCnSortChange, setEnSortChange, setIsButtonSortLoading
  }
  const columns = efn.generateFileMenu(props, setFuncMap)
  const moveColumns = efn.generateFileMoveMenu(props, setFuncMap)
  useEffect(() => {
    setCnFiles(efn.getLangSortedFiles(files, "CN"))
  }, [files])

  // let cnFiles = efn.getLangSortedFiles(Files, "CN")
  let enFiles = efn.getLangSortedFiles(files, "EN")
  useEffect(() => {

    efn.getFiles(props, setFuncMap)
    efn.getConference(props, setFuncMap)

  }, []);

  return (
    <Spin spinning={isPageLoading}>
      <div className="File">
        <Row gutter={16} justify="end">
          {mode === "CN" && cnSortChange.length > 0 && <Col>
            <Button type="primary" loading={isButtonSortLoading}
              onClick={() => { efn.updateFilesSort(mode, cnSortChange, props, setFuncMap) }}>更新排序</Button>
          </Col>}
          {mode === "EN" && enSortChange.length > 0 && <Col>
            <Button type="primary" loading={isButtonSortLoading}
              onClick={() => { efn.updateFilesSort(mode, enSortChange, props, setFuncMap) }}>更新排序</Button>
          </Col>}
          <Col>
            <Button type="primary" onClick={() => { efn.openFileCreatePage(props.conferenceUid, "建立檔案", setFuncMap) }}>建立檔案</Button>
          </Col>
        </Row>
        <Spin spinning={isTableLoading}>
          <Radio.Group
            onChange={handleModeChange}
            value={mode}
            style={{
              marginBottom: 8,
            }}
          >
            {langObj.langsWithAll.map((lang, key) => {
              return <Radio.Button key={key} value={lang.key} style={{ background: lang.color }}>{lang.key}</Radio.Button>
            })}
          </Radio.Group>
          {mode === "All" && <Table dataSource={files} columns={columns} />}
          {mode === "EN" && <DraggableTable dataSource={enFiles}
            columns={moveColumns}
            setFuncMap={setFuncMap}
            mode="EN" />}
          {mode === "CN" && <DraggableTable dataSource={cnFiles}
            columns={moveColumns}
            setFuncMap={setFuncMap}
            mode="CN" />}

        </Spin>
        <Modal wrapClassName="modal-theme" width="80%" title={modalTitle} open={isModalOpen} footer={[]} destroyOnClose
          onCancel={() => { setIsModalOpen(false) }}>
          {modalContent}
        </Modal>
      </div>
    </Spin>
  );
}



export default File;
