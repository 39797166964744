import axios from 'axios';
import { toolUtils } from '../../../../utils/toolUtils.js'
import pfn from './FileFns.js'
import { message, Upload } from 'antd';
export default {
  createFile: async function (conferenceUid, values, setFuncMap) {
    let payload = {
      "lang": values.lang,
      "name": values.name,
      "originFileName": values.originFileName,
      "fileName": values.fileName,
      "ext": values.ext,
      "remark": values.remark,
    }
    console.log({ payload })
    setFuncMap.setIsModalLoading(true)
    console.log({ payload })
    const res = await this.createFileAction(conferenceUid, payload)
    pfn.getFiles({ conferenceUid }, setFuncMap)
    setFuncMap.setIsModalLoading(false)
    setFuncMap.setIsModalOpen(false)
    setFuncMap.message.open({
      key: "create",
      type: 'success',
      content: 'Create success',
      duration: 2,
    });
  },
  createFileAction: (conferenceUid, payload) => {
    return axios.post(`/conference/${conferenceUid}/file`, payload, {
      headers: {
        "role": "topUser"
      }
    })
  },
  getFileById: async function (conferenceUid, id, setFuncMap) {
    try {
      const res = await this.getFileByIdAction(conferenceUid, id);
      let data = res.data
      if (data) {
        // data.startAt = toolUtils.convertStringToDatetime(data.startAt)
        // data.endAt = toolUtils.convertStringToDatetime(data.endAt)
        setFuncMap.setFieldsValue(data)
        setFuncMap.setFileList([{
          uid: data.id,
          name: data.originFileName,
          status: 'done',
          url: `${window.CONFS.cloudDomain}/${data.fileName}`,
        }])
        setFuncMap.setIsGetFile(true)
        if (data.content) {
          setFuncMap.setEditorData(data.content)
        }
      }
    }
    catch (e) {

    }

  },
  getFileByIdAction: (conferenceUid, id) => {
    return axios.get(`/conference/${conferenceUid}/file/${id}`, {
      headers: {
        "role": "topUser"
      }
    })
  },
  updateFile: async function (conferenceUid, values, setFuncMap) {
    let payload = {
      "id": values.id,
      "lang": values.lang,
      "name": values.name,
      "originFileName": values.originFileName,
      "fileName": values.fileName,
      "ext": values.ext,
      "remark": values.remark,
    }
    setFuncMap.setIsModalLoading(true)
    console.log({ payload })
    const res = await this.updateFileAction(conferenceUid, payload)
    pfn.getFiles({ conferenceUid }, setFuncMap)
    setFuncMap.setIsModalLoading(false)
    setFuncMap.setIsModalOpen(false)
    setFuncMap.message.open({
      key: "update",
      type: 'success',
      content: 'Update success',
      duration: 2,
    });
  },
  updateFileAction: (conferenceUid, payload) => {
    return axios.post(`/conference/${conferenceUid}/file/${payload.id}`, payload, {
      headers: {
        "role": "topUser"
      }
    })
  },
  generateUploadProps: function (form, fileList, setFuncMap) {
    return {
      beforeUpload: (file) => {
        // console.log({type: file.type})
        // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        // if (!isJpgOrPng) {
        //   message.error('You can only upload JPG/PNG file!');
        //   return Upload.LIST_IGNORE;
        // }
        const isLt2M = file.size / 1024 / 1024 < 10;
        if (!isLt2M) {
          message.error('Image must smaller than 10MB!');
          return Upload.LIST_IGNORE;
        }
        return true;
      },
      customRequest: (options) => {
        this.handleUpload(options, form)
      },
      onPreview: toolUtils.handleUploadPreview,
      defaultFileList: fileList,
      maxCount: 1
    };
  },
  handleUpload: ({ file, onSuccess, onError, onProgress }, form) => {
    const formData = new FormData();
    formData.append('file', file);
    axios.post('/upload-files/top-user', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        "role": "topUser"
      },
      onUploadProgress: (event) => {
        const percent = Math.round((event.loaded * 100) / event.total);
        onProgress({ percent });
      },
    })
      .then(response => {
        let data = response.data
        onSuccess({ url: `${window.CONFS.cloudDomain}/${data.fileName}`, ...response.data });
        form.setFieldsValue({
          originFileName: data.originFileName,
          fileName: data.fileName,
          ext: data.ext
        })
        // message.success(`${file.name} file uploaded successfully`);
      })
      .catch(error => {
        onError(error);
        message.error(`${file.name} file upload failed.`);
      });
  }
}