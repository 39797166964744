export default {
    0: "default",
    1: "processing",
    2: "error",
    3: "processing",
    4: "success",
    5: "processing",
    6: "error",
    7: "processing",
    8: "#ff4d4f"
}