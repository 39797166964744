import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Typography, Row, Col, Layout, Card, Spin, Select, message } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toolUtils } from '../../../../utils/toolUtils.js'
import { DataContext } from '../../../../Context/DataContext';
import {
  AppstoreOutlined, UserOutlined
} from '@ant-design/icons';
import efn from './UserLogicFns'
import './SignUp.scss';
const { Title, Text } = Typography;
function SignUp(props) {
  const {t} = React.useContext(DataContext)  
  const [isLogining, setIsLogining] = useState(false)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [nationalities, setNationalities] = useState([])
  const navigate = useNavigate()
  const location = useLocation();
  const setFuncMap = {
    setIsLogining, setIsPageLoading, setNationalities, ...props.setFuncMap, t
  }
  const onFinish = (values) => {
    efn.registerUser(values, setFuncMap, {
      navigate, location
    })
  };
  useEffect(() => {
    efn.getNationalities(setFuncMap)
  }, [])
  return (
    <div className="SignUp">
      <Spin spinning={isPageLoading}>
        <Layout>
          <Row justify="center" align="middle">
            <Col span={24}>
              <Card style={{ borderRadius: '8px', display: "flex", flexDirection: "column" }}>
                <Title level={2} style={{ textAlign: 'center', marginBottom: '24px' }}>ICEI Sign Up</Title>
                <Form
                  name="google_login"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  layout="horizontal"
                >
                  {generateSignUpAttribute(nationalities, t).map((item, key) => {
                    return <Form.Item key={key}
                      name={item.name}
                      label={item.label}
                      rules={[{ required: item.required, message: `請輸入${item.label}` }, ...item.rules]}
                    >
                      {item.type == "text" && <Input placeholder={item.label} size="large" />}
                      {item.type == "select" && <Select showSearch={true} options={item.options} filterOption={toolUtils.filterOption} />}
                    </Form.Item>
                  })}
                  <Form.Item>
                    <Row gutter={16} justify={'space-around'}>
                      <Col>
                        <Button loading={isLogining} type="primary" htmlType="submit" style={{ background: "#fea4a4" }} size="large">
                          Sign Up
                        </Button>
                      </Col>
                      <Col>
                        <Button size="large" onClick={() => { navigate(location.pathname) }}>
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Layout>
      </Spin>
    </div>
  );
}

const generateSignUpAttribute = function (nationalities, t) {
  return [{
    label: t('home.user.profile.account'),
    type: "text",
    name: "account",
    required: true,
    rules: [{ type: "email", message: 'It\'s not email format' }]
  },
  {
    label: t('home.user.profile.name'),
    type: "text",
    name: "name",
    required: true,
    rules: []
  },
  {
    label: t('home.user.profile.nationality'),
    type: "select",
    name: "nationality",
    required: true,
    options: [{
      label: "",
      value: null
    }, ...nationalities.map(item => ({
      label: item.name,
      value: item.id
    }))],
    rules: []
  },
  {
    label: t('home.user.profile.department'),
    type: "text",
    name: "unit",
    required: true,
    rules: []
  },
  {
    label: t('home.user.profile.unit'),
    type: "text",
    name: "department",
    required: false,
    rules: []
  },
  {
    label: t('home.user.profile.telephoneNumber'),
    type: "text",
    name: "telephone_number",
    required: true,
    rules: []
  },
  {
    label: t('home.user.profile.cellphoneNumber'),
    type: "text",
    name: "cellphone_number",
    required: true,
    rules: []
  },
  {
    label: t('home.user.profile.zipCode'),
    type: "text",
    name: "zip_code",
    required: true,
    rules: []
  },
  {
    label: t('home.user.profile.address'),
    type: "text",
    name: "address",
    required: true,
    rules: []
  },]
}

export default SignUp;
