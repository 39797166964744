import React, { useState, useEffect } from 'react';
import efn from './ReviewSubmissionPaperDetailPageFns.js'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Spin, Form, Typography, Tag, Select, Input, Button, Row, Col } from 'antd';
import { DataContext } from '../../../../Context/DataContext.js';
const { TextArea } = Input;
const { Option } = Select;

const ReviewSubmissionPaperDetailPage = (props) => {
  const [form] = Form.useForm();
  const [reviewForm] = Form.useForm();
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const {t} = React.useContext(DataContext)
  const [initialFormValues, setInitialFormValues] = useState({
    reviewResultTypes: []
  });
  const navigate = useNavigate()
  const location = useLocation()
  const setFuncMap = {
    setIsModalLoading,
    setFieldsValue: form.setFieldsValue,
    setReviewFieldValue: reviewForm.setFieldsValue,
    navigate,
    setInitialFormValues, t,
    ...props.setFuncMap
  }
  useEffect(() => {
    setIsModalLoading(true)
    efn.getReviewSubmissionPaperById(props.id, setFuncMap)
    setIsModalLoading(false)
  }, [])
  useEffect(() => {
    // 设置初始值
    form.setFieldsValue(initialFormValues);
  }, [form]);
  const onFinish = (values) => {
    efn.updateSubmissionPaperRecord(values, setFuncMap)
  };
  return (
    <Spin spinning={isModalLoading}>
      <Row gutter={16} style={{ height: "70vh" }}>
        <Col span={12} style={{ overflow: "auto", height: "100%" }}>
          {basicInfo(form, efn)}
        </Col>
        <Col span={11} style={{ height: "100%", display: "flex", flexDirection: "column" }}>
          <Form
            onFinish={onFinish}
            form={reviewForm}
            layout="horizontal"
          >
            {efn.generateReviewSubmissionPaperAttribute(initialFormValues.reviewResultTypes).map((item, key) => {
              return <>{item.type === "hidden" ? <Form.Item name={item.name} hidden>
                <Input type="hidden" />
              </Form.Item> : <Form.Item key={key}
                name={item.name}
                label={item.label}
                rules={[{ required: item.required, message: `請輸入${item.label}` }, ...item.rules]}
              >
                {item.type === "select" && <Select options={item.options} />}
                {item.type === "textarea" && <Input.TextArea
                  autoSize={{ minRows: 10, maxRows: 15 }}
                  placeholder={item.label} />}
              </Form.Item>}
              </>
            })}
            <Form.Item>
              <Row gutter={16} justify={'center'}>
                <Col>
                  <Button loading={isLoading} type="primary" htmlType="submit" size="large">
                    儲存
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Spin>
  );
};

const basicInfo = function (form, efn) {
  return <Form
    form={form}
    layout="horizontal"
  >
    <Row gutter={16}>
      <Col span={24}>
        {efn.generateSubmissionPaperAttribute().map((item, key) => {
          return <Form.Item key={key} style={{ borderBottom: "1px dotted #848383" }}
            name={item.name}
            label={<span style={{ whiteSpace: 'pre-wrap', display: 'block', fontWeight: 'bold' }}>{item.label}</span>}
            rules={[{ required: item.required, message: `請輸入${item.label}` }, ...item.rules]}
          >
            {item.type == "text-tag" ?
              <Typography.Text size="large" style={{ whiteSpace: 'pre-wrap' }} >
                {form.getFieldValue(item.name) && form.getFieldValue(item.name).split(";").map((word, k) => {
                  return <Tag color="#868986" key={k}>{word}</Tag>
                })}
              </Typography.Text>
              : <Typography.Text size="large" style={{ whiteSpace: 'pre-wrap' }} >{form.getFieldValue(item.name)}</Typography.Text>}

          </Form.Item>
        })}
      </Col>
    </Row>
  </Form>
}

export default ReviewSubmissionPaperDetailPage;