import axios from 'axios';
import pfn from './LecturerFns.js'
import { toolUtils } from '../../../../utils/toolUtils.js'

export default {
  updateLecturer: async function (payload, props, setFuncMap) {
    try {
      setFuncMap.setIsContentLoading(true)
      if (payload.id) {
        await this.updateLecturerByIdAction(payload, props.conferenceUid)
      }
      else {
        await this.updateLecturerAction(payload, props.conferenceUid)
      }
      let res = await pfn.getLecturersAction(props.conferenceUid)
      setFuncMap.setIsContentLoading(false)
      setFuncMap.setLangLecturer(res.data.find(item => { return item.lang === payload.lang }))
    } catch (error) {
    }
  },
  updateLecturerAction: (payload, conferenceUid) => {
    return axios({
      method: 'POST',
      url: `/conference/${conferenceUid}/lecturer`,
      headers: {
        "role": "topUser"
      },
      data: payload
    })
  },
  updateLecturerByIdAction: (payload, conferenceUid) => {
    return axios({
      method: 'POST',
      url: `/conference/${conferenceUid}/lecturer/${payload.id}`,
      headers: {
        "role": "topUser"
      },
      data: payload
    })
  },
  getLecturer: async function (props, setFuncMap) {
    try {
      setFuncMap.setIsContentLoading(true)
      const res = await this.getLecturersAction(props.conferenceUid)
      let result = {}
      res.data.forEach(item => {
        result[item.lang] = item
      })
      setFuncMap.setLecturerMap(result)
      setFuncMap.setIsContentLoading(false)
    } catch (error) {
    }
  },
  getLecturerByIdAction: (conferenceUid) => {
    return axios({
      method: 'GET',
      url: `/conference/${conferenceUid}/lecturer`,
      headers: {
        "role": "topUser"
      }
    })
  },
};
