import { localStorageUtils } from "../localStorageUtils";
import axios from 'axios';

class MyUploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }

    // 开始上传过程
    upload() {
        return this.loader.file
            .then(file => new Promise((resolve, reject) => {
                this._initListeners(resolve, reject, file);
            }));
    }

    // 中断上传过程
    abort() {
        if (this.cancelToken) {
            this.cancelToken.cancel('Upload aborted');
        }
    }

    // 初始化监听器
    _initListeners(resolve, reject, file) {
        const data = new FormData();
        data.append('file', file);

        this.cancelToken = axios.CancelToken.source();

        axios.post('/upload-files/top-user', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "role": "topUser"
            },
            cancelToken: this.cancelToken.token,
            onUploadProgress: (event) => {
                this.loader.uploadTotal = event.total;
                this.loader.uploaded = event.loaded;
            }
        })
            .then(response => {
                if (response.data.error) {
                    return reject(response.data.error.message);
                }
                resolve({
                    default: `${window.CONFS.cloudDomain}/${response.data.fileName}`,
                    customProperty: {
                        'data-original-path': 'https://cdn.example.com/foo.jpg',
                        'data-uuid': 'abcd',
                        'width': '100%',
                        'height': null
                    },
                });
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    reject('Upload cancelled');
                } else {
                    reject(error.message || 'Upload failed');
                }
            });
    }
}

function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = loader => {
        // Configure the URL to the upload script in your back-end here!
        return new MyUploadAdapter(loader);
    };
}

export default MyCustomUploadAdapterPlugin