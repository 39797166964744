import React, { useEffect, useState } from 'react';
// import 'antd/dist/antd.css';
import { Input, Alert, message, Spin, List, Row, Col, Modal } from 'antd';
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom';
import { DataContext } from '../../../Context/DataContext';
import AnnouncementDetail from './AnnouncementDetail'
import efn from './AnnouncementFns'
import './Announcement.scss';


function Announcement(props) {
    const { globalData } = React.useContext(DataContext)
    const navigate = useNavigate()
    const location = useLocation()
    const { action, id } = useParams();
    const { TextArea } = Input
    const [announcements, setAnnouncements] = useState([])
    const [isPageLoading, setIsPageLoading] = useState(true)
    const [modalConfig, setModalConfig] = useState({
        isOpen: false,
        content: ""
    })

    const [modalTitle, setModalTitle] = useState("")
    const setFuncMap = {
        message, setIsPageLoading, setAnnouncements, navigate, setModalTitle
    }
    useEffect(() => {
        efn.getAnnouncements(setFuncMap)
    }, [globalData.langChangeTime]);

    useEffect(() => {
        if (action == "show" && id != null) {
            setModalConfig({
                isOpen: true,
                content: <AnnouncementDetail id={id} setFuncMap={setFuncMap} />
            })
        }
        else {
            setModalConfig({
                isOpen: false
            })
        }
    }, [location.pathname]);
    return (
        <Spin spinning={isPageLoading}>
            <div className="Announcement">
                {announcementItems(announcements)}
            </div>
            <Modal wrapClassName="modal-theme"
                width={"80%"}
                title={<Row justify={"center"}><Col>{modalTitle}</Col></Row>}
                open={modalConfig.isOpen}
                footer={[]}
                destroyOnClose
                onCancel={() => {
                    navigate("/announcements", { replace: true })
                }}>
                {modalConfig.content}
            </Modal>
        </Spin>
    );
}


const announcementItems = (announcements) => {
    return announcements.map(item => {
        return <Link to={`/announcements/show/${item.id}`}><Row gutter={24} justify={"start"} className='Announcement-item'>
            <Col span={2} className={item.isTop == 1 ? "Top" : ""}>
                {item.isTop == 1 && <div>TOP</div>}</Col>
            <Col style={{ flex: 1 }}>{item.title}</Col>
        </Row></Link>
    })
}



export default Announcement;
