import React, { useEffect, useState } from 'react';
// import 'antd/dist/antd.css';

import { Input, Radio, message, Spin, Modal, Button, Row, Col, Form } from 'antd';
import CKEditorUtil from '../../../../utils/ckEditorUtil'
import efn from './IntrodutionUpsertPageFns'
import moment from 'moment'

function IntrodutionUpsertPage(props) {
  const { lang, introductionMap } = props
  const [form] = Form.useForm();
  const { TextArea } = Input
  const [langIntroduction, setLangIntroduction] = useState()
  const [editorData, setEditorData] = useState();
  let setFuncMap = { setLangIntroduction, setEditorData, ...props.setFuncMap }
  const onFinish = values => {
    values["lang"] = lang
    values["content"] = editorData
    efn.updateIntroduction(values, props, setFuncMap)
  };

  useEffect(() => {
    setLangIntroduction(introductionMap[lang])
    form.setFieldsValue(introductionMap[lang])
  }, [introductionMap])

  useEffect(() => {
    form.setFieldsValue(langIntroduction)
  }, [langIntroduction])

  return (
    <Form
      form={form}
      layout="horizontal"
      onFinish={onFinish}
    >
      <CKEditorUtil setFuncMap={setFuncMap} defaultValue={langIntroduction ? langIntroduction.content : null} />
      <Form.Item
        name="id"
        hidden
      >
        <Input type="hidden" />{langIntroduction && langIntroduction.lastUpdateAt && `最後更新時間: ${moment(langIntroduction.lastUpdateAt).format("yyyy-MM-DD HH:mm:ss")}`}
      </Form.Item>
      <Row justify={"center"} gutter={16}>
        <Col>
          <Button type="primary" htmlType="submit">
            更新
          </Button>
        </Col>
      </Row>

    </Form>
  );
}



export default React.memo(IntrodutionUpsertPage);
