import axios from 'axios';
import { toolUtils } from '../../../../utils/toolUtils.js'
import pfn from './ReviewSubmissionPapersFns'
export default {
  getReviewSubmissionPaperById: async function (id, setFuncMap) {
    try {
      setFuncMap.setIsModalLoading(true)
      const res = await this.getReviewSubmissionPaperByIdAction(id);
      let data = res.data
      setFuncMap.setInitialFormValues(data)
      setFuncMap.setFieldsValue(data)
      setFuncMap.setReviewFieldValue({
        score: data.score,
        result: data.result,
        comment: data.comment,
        id: data.recordId
      })
      setFuncMap.setIsModalLoading(false)
    }
    catch (e) {
      setFuncMap.setIsModalLoading(false)
      console.log(e)
    }

  },
  getReviewSubmissionPaperByIdAction: (id) => {
    return axios.get(`/review-paper/${id}`, {
      headers: {
        "role": "reviewer"
      }
    })
  },
  updateSubmissionPaperRecord: async function (payload, setFuncMap) {
    setFuncMap.setIsModalLoading(true)
    console.log({ payload })
    const res = await this.updateSubmissionPaperRecordAction(payload, payload.id)
    pfn.getWaitReviewSubmissionPapers(setFuncMap)
    setFuncMap.setIsModalLoading(false)
    setFuncMap.message.open({
      key: "update",
      type: 'success',
      content: setFuncMap.t(`system.updateSuccess`),
      duration: 2,
    });
    setFuncMap.setModalConfig({
      isOpen: false,
    })
  },
  updateSubmissionPaperRecordAction: (payload, id) => {
    return axios.put(`/review-paper/record/${id}`, payload, {
      headers: {
        "role": "reviewer"
      }
    })
  },
  generateSubmissionPaperAttribute: function () {
    return [
      // {
      //   label: "徵稿群組",
      //   type: "text",
      //   name: "callForPaperTypeGroup",
      //   required: false,
      //   rules: []
      // },
      {
        label: "徵稿類別",
        type: "text",
        name: "callForPaperType",
        required: true,
        rules: []
      },
      {
        label: "稿件類別",
        type: "text",
        name: "paperType",
        required: true,
        rules: []
      },
      {
        label: "中文標題",
        type: "text",
        name: "titleCn",
        required: false,
        rules: []
      },
      {
        label: "英文標題",
        type: "text",
        name: "titleEn",
        required: true,
        rules: []
      },
      {
        label: "中文摘要",
        type: "textarea",
        name: "abstractCn",
        required: false,
        rules: []
      },
      {
        label: "英文摘要",
        type: "textarea",
        name: "abstractEn",
        required: true,
        rules: []
      },
      {
        label: "中文關鍵字",
        type: "text-tag",
        name: "keywordsCn",
        required: false,
        rules: []
      },
      {
        label: "英文關鍵字",
        type: "text-tag",
        name: "keywordsEn",
        required: true,
        rules: []
      },
    ]
  },
  generateReviewSubmissionPaperAttribute: function (reviewResultTypes) {
    return [
      {
        label: "分數",
        type: "select",
        name: "score",
        required: true,
        options: [{
          label: "未審",
          value: null
        }, ...[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(val => {
          return {
            label: val,
            value: val
          }
        })],
        rules: []
      },
      {
        label: "建議審查結果",
        type: "select",
        name: "result",
        required: true,
        options: [{
          label: "未審",
          value: null
        }, ...reviewResultTypes.map(paperType => {
          return {
            label: paperType.name,
            value: paperType.id
          }
        }), {
          label: "不接受(Reject)",
          value: -1
        }],
        rules: []
      },
      {
        label: "審查意見",
        type: "textarea",
        name: "comment",
        required: true,
        rules: []
      },
      {
        label: "",
        type: "hidden",
        name: "id",
        rules: []
      },
    ]
  }
}