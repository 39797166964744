import React, { useState, useEffect } from 'react';
import efn from './LinkUpsertPageFns.js'
import { toolUtils } from '../../../../utils/toolUtils.js'
import { Spin, Form, Progress, Upload, DatePicker, Select, Input, Button, Row, Col } from 'antd';
import {
  UploadOutlined
} from '@ant-design/icons';

const { TextArea } = Input;
const { Option } = Select;

const LinkUpsertPage = (props) => {
  const [form] = Form.useForm();
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [isGetLink, setIsGetLink] = useState(false)
  const [fileList, setFileList] = useState([])
  const setFuncMap = {
    setIsModalLoading,
    setFileList,
    setFieldsValue: form.setFieldsValue,
    setIsGetLink,
    ...props.setFuncMap
  }
  const onFinish = values => {
    console.log('Form values:', values);
    if (props.id) {
      efn.updateLink(props.conferenceUid, values, setFuncMap)
    }
    else {
      efn.createLink(props.conferenceUid, values, setFuncMap)
    }
  };
  useEffect(() => {
    if (props.id) {
      setIsModalLoading(true)
      efn.getLinkById(props.conferenceUid, props.id, setFuncMap)
      setIsModalLoading(false)
    }
  }, [props.id])
  return (
    <Spin spinning={isModalLoading}>
      <Form
        form={form}
        layout="horizontal"
        onFinish={onFinish}
      >
        {((props && props.id == null) || isGetLink == true) && <Form.Item
          name="upload"
          valuePropName="fileList"
          getValueFromEvent={e => Array.isArray(e) ? e : e && e.fileList}
          rules={[{ required: !isGetLink, message: 'Please upload a file!' }]}
        >
          <Upload {...efn.generateUploadProps(form, fileList, setFuncMap)}>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>}
        <Form.Item
          label="網址"
          name="url"
          rules={[
            {
              required: true,
              message: "This field is required."
            },
            {
              type: "url",
              message: "This field must be a valid url."
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="備註"
          name="remark"
          rules={[{ message: '請輸入備註' }]}
        >
          <Input />
        </Form.Item>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="語系"
              name="lang"
              rules={[{ required: true, message: '請選擇語系' }]}
            >
              <Select placeholder="請選擇語系">
                <Option value="EN">EN</Option>
                <Option value="CN">CN</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify={"center"} gutter={16}>
          <Col>
            <Form.Item>
              <Button type="default" onClick={() => {
                setFuncMap.setIsModalOpen(false)
              }}>
                取消
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              {props.id ?
                <Button type="primary" htmlType="submit">
                  更新
                </Button> :
                <Button type="primary" htmlType="submit">
                  建立
                </Button>}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>{props.id && <Form.Item
            name="id"
            hidden
          >
            <Input type="hidden" />
          </Form.Item>}
          </Col>
          <Col>
            <Form.Item
              name="coverImage"
              hidden
            >
              <Input type="hidden" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default LinkUpsertPage;