export const localStorageUtils = {
    get: function (key) {
        return JSON.parse(localStorage.getItem(key))
    },
    save: function (key, value) {
        localStorage.setItem(key, JSON.stringify(value))
    },
    remove: function (key) {
        localStorage.removeItem(key)
    },
}