import axios from 'axios';
import { toolUtils } from '../../../../utils/toolUtils.js'
import { Tag, Typography, Popconfirm, message, Spin, Modal, Button, Row, Col, Image } from 'antd';
import BankUpsertPage from './BankUpsertPage.js'
import moment from 'moment';
import {
    CloseOutlined,
    CheckCircleOutlined,
    DragOutlined
} from '@ant-design/icons';

export default {
    generateBankMoveMenu: function (props, setFuncMap) {
        let menu = this.generateBankMenu(props, setFuncMap)
        return [
            {
                title: ' ',
                dataIndex: 'sort',
                key: 'sortIcon',
                width: 60,
                render: (lang) => {
                    return <Tag color="#d8780a" key={lang}>
                        <DragOutlined />
                    </Tag>
                }
            }, ...menu]
    },
    generateBankMenu: function (props, setFuncMap) {
        const { Link } = Typography;
        return [
            {
                title: ' ',
                dataIndex: 'sequence',
                key: 'sequence',
                width: 20,
            },
            {
                title: '銀行',
                dataIndex: 'name',
                key: 'name',
                width: 400,
            },
            {
                title: "",
                dataIndex: 'id',
                key: 'id',
                width: 120,
                render: (_, render) => {
                    return <><Button size="small" style={{ marginRight: "5px" }}
                        onClick={() => { this.openBankUpdatePage(props.conferenceUid, render.id, "更新銀行", setFuncMap) }}>編輯</Button>
                        <Popconfirm
                            placement="left"
                            title={`確認要刪除 ${render.name}?`}
                            onConfirm={() => { this.deleteBank(render.id, props, setFuncMap) }}
                            okText="Yes"
                            cancelText="No"
                        ><Button size="small" type="primary" danger><CloseOutlined /></Button>
                        </Popconfirm>
                    </>
                }
            }]
    },
    getBanks: async function (props, setFuncMap) {
        try {
            setFuncMap.setIsTableLoading(true)
            const res = await this.getBanksAction(props.conferenceUid)
            let banks = res.data
            banks = this.getSortedBanks(banks)
            banks = banks.map((bank, i) => {
                return { sequence: (i + 1), ...bank }
            })
            setFuncMap.setBanks(banks)
            setFuncMap.setIsTableLoading(false)
            setFuncMap.setIsPageLoading(false)
        } catch (error) {
            setFuncMap.setIsTableLoading(false)
            // message.error(error.response.data)
        }
    }, getBanksAction: (conferenceUid) => {
        return axios({
            method: 'GET',
            url: `/conference/${conferenceUid}/banks`,
            headers: {
                "role": "topUser"
            }
        })
    },
    getConference: async function (props, setFuncMap) {
        try {
            const res = await this.getConferenceByUidAction(props.conferenceUid)
            props.setContentHeader(res.data.name)
            setFuncMap.setIsPageLoading(false)
        } catch (error) {
        }
    },
    getConferenceByUidAction: (conferenceUid) => {
        return axios({
            method: 'GET',
            url: `/conference/${conferenceUid}`,
            headers: {
                "role": "topUser"
            }
        })
    },
    openBankCreatePage: (conferenceUid, modalName, setFuncMap) => {
        setFuncMap.setIsModalOpen(true)
        setFuncMap.setModalTitle(modalName)
        setFuncMap.setModalContent(<BankUpsertPage conferenceUid={conferenceUid} bankData={null} setFuncMap={setFuncMap} />)
    },
    openBankUpdatePage: (conferenceUid, id, modalName, setFuncMap) => {
        setFuncMap.setIsModalOpen(true)
        setFuncMap.setModalTitle(modalName)
        setFuncMap.setModalContent(<BankUpsertPage conferenceUid={conferenceUid} id={id} banks={null} setFuncMap={setFuncMap} />)
    },
    getSortedBanks: function (banks) {
        return banks.sort((a, b) => a.sort - b.sort)
    },
    updateBanksSort: async function (payload, props, setFuncMap) {
        try {
            setFuncMap.setIsButtonSortLoading(true)
            setFuncMap.setIsTableLoading(true)
            const res = await this.updateBanksSortAction(payload, props.conferenceUid)
            await this.getBanks(props, setFuncMap)
            setFuncMap.setBanksSortChange([])
            setFuncMap.setIsButtonSortLoading(false)
            setFuncMap.setIsTableLoading(false)
        } catch (error) {
        }
    },
    updateBanksSortAction: (payload, conferenceUid) => {
        return axios({
            method: 'POST',
            url: `/conference/${conferenceUid}/banks/sort`,
            headers: {
                "role": "topUser"
            },
            data: payload
        })
    },
    deleteBank: async function (id, props, setFuncMap) {
        try {
            setFuncMap.setIsTableLoading(true)
            const res = await this.deleteBankAction(id, props.conferenceUid)
            await this.getBanks(props, setFuncMap)
            setFuncMap.setIsTableLoading(false)
        } catch (error) {
        }
    },
    deleteBankAction: (id, conferenceUid) => {
        return axios({
            method: 'DELETE',
            url: `/conference/${conferenceUid}/bank/${id}`,
            headers: {
                "role": "topUser"
            }
        })
    }

};
