import axios from 'axios';
import { toolUtils } from '../../../../utils/toolUtils.js'
import pfn from './CallForPapersFns.js'
import { message, Upload, Tag, Badge, } from 'antd';
import {
  PlusOutlined,
  DeleteOutlined
} from '@ant-design/icons';
export default {
  // createCallForPapersGroup: async function (conferenceUid, values, setFuncMap) {
  //   let payload = {
  //     "name": values.name,
  //   }
  //   setFuncMap.setIsModalLoading(true)
  //   const res = await this.createCallForPapersGroupAction(conferenceUid, payload)
  //   pfn.getCallForPapersGroupsComplete({ conferenceUid }, setFuncMap)
  //   setFuncMap.setIsModalLoading(false)
  //   setFuncMap.setIsModalOpen(false)
  //   setFuncMap.message.open({
  //     key: "create",
  //     type: 'success',
  //     content: 'Create success',
  //     duration: 2,
  //   });
  // },
  // createCallForPapersGroupAction: (conferenceUid, payload) => {
  //   return axios.post(`/conference/${conferenceUid}/call-for-papers/group`, payload, {
  //     headers: {
  //       "role": "topUser"
  //     }
  //   })
  // },
  getCallForPapersGroupCompleteById: async function (conferenceUid, id, setFuncMap) {
    try {
      const res = await this.getCallForPapersGroupCompleteByIdAction(conferenceUid, id);
      let data = res.data
      data.group.types = data.group.types.sort((a, b) => a.sort - b.sort)
      console.log({data})
      if (data) {
        setFuncMap.setGroupCompleteData(data)
        setFuncMap.setFieldsValue(data.group)
      }
    }
    catch (e) {

    }

  },
  getCallForPapersGroupCompleteByIdAction: (conferenceUid, id) => {
    return axios.get(`/conference/${conferenceUid}/call-for-papers/group/${id}/complete`, {
      headers: {
        "role": "topUser"
      }
    })
  },
  updateCallForPapersGroup: async function (conferenceUid, values, groupCompleteData, setFuncMap) {
    let payload = {
      "id": values.id,
      "name": values.name,
      "types": groupCompleteData.group.types
    }
    try{
      setFuncMap.setIsModalLoading(true)
      console.log({ payload })
      const res = await this.updateCallForPapersGroupAction(conferenceUid, payload)
      pfn.getCallForPapersGroupsComplete({ conferenceUid }, setFuncMap)
      setFuncMap.setIsModalLoading(false)
      setFuncMap.setIsModalOpen(false)
      setFuncMap.message.open({
        key: "update",
        type: 'success',
        content: 'Update success',
        duration: 2,
      });
    }
    catch(error){
      setFuncMap.setIsModalLoading(false)
      setFuncMap.message.open({
        type: 'error',
        content: setFuncMap.t(`error.${error.response.status}`, {data: payload.name}),
        duration: 2,
      });
    }
    
  },
  updateCallForPapersGroupAction: (conferenceUid, payload) => {
    return axios.post(`/conference/${conferenceUid}/call-for-papers/group/${payload.id}`, payload, {
      headers: {
        "role": "topUser"
      }
    })
  },
  generateAddTypeToGroupItem: (groupCompleteData, type, setFuncMap) => {
    return <div className="type-item">
      <Tag color="gold">
        <Badge
          className="site-badge-count-109"
          count={<PlusOutlined />}
          onClick={() => {
            setFuncMap.setGroupCompleteData({
              group: {
                types: [...groupCompleteData.group.types, { ...type, sort: 999 }]
              },
              unsetTypes: groupCompleteData.unsetTypes.filter(oriType => { return oriType.id != type.id })
            })
          }}
          style={{
            backgroundColor: '#8d8d8d',
            color: '#ffffff',
            marginRight: "10px"
          }} />
        {type.name}
      </Tag>
    </div>
  },
  generateRemoveTypeFromGroupItem: (groupCompleteData, type, setFuncMap) => {
    return <div className="type-item">
      <Tag color="gold">
        <Badge
          className="site-badge-count-109"
          count={<DeleteOutlined />}
          onClick={() => {
            setFuncMap.setGroupCompleteData({
              group: {
                types: groupCompleteData.group.types.filter(oriType => { return oriType.id != type.id })
              },
              unsetTypes: [...groupCompleteData.unsetTypes, type]
            })
          }}
          style={{
            backgroundColor: "none",
            color: '#ff4d4f',
            marginRight: "10px"
          }} />
        {type.name}
      </Tag>
    </div>
  }
}