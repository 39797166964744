import axios from 'axios';
import { toolUtils } from '../../../../utils/toolUtils.js'
import pfn from './PaymentWayFns.js'
import { message, Upload } from 'antd';
export default {
  createPaymentWay: async function (conferenceUid, values, setFuncMap) {
    let payload = {
      "name": values.name,
    }
    try {
      setFuncMap.setIsModalLoading(true)
      const res = await this.createPaymentWayAction(conferenceUid, payload)
      pfn.getPaymentWays({ conferenceUid }, setFuncMap)
      setFuncMap.setIsModalLoading(false)
      setFuncMap.setIsModalOpen(false)
      setFuncMap.message.open({
        key: "create",
        type: 'success',
        content: 'Create success',
        duration: 2,
      });
    }
    catch (error) {
      setFuncMap.message.open({
        type: 'error',
        content: setFuncMap.t(`error.${error.response.status}`, { data: payload.name }),
        duration: 2,
      });
      setFuncMap.setIsModalLoading(false)
    }

  },
  createPaymentWayAction: (conferenceUid, payload) => {
    return axios.post(`/conference/${conferenceUid}/payment-way`, payload, {
      headers: {
        "role": "topUser"
      }
    })
  },
  getPaymentWayById: async function (conferenceUid, id, setFuncMap) {
    try {
      const res = await this.getPaymentWayByIdAction(conferenceUid, id);
      let data = res.data
      if (data) {
        setFuncMap.setFieldsValue(data)
      }
    }
    catch (e) {

    }

  },
  getPaymentWayByIdAction: (conferenceUid, id) => {
    return axios.get(`/conference/${conferenceUid}/payment-way/${id}`, {
      headers: {
        "role": "topUser"
      }
    })
  },
  updatePaymentWay: async function (conferenceUid, values, setFuncMap) {
    let payload = {
      "id": values.id,
      "name": values.name,
    }
    try {
      setFuncMap.setIsModalLoading(true)
      console.log({ payload })
      const res = await this.updatePaymentWayAction(conferenceUid, payload)
      pfn.getPaymentWays({ conferenceUid }, setFuncMap)
      setFuncMap.setIsModalLoading(false)
      setFuncMap.setIsModalOpen(false)
      setFuncMap.message.open({
        key: "update",
        type: 'success',
        content: 'Update success',
        duration: 2,
      });
    } catch (error) {
      setFuncMap.message.open({
        type: 'error',
        content: setFuncMap.t(`error.${error.response.status}`, { data: payload.name }),
        duration: 2,
      });
      setFuncMap.setIsModalLoading(false)
    }

  },
  updatePaymentWayAction: (conferenceUid, payload) => {
    return axios.post(`/conference/${conferenceUid}/payment-way/${payload.id}`, payload, {
      headers: {
        "role": "topUser"
      }
    })
  }
}