import React, { useEffect, useState } from 'react';
// import 'antd/dist/antd.css';
import { Table, Radio, message, Spin, Modal, Button, Row, Col, Tag, Card, List, Collapse, Badge } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import efn from './CallForPapersFns'
import './CallForPapers.scss';
import DraggableTable from './DraggableTable'

function CallForPapers(props) {
  const navigate = useNavigate()
  const location = useLocation()
  const [callForPapersGroups, setCallForPapersGroups] = useState([])
  const [unsetCallForPapersTypes, setUnsetCallForPapersTypes] = useState([])
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState("")
  const [modalContent, setModalContent] = useState()
  const [callForPapersGroupsSortChange, setCallForPapersGroupsSortChange] = useState([])
  const [isButtonSortLoading, setIsButtonSortLoading] = useState(false)
  const setFuncMap = {
    setCallForPapersGroups, setUnsetCallForPapersTypes, setIsPageLoading, setIsModalOpen,
    setIsTableLoading, setModalTitle, setModalContent,
    setCallForPapersGroupsSortChange,
    message, setIsButtonSortLoading
  }
  useEffect(() => {

    efn.getCallForPapersGroupsComplete(props, setFuncMap)
    efn.getConference(props, setFuncMap)

  }, []);

  return (
    <Spin spinning={isPageLoading}>
      <div className="CallForPapers">
        <Row gutter={16} justify="end" style={{ marginBottom: "15px" }}>
          {callForPapersGroupsSortChange.length > 0 && <Col>
            <Button type="primary" loading={isButtonSortLoading} style={{ background: "#f7b172" }}
              onClick={() => { efn.updateCallForPapersGroupsSort(callForPapersGroupsSortChange, props, setFuncMap) }}>更新排序</Button>
          </Col>}
          <Col>
            <Button type="primary" style={{ background: "#72a1f7", marginRight: "10px" }} onClick={() => { efn.openCallForPapersTypeCreatePage(props.conferenceUid, "建立徵稿類型", setFuncMap) }}>建立徵稿類型</Button>
            <Button type="primary" onClick={() => { efn.openCallForPapersGroupCreatePage(props.conferenceUid, "建立徵稿群組", setFuncMap) }}>建立徵稿群組</Button>
          </Col>
        </Row>
        <Spin spinning={isTableLoading} >
          <Collapse style={{ marginBottom: "8px" }} defaultActiveKey={["unsetGroups"]}
            items={[{
              key: 'unsetGroups',
              label: `未設置徵稿類型 ${unsetCallForPapersTypes.length > 0 ? `[${unsetCallForPapersTypes.length}]` : ""}`,
              children: <div className="types">
                {unsetCallForPapersTypes.map(unsetCallForPapersType => {
                  return efn.generateTypeIcon(props.conferenceUid, unsetCallForPapersType, setFuncMap, efn)
                })}
              </div>,
            }]} />
          <DraggableTable
            initialPanels={callForPapersGroups}
            setFuncMap={setFuncMap}
            conferenceUid={props.conferenceUid} />
        </Spin>
        <Modal wrapClassName="modal-theme" width="80%" title={modalTitle} open={isModalOpen} footer={[]} destroyOnClose
          onCancel={() => { setIsModalOpen(false) }}>
          {modalContent}
        </Modal>
      </div>
    </Spin>
  );
}



export default CallForPapers;
