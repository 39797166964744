import axios from 'axios';
import { toolUtils } from '../../../../utils/toolUtils.js'
import pfn from './UserFns.js'
export default {
  createUser: async function (conferenceUid, values, setFuncMap) {
    try {
      setFuncMap.setIsModalLoading(true)
      console.log({ values })
      const res = await this.createUserAction(conferenceUid, values)
      pfn.getUsers(conferenceUid, setFuncMap)
      setFuncMap.setIsModalLoading(false)
      setFuncMap.message.open({
        key: "create",
        type: 'success',
        content: 'Create success',
        duration: 2,
      });
      setFuncMap.setModalConfig({
        isOpen: false,
      })
    } catch (error) {
      setFuncMap.setIsModalLoading(false)
      console.log(error)
    }
  },
  createUserAction: (conferenceUid, payload) => {
    return axios.post(`/conference/${conferenceUid}/user`, payload, {
      headers: {
        "role": "topUser"
      }
    })
  },
  getUserById: async function (id, conferenceUid, setFuncMap) {
    try {
      setFuncMap.setIsModalLoading(true)
      const res = await this.getUserByIdAction(conferenceUid, id);
      let data = res.data
      setFuncMap.setUseInitValue(data)
      setFuncMap.setIsModalLoading(false)
    }
    catch (e) {
      setFuncMap.setIsModalLoading(false)
      console.log(e)
    }

  },
  getUserByIdAction: (conferenceUid, id) => {
    return axios.get(`/conference/${conferenceUid}/user/${id}`, {
      headers: {
        "role": "topUser"
      }
    })
  },
  updateUser: async function (conferenceUid, payload, setFuncMap) {
    setFuncMap.setIsModalLoading(true)
    console.log({ payload })
    const res = await this.updateUserAction(conferenceUid, payload, payload.id)
    pfn.getUsers(conferenceUid, setFuncMap)
    setFuncMap.setIsModalLoading(false)
    setFuncMap.message.open({
      key: "update",
      type: 'success',
      content: 'Update success',
      duration: 2,
    });
    setFuncMap.setModalConfig({
      isOpen: false,
    })
  },
  updateUserAction: (conferenceUid, payload, id) => {
    return axios.post(`/conference/${conferenceUid}/user/${id}`, payload, {
      headers: {
        "role": "topUser"
      }
    })
  }
}