import axios from 'axios';
import { toolUtils } from '../../../../utils/toolUtils'
import pfn from './AnnouncementFns.js'
export default {
  createAnnouncement: async function (conferenceUid, values, setFuncMap) {
    let payload = {
      "is_top": toolUtils.convertFormToBool(values.isTop),
      "is_show": toolUtils.convertFormToBool(values.isShow),
      "lang": values.lang,
      "title": values.title,
      "content": values.content,
      "start_at": toolUtils.convertFormToDatetime(values.startAt),
      "end_at": toolUtils.convertFormToDatetime(values.endAt)
    }
    setFuncMap.setIsModalLoading(true)
    console.log({ payload })
    const res = await this.createAnnouncementAction(conferenceUid, payload)
    pfn.getAnnouncements({ conferenceUid }, setFuncMap)
    setFuncMap.setIsModalLoading(false)
    setFuncMap.setIsModalOpen(false)
    setFuncMap.message.open({
      key: "create",
      type: 'success',
      content: setFuncMap.t(`system.updateSuccess`),
      duration: 2,
    });
  },
  createAnnouncementAction: (conferenceUid, payload) => {
    return axios.post(`/conference/${conferenceUid}/announcement`, payload, {
      headers: {
        "role": "topUser"
      }
    })
  },
  getAnnouncementById: async function (conferenceUid, id, setFuncMap) {
    try {
      const res = await this.getAnnouncementByIdAction(conferenceUid, id);
      let data = res.data
      if (data) {
        data.startAt = toolUtils.convertStringToDatetime(data.startAt)
        data.endAt = toolUtils.convertStringToDatetime(data.endAt)
        setFuncMap.setFieldsValue(data)
        if (data.content) {
          setFuncMap.setEditorData(data.content)
        }
      }
    }
    catch (e) {

    }

  },
  getAnnouncementByIdAction: (conferenceUid, id) => {
    return axios.get(`/conference/${conferenceUid}/announcement/${id}`, {
      headers: {
        "role": "topUser"
      }
    })
  },
  updateAnnouncement: async function (conferenceUid, values, setFuncMap) {
    let payload = {
      "id": values.id,
      "is_top": toolUtils.convertFormToBool(values.isTop),
      "is_show": toolUtils.convertFormToBool(values.isShow),
      "lang": values.lang,
      "title": values.title,
      "content": values.content,
      "start_at": toolUtils.convertFormToDatetime(values.startAt),
      "end_at": toolUtils.convertFormToDatetime(values.endAt)
    }
    setFuncMap.setIsModalLoading(true)
    console.log({ payload })
    const res = await this.updateAnnouncementAction(conferenceUid, payload)
    pfn.getAnnouncements({ conferenceUid }, setFuncMap)
    setFuncMap.setIsModalLoading(false)
    setFuncMap.setIsModalOpen(false)
    setFuncMap.message.open({
      key: "update",
      type: 'success',
      content: setFuncMap.t(`system.updateSuccess`),
      duration: 2,
    });
  },
  updateAnnouncementAction: (conferenceUid, payload) => {
    return axios.post(`/conference/${conferenceUid}/announcement/${payload.id}`, payload, {
      headers: {
        "role": "topUser"
      }
    })
  }
}