import React, { useEffect, useState } from 'react';
import { DataContext } from '../../../Context/DataContext';
// import 'antd/dist/antd.css';
import { Input, Radio, message, Spin, Modal, Button, Row, Col, Form } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import efn from './RegistrationFeeFns'
import moment from 'moment'
import './RegistrationFee.scss';


function RegistrationFee(props) {
    const { globalData } = React.useContext(DataContext)
    const navigate = useNavigate()
    const location = useLocation()
    const { TextArea } = Input
    const [registrationFee, setRegistrationFee] = useState({})
    const [isPageLoading, setIsPageLoading] = useState(true)
    const setFuncMap = {
        message, setIsPageLoading, setRegistrationFee
    }
    useEffect(() => {
        efn.getRegistrationFee(setFuncMap)
    }, [globalData.langChangeTime]);
    return (
        <Spin spinning={isPageLoading}>
            <div className="UserRegistrationFee">
                {registrationFee.lastUpdateAt && <div className="last-update-at">{`最後更新時間 ${moment(registrationFee.lastUpdateAt).format("yyyy-MM-DD HH:mm:ss")}`}</div>}
                <div className="main" dangerouslySetInnerHTML={{ __html: registrationFee.content }}></div>
            </div>
        </Spin>
    );
}



export default RegistrationFee;
