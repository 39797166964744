import axios from 'axios';
import { toolUtils } from '../../../../utils/toolUtils.js'
import pfn from './SubmittedPaperTypeFns.js'
import { message, Upload } from 'antd';
export default {
  createSubmittedPaperType: async function (conferenceUid, values, setFuncMap) {
    let payload = {
      "name": values.name,
    }
    try {
      setFuncMap.setIsModalLoading(true)
      const res = await this.createSubmittedPaperTypeAction(conferenceUid, payload)
      pfn.getSubmittedPaperTypes({ conferenceUid }, setFuncMap)
      setFuncMap.setIsModalLoading(false)
      setFuncMap.setIsModalOpen(false)
      setFuncMap.message.open({
        key: "create",
        type: 'success',
        content: 'Create success',
        duration: 2,
      });
    }
    catch (error) {
      setFuncMap.message.open({
        type: 'error',
        content: setFuncMap.t(`error.${error.response.status}`, { data: payload.name }),
        duration: 2,
      });
      setFuncMap.setIsModalLoading(false)
    }

  },
  createSubmittedPaperTypeAction: (conferenceUid, payload) => {
    return axios.post(`/conference/${conferenceUid}/submitted-paper-type`, payload, {
      headers: {
        "role": "topUser"
      }
    })
  },
  getSubmittedPaperTypeById: async function (conferenceUid, id, setFuncMap) {
    try {
      const res = await this.getSubmittedPaperTypeByIdAction(conferenceUid, id);
      let data = res.data
      if (data) {
        setFuncMap.setFieldsValue(data)
      }
    }
    catch (e) {

    }

  },
  getSubmittedPaperTypeByIdAction: (conferenceUid, id) => {
    return axios.get(`/conference/${conferenceUid}/submitted-paper-type/${id}`, {
      headers: {
        "role": "topUser"
      }
    })
  },
  updateSubmittedPaperType: async function (conferenceUid, values, setFuncMap) {
    let payload = {
      "id": values.id,
      "name": values.name,
    }
    try {
      setFuncMap.setIsModalLoading(true)
      console.log({ payload })
      const res = await this.updateSubmittedPaperTypeAction(conferenceUid, payload)
      pfn.getSubmittedPaperTypes({ conferenceUid }, setFuncMap)
      setFuncMap.setIsModalLoading(false)
      setFuncMap.setIsModalOpen(false)
      setFuncMap.message.open({
        key: "update",
        type: 'success',
        content: 'Update success',
        duration: 2,
      });
    }
    catch (error) {
      setFuncMap.message.open({
        type: 'error',
        content: setFuncMap.t(`error.${error.response.status}`, { data: payload.name }),
        duration: 2,
      });
      setFuncMap.setIsModalLoading(false)
    }

  },
  updateSubmittedPaperTypeAction: (conferenceUid, payload) => {
    return axios.post(`/conference/${conferenceUid}/submitted-paper-type/${payload.id}`, payload, {
      headers: {
        "role": "topUser"
      }
    })
  }
}