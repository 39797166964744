import React, { useEffect, useState } from 'react';
// import 'antd/dist/antd.css';
import { Table, Radio, message, Spin, Modal, Button, Row, Col, Card } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import efn from './ReviewResultTypeFns'
import langObj from '../TopUserLang'
import './ReviewResultType.scss';
import DraggableTable from './DraggableTable'

function ReviewResultType(props) {
  const navigate = useNavigate()
  const location = useLocation()
  const [reviewResultTypes, setReviewResultTypes] = useState([])
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState("")
  const [modalContent, setModalContent] = useState()
  const [reviewResultTypesSortChange, setReviewResultTypesSortChange] = useState([])
  const [isButtonSortLoading, setIsButtonSortLoading] = useState(false)
  const setFuncMap = {
    setReviewResultTypes, setIsPageLoading, setIsModalOpen,
    setIsTableLoading, setModalTitle, setModalContent,
    setReviewResultTypesSortChange,
    message, setIsButtonSortLoading
  }
  const moveColumns = efn.generateReviewResultTypeMoveMenu(props, setFuncMap)
  useEffect(() => {

    efn.getReviewResultTypes(props, setFuncMap)
    efn.getConference(props, setFuncMap)

  }, []);

  return (
    <Spin spinning={isPageLoading}>
      <div className="ReviewResultType">
        <Row gutter={16} justify="end">
          {reviewResultTypesSortChange.length > 0 && <Col>
            <Button type="primary" loading={isButtonSortLoading}
              onClick={() => { efn.updateReviewResultTypesSort(reviewResultTypesSortChange, props, setFuncMap) }}>更新排序</Button>
          </Col>}
          <Col>
            <Button type="primary" onClick={() => { efn.openReviewResultTypeCreatePage(props.conferenceUid, "建立審稿結果類別", setFuncMap) }}>建立審稿結果類別</Button>
          </Col>
        </Row>
        <Spin spinning={isTableLoading}>          
          <DraggableTable dataSource={reviewResultTypes}
            columns={moveColumns}
            setFuncMap={setFuncMap}/>

        </Spin>
        <Modal wrapClassName="modal-theme" width="80%" title={modalTitle} open={isModalOpen} footer={[]} destroyOnClose
          onCancel={() => { setIsModalOpen(false) }}>
          {modalContent}
        </Modal>
      </div>
    </Spin>
  );
}



export default ReviewResultType;
