import axios from 'axios';
import pfn from './AgendaFns.js'
import { toolUtils } from '../../../../utils/toolUtils.js'

export default {
  updateAgenda: async function (payload, props, setFuncMap) {
    try {
      setFuncMap.setIsContentLoading(true)
      if (payload.id) {
        await this.updateAgendaByIdAction(payload, props.conferenceUid)
      }
      else {
        await this.updateAgendaAction(payload, props.conferenceUid)
      }
      let res = await pfn.getAgendasAction(props.conferenceUid)
      setFuncMap.setIsContentLoading(false)
      setFuncMap.setLangAgenda(res.data.find(item => { return item.lang === payload.lang }))
    } catch (error) {
    }
  },
  updateAgendaAction: (payload, conferenceUid) => {
    return axios({
      method: 'POST',
      url: `/conference/${conferenceUid}/agenda`,
      headers: {
        "role": "topUser"
      },
      data: payload
    })
  },
  updateAgendaByIdAction: (payload, conferenceUid) => {
    return axios({
      method: 'POST',
      url: `/conference/${conferenceUid}/agenda/${payload.id}`,
      headers: {
        "role": "topUser"
      },
      data: payload
    })
  },
  getAgenda: async function (props, setFuncMap) {
    try {
      setFuncMap.setIsContentLoading(true)
      const res = await this.getAgendasAction(props.conferenceUid)
      let result = {}
      res.data.forEach(item => {
        result[item.lang] = item
      })
      setFuncMap.setAgendaMap(result)
      setFuncMap.setIsContentLoading(false)
    } catch (error) {
    }
  },
  getAgendaByIdAction: (conferenceUid) => {
    return axios({
      method: 'GET',
      url: `/conference/${conferenceUid}/agenda`,
      headers: {
        "role": "topUser"
      }
    })
  },
};
