import axios from 'axios';
import { toolUtils } from '../../../../utils/toolUtils'
import pfn from './ReviewerFns.js'
export default {
  createReviewer: async function (conferenceUid, values, setFuncMap) {
    try {
      setFuncMap.setIsModalLoading(true)
      console.log({ values })
      const res = await this.createReviewerAction(conferenceUid, values)
      pfn.getReviewers(conferenceUid, setFuncMap)
      setFuncMap.setIsModalLoading(false)
      setFuncMap.message.open({
        key: "create",
        type: 'success',
        content: 'Create success',
        duration: 2,
      });
      setFuncMap.setModalConfig({
        isOpen: false,
      })
    } catch (error) {
      setFuncMap.setIsModalLoading(false)
      console.log(error)
    }
  },
  createReviewerAction: (conferenceUid, payload) => {
    return axios.post(`/conference/${conferenceUid}/reviewer`, payload, {
      headers: {
        "role": "topUser"
      }
    })
  },
  getReviewerById: async function (id, conferenceUid, setFuncMap) {
    try {
      setFuncMap.setIsModalLoading(true)
      const res = await this.getReviewerByIdAction(conferenceUid, id);
      let data = res.data
      setFuncMap.setFieldsValue(data)
      setFuncMap.setIsModalLoading(false)
    }
    catch (e) {
      setFuncMap.setIsModalLoading(false)
      console.log(e)
    }

  },
  getReviewerByIdAction: (conferenceUid, id) => {
    return axios.get(`/conference/${conferenceUid}/reviewer/${id}`, {
      headers: {
        "role": "topUser"
      }
    })
  },
  updateReviewer: async function (conferenceUid, payload, setFuncMap) {
    setFuncMap.setIsModalLoading(true)
    console.log({ payload })
    const res = await this.updateReviewerAction(conferenceUid, payload, payload.id)
    pfn.getReviewers(conferenceUid, setFuncMap)
    setFuncMap.setIsModalLoading(false)
    setFuncMap.message.open({
      key: "update",
      type: 'success',
      content: 'Update success',
      duration: 2,
    });
    setFuncMap.setModalConfig({
      isOpen: false,
    })
  },
  updateReviewerAction: (conferenceUid, payload, id) => {
    return axios.post(`/conference/${conferenceUid}/reviewer/${id}`, payload, {
      headers: {
        "role": "topUser"
      }
    })
  }
}