import React, { useEffect, useState } from 'react';
// import 'antd/dist/antd.css';

import { Input, Radio, message, Spin, Modal, Button, Row, Col, Form } from 'antd';
import CKEditorUtil from '../../../../utils/ckEditorUtil'
import efn from './RegistrationFeeUpsertPageFns'
import moment from 'moment';

function RegistrationFeeUpsertPage(props) {
  const { lang, registrationFeeMap } = props
  const [form] = Form.useForm();
  const { TextArea } = Input
  const [langRegistrationFee, setLangRegistrationFee] = useState()
  const [editorData, setEditorData] = useState();
  let setFuncMap = { setLangRegistrationFee, setEditorData, ...props.setFuncMap }
  const onFinish = values => {
    values["lang"] = lang
    values["content"] = editorData
    efn.updateRegistrationFee(values, props, setFuncMap)
  };

  useEffect(() => {
    setLangRegistrationFee(registrationFeeMap[lang])
    form.setFieldsValue(registrationFeeMap[lang])
  }, [registrationFeeMap])

  useEffect(() => {
    form.setFieldsValue(langRegistrationFee)
  }, [langRegistrationFee])

  return (
    <Form
      form={form}
      layout="horizontal"
      onFinish={onFinish}
    >
      <CKEditorUtil setFuncMap={setFuncMap} defaultValue={langRegistrationFee ? langRegistrationFee.content : null} />
      <Form.Item
        name="id"
        hidden
      >
        <Input type="hidden" />{langRegistrationFee && langRegistrationFee.lastUpdateAt && `最後更新時間: ${moment(langRegistrationFee.lastUpdateAt).format("yyyy-MM-DD HH:mm:ss")}`}
      </Form.Item>
      <Row justify={"center"} gutter={16}>
        <Col>
          <Button type="primary" htmlType="submit">
            更新
          </Button>
        </Col>
      </Row>

    </Form>
  );
}



export default React.memo(RegistrationFeeUpsertPage);
