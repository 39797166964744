export default {
    "home.user.paper.finalVersion.ENFileFinalversion":"English file",
    "home.user.paper.finalVersion.CNFileFinalversion":"Chinese file",
    "home.user.paper.finalVersion.CopyrightConfirmation":"Copyright confirmation",
    "home.user.paper.finalVersion.PrivacyConfirmation":"Privacy confirmation",
    "home.user.paper.button.UploadPaper":"Upload paper",
    "home.user.paper.attr.no":"Paper no",
    "home.user.paper.column.chinese":"Chinese",
    "home.user.paper.column.english":"English",
    "home.user.paper.row.button.view":"View",
    "home.user.paper.row.button.UploadFinalVersionPaper":"Upload final version paper",
    "home.user.paper.row.button.SubmitPaper":"Submit paper",
    "home.user.paper.row.button.Edit":"Edit",
    "home.user.paper.action.check.delete":"Are you sure to delete ",
    "home.user.paper.action.cancel.submit":"Are you sure to get back",
    "home.user.paper.row.button.Back":"Back",
    "home.user.paper.action.check.submit":"Are you sure to submit",
    "home.user.paper.frame.title.new":"New",
    "home.user.paper.frame.title.UploadFinalVersionPaper":"Upload final version paper",
    "home.user.paper.row.button.ImprovementFeedback":"Reviewer Feedback",
    "system.updateSuccess":"Update success",
    "system.createSuccess":"Create success.",
    "system.sendNewPasswordSuccess":"Sent new password to your email!",
    "system.notFoundAnnouncement":"Not found data",
    "system.registrationSuccess":"Registration success",
    "system.signupSuccess":"Registration success, have sent new password to your email!",
    "error.40100":"Please login first.",
    "error.40101":"Your login session expired.",
    "error.50101":"Registration is not open yet.",
    "error.50102":"This account is not registered",
    "error.50103":"Account or Password is wrong!",
    "error.50104":"Old password is wrong",
    "error.50105":"Email can't be empty",
    "error.50106":"The account has been used.",
    "error.50107":"This account didn't set Email, please contact the admin.",
    "error.50108":"Error, the data is exists: {{data}}.",
    "error.50109":"Error, the data is exists.",
    "error.50110":"Error, please refresh the data",
    "error.50111":"Don't have permission.",
    "error.50112":"Account is forbidden. please contact the administrator.",
    "error.50001":"System encountered error, please contact admin.",
    "error.510":"System encountered error, please contact admin.",
    "error.500":"System encountered error, please contact admin.",
    "home.user.login":"Login",
    "home.user.profile":"Profile",
    "home.user.my-papers":"My Manuscript",
    "home.user.logout":"Logout",
    "home.announcements":"News",
    "home.acceptedPapers":"Accepted Paper List",
    "home.conferenceInfos":"Conference Infomation",
    "home.conferenceInfos.introdution":"Background & Purpose",
    "home.conferenceInfos.lecturer":"Lecturer",
    "home.conferenceInfos.agenda":"Program & Venue",
    "home.conferenceInfos.invite-info":"Call for Paper",
    "home.conferenceInfos.registration-fee":"Registration Fee",
    "home.conferenceInfos.files":"Download Conference Files",
    "home.conferenceInfos.activities":"ICEI Activities",
    "home.conferenceInfos.pasticei":"Past Conference",
    "home.SubmissionDeadline":"English Abstract Submission Deadline",
    "home.FinalSubmissionDeadline":"Submission Deadline for Final version of accepted manuscript",
    "home.RegistrationDeadline":"Registration deadline",
    "home.PublishReviewResultDeadline":"Announcement of Accepted Manuscripts",
    "home.ReviewDeadline":"Deadline for Peer Review",
    "home.lang.chinese":"Chinese",
    "home.lang.english":"English",
    "home.user.profile.account":"Account",
    "home.user.profile.name":"Name",
    "home.user.profile.nationality":"Nationality",
    "home.user.profile.department":"Department",
    "home.user.profile.unit":"Unit",
    "home.user.profile.telephoneNumber":"Telephone",
    "home.user.profile.cellphoneNumber":"Cellphone",
    "home.user.profile.zipCode":"ZIP",
    "home.user.profile.address":"Address",
    "home.user.paper.content.attr.cnTitle":"Chinese title",
    "home.user.paper.content.attr.enTitle":"English title",
    "home.user.paper.content.attr.cnAbstract":"Chinese abstract",
    "home.user.paper.content.attr.enAbstract":"English abstract",
    "home.user.paper.content.attr.cnKeywords":"Chinese kewords",
    "home.user.paper.content.attr.cnKeywords.tip":"Please seperate keywords by \";\", maximum: 5",
    "home.user.paper.content.attr.enKeywords":"English kewords",
    "home.user.paper.content.attr.enKeywords.tip":"Please seperate keywords by \";\", maximum: 5",
    "home.user.paper.content.attr.CallForPaperTypeGroup":"Call for paper type group",
    "home.user.paper.content.attr.option.all":"All",
    "home.user.paper.content.attr.CallForPaperType":"Call for paper type",
    "home.user.paper.content.attr.PaperType":"Paper type",
    "home.user.paper.content.attr.Authors":"Authors",
    "home.user.paper.content.attr.Authors.tip":"Please seperate authors by \";\"",
    "home.user.paper.content.button.cancel":"Cancel",
    "home.user.paper.content.button.create":"Create",
    "home.user.paper.content.button.update":"Update",
    "home.user.paper.content.hint.word":"Can't empty of ",
    "home.user.paper.content.hint.overLengthOfWords":"The word count has exceeded ",
    "home.user.paper.content.attr.author1":"1st. author",
    "home.user.paper.content.attr.author1Unit":"1st. author's unit",
    "home.user.paper.content.attr.author1ContactAuthor":"Default contact author",
    "home.user.paper.content.attr.author1ContactWay":"1st. author's email",
    "home.user.paper.content.attr.author2":"2nd. author",
    "home.user.paper.content.attr.author2Unit":"2nd. author's unit",
    "home.user.paper.content.attr.author2ContactAuthor":"Default contact author",
    "home.user.paper.content.attr.author2ContactWay":"2nd. author's email",
    "home.user.paper.content.attr.author3":"3rd. author",
    "home.user.paper.content.attr.author3Unit":"3rd. author's unit",
    "home.user.paper.content.attr.author3ContactAuthor":"Default contact author",
    "home.user.paper.content.attr.author3ContactWay":"3rd. author's email",
    "home.user.paper.content.attr.author4":"4th. author",
    "home.user.paper.content.attr.author4Unit":"4th. author's unit",
    "home.user.paper.content.attr.author4ContactAuthor":"Default contact author",
    "home.user.paper.content.attr.author4ContactWay":"4th. author's email",
    "home.user.paper.content.attr.author5":"5th. author",
    "home.user.paper.content.attr.author5Unit":"5th. author's unit",
    "home.user.paper.content.attr.author5ContactAuthor":"Default contact author",
    "home.user.paper.content.attr.author5ContactWay":"5th. author's email",
    "home.user.paper.content.attr.author6":"6th. author",
    "home.user.paper.content.attr.author6Unit":"6th. author's unit",
    "home.user.paper.content.attr.author6ContactAuthor":"Default contact author",
    "home.user.paper.content.attr.author6ContactWay":"6th. author's email",
    "home.user.paper.content.attr.email.format.error":"It's not email format",
    "home.user.paper.content.attr.cantempty":"can't be empty"
    }