import React, { useEffect, useState } from 'react';
import { DataContext } from '../../../Context/DataContext';
// import 'antd/dist/antd.css';
import { Input, Radio, message, Spin, Modal, Button, Row, Col, Form } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import efn from './LecturerFns'
import './Lecturer.scss';
import moment from 'moment';


function Lecturer(props) {
    const { globalData } = React.useContext(DataContext)
    const navigate = useNavigate()
    const location = useLocation()
    const { TextArea } = Input
    const [lecturer, setLecturer] = useState({})
    const [isPageLoading, setIsPageLoading] = useState(true)
    const setFuncMap = {
        message, setIsPageLoading, setLecturer
    }
    useEffect(() => {
        efn.getLecturer(setFuncMap)
    }, [globalData.langChangeTime]);
    return (
        <Spin spinning={isPageLoading}>
            <div className="UserLecturer">
                {lecturer.lastUpdateAt && <div className="last-update-at">{`最後更新時間 ${moment(lecturer.lastUpdateAt).format("yyyy-MM-DD HH:mm:ss")}`}</div>}
                <div className="main" dangerouslySetInnerHTML={{ __html: lecturer.content }}></div>
            </div>
        </Spin>
    );
}



export default Lecturer;
