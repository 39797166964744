import axios from 'axios';
import { toolUtils } from '../../../../utils/toolUtils.js'
import pfn from './CallForPapersFns.js'
import { message, Upload } from 'antd';
export default {
  createCallForPapersType: async function (conferenceUid, values, setFuncMap) {
    let payload = {
      "name": values.name,
    }
    try {
      setFuncMap.setIsModalLoading(true)
      const res = await this.createCallForPapersTypeAction(conferenceUid, payload)
      pfn.getCallForPapersGroupsComplete({ conferenceUid }, setFuncMap)
      setFuncMap.setIsModalLoading(false)
      setFuncMap.setIsModalOpen(false)
      setFuncMap.message.open({
        key: "create",
        type: 'success',
        content: 'Create success',
        duration: 2,
      });
    }
    catch (error) {
      setFuncMap.message.open({
        type: 'error',
        content: setFuncMap.t(`error.${error.response.status}`, { data: payload.name }),
        duration: 2,
      });
      setFuncMap.setIsModalLoading(false)
    }

  },
  createCallForPapersTypeAction: (conferenceUid, payload) => {
    return axios.post(`/conference/${conferenceUid}/call-for-papers/type`, payload, {
      headers: {
        "role": "topUser"
      }
    })
  },
  getCallForPapersTypeById: async function (conferenceUid, id, setFuncMap) {
    try {
      const res = await this.getCallForPapersTypeByIdAction(conferenceUid, id);
      let data = res.data
      if (data) {
        setFuncMap.setFieldsValue(data)
      }
    }
    catch (e) {

    }

  },
  getCallForPapersTypeByIdAction: (conferenceUid, id) => {
    return axios.get(`/conference/${conferenceUid}/call-for-papers/type/${id}`, {
      headers: {
        "role": "topUser"
      }
    })
  },
  updateCallForPapersType: async function (conferenceUid, values, setFuncMap) {
    let payload = {
      "id": values.id,
      "name": values.name,
    }
    try {
      setFuncMap.setIsModalLoading(true)
      console.log({ payload })
      const res = await this.updateCallForPapersTypeAction(conferenceUid, payload)
      pfn.getCallForPapersGroupsComplete({ conferenceUid }, setFuncMap)
      setFuncMap.setIsModalLoading(false)
      setFuncMap.setIsModalOpen(false)
      setFuncMap.message.open({
        key: "update",
        type: 'success',
        content: 'Update success',
        duration: 2,
      });
    }
    catch (error) {
      setFuncMap.message.open({
        type: 'error',
        content: setFuncMap.t(`error.${error.response.status}`, { data: payload.name }),
        duration: 2,
      });
      setFuncMap.setIsModalLoading(false)
    }
  },
  updateCallForPapersTypeAction: (conferenceUid, payload) => {
    return axios.post(`/conference/${conferenceUid}/call-for-papers/type/${payload.id}`, payload, {
      headers: {
        "role": "topUser"
      }
    })
  }
}