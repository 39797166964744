import axios from 'axios';
import { toolUtils } from '../../../../utils/toolUtils.js'
import { Tag, Typography, Popconfirm, message, Spin, Modal, Button, Row, Col, Image } from 'antd';
import LinkUpsertPage from './LinkUpsertPage.js'
import moment from 'moment';
import {
    CloseOutlined,
    CheckCircleOutlined,
    DragOutlined
} from '@ant-design/icons';

export default {
    generateLinkMoveMenu: function (props, setFuncMap) {
        let menu = this.generateLinkMenu(props, setFuncMap)
        return [
            {
                title: ' ',
                dataIndex: 'sort',
                key: 'sortIcon',
                width: 60,
                render: (lang) => {
                    return <Tag color="#d8780a" key={lang}>
                        <DragOutlined />
                    </Tag>
                }
            }, ...menu]
    },
    generateLinkMenu: function (props, setFuncMap) {
        const { Link } = Typography;
        return [
            {
                title: '語系',
                dataIndex: 'lang',
                key: 'lang',
                width: 60,
                render: (lang) => {
                    return <Tag color={lang === "EN" ? "green" : "purple"} key={lang}>
                        {lang ? lang.toUpperCase() : null}
                    </Tag>
                }
            },
            {
                title: '',
                dataIndex: 'coverImage',
                key: 'coverImage',
                width: 120,
                render: (_, render) => {
                    return <Image
                        style={{backgroundPosition:"center"}}
                        width={100}
                        height={40}
                        src={`${window.CONFS.cloudDomain}/${render.coverImage}`}
                    />
                }
            },
            {
                title: '網址',
                dataIndex: 'url',
                key: 'url',
                width: 400,
                render: (url) => {
                    return <Link href={url}
                        target='_blank'>{url}</Link>
                }
            },
            {
                title: '備註',
                dataIndex: 'remark',
                key: 'remark',
            }, {
                title: "",
                dataIndex: 'id',
                key: 'id',
                width: 120,
                render: (_, render) => {
                    return <><Button size="small" style={{ marginRight: "5px" }}
                        onClick={() => { this.openLinkUpdatePage(props.conferenceUid, render.id, "更新連結", setFuncMap) }}>編輯</Button>
                        <Popconfirm
                            placement="left"
                            title={`確認要刪除 ${render.url}?`}
                            onConfirm={() => { this.deleteLink(render.id, props, setFuncMap) }}
                            okText="Yes"
                            cancelText="No"
                        ><Button size="small" type="primary" danger><CloseOutlined /></Button>
                        </Popconfirm>
                    </>
                }
            }]
    },
    getLinks: async function (props, setFuncMap) {
        try {
            setFuncMap.setIsTableLoading(true)
            const res = await this.getLinksAction(props.conferenceUid)
            let links = res.data
            setFuncMap.setLinks(links)
            setFuncMap.setIsTableLoading(false)
            setFuncMap.setIsPageLoading(false)
        } catch (error) {
            setFuncMap.setIsTableLoading(false)
            // message.error(error.response.data)
        }
    }, getLinksAction: (conferenceUid) => {
        return axios({
            method: 'GET',
            url: `/conference/${conferenceUid}/links`,
            headers: {
                "role": "topUser"
            }
        })
    },
    getConference: async function (props, setFuncMap) {
        try {
            const res = await this.getConferenceByUidAction(props.conferenceUid)
            props.setContentHeader(res.data.name)
            setFuncMap.setIsPageLoading(false)
        } catch (error) {
        }
    },
    getConferenceByUidAction: (conferenceUid) => {
        return axios({
            method: 'GET',
            url: `/conference/${conferenceUid}`,
            headers: {
                "role": "topUser"
            }
        })
    },
    openLinkCreatePage: (conferenceUid, modalName, setFuncMap) => {
        setFuncMap.setIsModalOpen(true)
        setFuncMap.setModalTitle(modalName)
        setFuncMap.setModalContent(<LinkUpsertPage conferenceUid={conferenceUid} linkData={null} setFuncMap={setFuncMap} />)
    },
    openLinkUpdatePage: (conferenceUid, id, modalName, setFuncMap) => {
        setFuncMap.setIsModalOpen(true)
        setFuncMap.setModalTitle(modalName)
        setFuncMap.setModalContent(<LinkUpsertPage conferenceUid={conferenceUid} id={id} linkData={null} setFuncMap={setFuncMap} />)
    },
    getLangSortedLinks: function (links, lang) {
        return links.filter(a => { return a.lang == lang }).sort((a, b) => a.sort - b.sort)
    },
    updateLinksSort: async function (mode, payload, props, setFuncMap) {
        try {
            setFuncMap.setIsButtonSortLoading(true)
            setFuncMap.setIsTableLoading(true)
            const res = await this.updateLinksSortAction(payload, props.conferenceUid)
            await this.getLinks(props, setFuncMap)
            if (mode === "EN") {
                setFuncMap.setEnSortChange([])
            }
            if (mode === "EN") {
                setFuncMap.setCnSortChange([])
            }
            setFuncMap.setIsButtonSortLoading(false)
            setFuncMap.setIsTableLoading(false)
        } catch (error) {
        }
    },
    updateLinksSortAction: (payload, conferenceUid) => {
        return axios({
            method: 'POST',
            url: `/conference/${conferenceUid}/links/sort`,
            headers: {
                "role": "topUser"
            },
            data: payload
        })
    },
    deleteLink: async function (id, props, setFuncMap) {
        try {
            setFuncMap.setIsTableLoading(true)
            const res = await this.deleteLinkAction(id, props.conferenceUid)
            await this.getLinks(props, setFuncMap)
            setFuncMap.setIsTableLoading(false)
        } catch (error) {
        }
    },
    deleteLinkAction: (id, conferenceUid) => {
        return axios({
            method: 'DELETE',
            url: `/conference/${conferenceUid}/link/${id}`,
            headers: {
                "role": "topUser"
            }
        })
    }

};
