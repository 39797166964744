import React, { useState, useEffect } from 'react';
import efn from './UserSubmissionPaperFeedbackFns.js'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Spin, Collapse, Select, Input, Row, Col } from 'antd';
import { toolUtils } from '../../../../../utils/toolUtils.js'
import { DataContext } from '../../../../../Context/DataContext.js';
import {
  UploadOutlined,
} from '@ant-design/icons';
const { TextArea } = Input;
const { Option } = Select;

const UserSubmissionPaperFeedback = (props) => {
  const { t } = React.useContext(DataContext)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const { subAction, id } = useParams();
  const [comments, setComments] = useState([])
  const navigate = useNavigate()
  const location = useLocation()
  const colSpan = 12
  const setFuncMap = {
    setIsModalLoading,
    setComments,
    navigate,
    t,
    ...props.setFuncMap
  }
  useEffect(() => {
    setIsModalLoading(true)
    if (id) {
      efn.getUserSubmissionPaperFeedbackById(id, setFuncMap)
    }
    setIsModalLoading(false)
  }, [id, location.search])

  return (
    <Spin spinning={isModalLoading}>
      <Row gutter={16}>
        <Col span={24}>
          <Collapse items={comments} defaultActiveKey={['1']} />
        </Col>
      </Row>
    </Spin>
  );
};

export default UserSubmissionPaperFeedback;