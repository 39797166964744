import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Typography, Row, Col, Layout, Card, message } from 'antd';
import {
  AppstoreOutlined, UserOutlined
} from '@ant-design/icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import efn from './UserLogicFns'
import './Login.scss';
import queryString from 'query-string';
const { Title, Text } = Typography;
function Login(props) {
  const [isLogining, setIsLogining] = useState(false)
  const navigate = useNavigate()
  const location = useLocation();
  const [form] = Form.useForm();
  let params = queryString.parse(location.search)
  const setFuncMap = {
    setIsLogining, ...props.setFuncMap
  }
  const onFinish = (values) => {
    efn.loginUser(values, setFuncMap, {
      navigate, location
    })
  };
  useEffect(() => {
    if (params.status) {
      message.error(params.status);
    }
    if (params.account) {
      form.setFieldsValue({
        account: params.account
      })
    }
  }, [location.search, location.pathname])
  return (
    <div className="Login">
      <Layout>
        <Row justify="center" align="middle">
          <Col span={24}>
            <Card style={{ borderRadius: '8px' }}>
              <Title level={2} style={{ textAlign: 'center', marginBottom: '24px' }}>ICEI Login in</Title>
              <Form
                name="google_login"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                layout="vertical"
                form={form}
              >
                <Form.Item
                  name="account"
                  rules={[{ required: true, message: 'Please input your account!' }, { type: "email", message: 'It\'s not email format' }]}
                >
                  <Input placeholder="Email" size="large" />
                </Form.Item>
                <Form.Item
                  name="pwd"
                  rules={[{ required: true, message: 'Please input your password!' }]}
                >
                  <Input.Password placeholder="Password" size="large" />
                </Form.Item>
                <Form.Item>
                  <Button loading={isLogining} type="primary" htmlType="submit" style={{ width: '100%', background: "#fea4a4", marginTop: '32px' }} size="large">
                    Log in
                  </Button>
                </Form.Item>
              </Form>
              <div style={{ textAlign: 'center' }}>
                <Text>Forgot your password? <a href="#" onClick={() => {
                  navigate(`${location.pathname}?action=forgot-password`)
                }}>Reset it here</a></Text>
                <br />
                {props.isRegistrationICEIAvailable > 0 && <Text>Don't have an account? <a href="#" onClick={() => {
                  navigate(`${location.pathname}?action=sign-up`)
                }}>Sign up now</a></Text>}
              </div>
            </Card>
          </Col>
        </Row>
      </Layout>
    </div>
  );
}


export default Login;
