import React from 'react';
import { message, Upload, Tag, Badge, } from 'antd';
import {
    PlusOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const ItemType = {
    TAG: 'tag',
};

const DraggableTag = (props) => {
    const { type, index, moveTag, groupCompleteData, setFuncMap } = props
    const [, ref] = useDrag({
        type: ItemType.TAG,
        item: { index },
    });

    const [, drop] = useDrop({
        accept: ItemType.TAG,
        hover: (item) => {
            if (item.index !== index) {
                moveTag(item.index, index);
                item.index = index;
            }
        },
    });

    return (
        <div className="type-item move" ref={(node) => ref(drop(node))} style={{ marginBottom: 8 }}>
            <Tag color="gold">
                <Badge
                    className="site-badge-count-109"
                    count={<DeleteOutlined />}
                    onClick={() => {
                        setFuncMap.setGroupCompleteData({
                            group: {
                                types: groupCompleteData.group.types.filter(oriType => { return oriType.id != type.id })
                            },
                            unsetTypes: [...groupCompleteData.unsetTypes, type]
                        })
                    }}
                    style={{
                        backgroundColor: "none",
                        color: '#ff4d4f',
                        marginRight: "10px"
                    }} />
                {type.name}
            </Tag>
        </div>
    );
};

const DraggableTagList = (props) => {
    const { groupCompleteData, setFuncMap } = props

    const moveTag = (fromIndex, toIndex) => {
        let updatedTypes = [...groupCompleteData.group.types];
        const [movedTag] = updatedTypes.splice(fromIndex, 1);
        updatedTypes.splice(toIndex, 0, movedTag);
        updatedTypes = updatedTypes.map((updatedType, index) => {
            return { ...updatedType, sort: index + 1 }
        })
        let groupCompleteDataInstance = JSON.parse(JSON.stringify(groupCompleteData))
        groupCompleteDataInstance.group.types = updatedTypes
        setFuncMap.setGroupCompleteData(groupCompleteDataInstance);
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="types">
                {groupCompleteData.group.types.map((type, index) => {
                    return <DraggableTag
                        key={index}
                        index={index}
                        type={type}
                        moveTag={moveTag}
                        groupCompleteData={groupCompleteData}
                        setFuncMap={setFuncMap} />
                })}
            </div>
        </DndProvider>
    );
};

export default DraggableTagList;