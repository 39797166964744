import axios from 'axios';
import { toolUtils } from '../../../../../utils/toolUtils.js';

export default {
  getUserSubmissionPaperFeedbackById: async function (id, setFuncMap) {
    try {
      setFuncMap.setIsModalLoading(true)
      const res = await this.getUserSubmissionPaperFeedbackAction(id);
      let rows = res.data

      if (rows.length > 0) {
        setFuncMap.setComments(rows.map((row, index) => {
          return {
            key: `${index + 1}`,
            label: `Feedback ${index + 1}`,
            children: <p>{row.comment}</p>,
          }
        }))
      }
      else {
        setFuncMap.message.open({
          key: "update",
          type: 'error',
          content: setFuncMap.t("system.notFoundAnnouncement"),
          duration: 2,
        });
        setFuncMap.navigate("/submission-paper")
      }
      setFuncMap.setIsModalLoading(false)
    }
    catch (e) {
      setFuncMap.setIsModalLoading(false)
      console.log(e)
    }

  },
  getUserSubmissionPaperFeedbackAction: (id) => {

    return axios.get(`/submission-paper/${id}/feedback`, {
      headers: {
        "role": "user"
      }
    })
  },
}