import axios from 'axios';
import { Tag, Typography, Popconfirm, message, Spin, Modal, Button, Row, Col, Card } from 'antd';
import { localStorageUtils } from '../../../utils/localStorageUtils'
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  AppstoreOutlined, UserOutlined
} from '@ant-design/icons';

export default {
  getHomepage: async function (setFuncMap) {
    try {
      const res = await this.getHomepageAction()
      setFuncMap.setConfig({ links: res.data.links, ...res.data.config })
      setFuncMap.setIsPageLoading(false)
    } catch (error) {
    }
  },
  getHomepageAction: () => {
    return axios({
      method: 'GET',
      url: `/site`
    })
  },
  setFeatureTitle: (location, setFuncMap) => {
    // let menus = this.generateMenus(location)
    // setFuncMap.setTitle("aa");
  },
  generateMenus: function (location, isPublishReviewResult, userData, t, mode) {
    let userMenu =
    {
      key: 'login',
      label: <Link to={`${location.pathname}?action=login`} >{t('home.user.login')}</Link >,
      icon: <UserOutlined />
    }

    if (userData) {
      userMenu = {
        key: 'login',
        label: userData.account,
        icon: <UserOutlined />,
        children: [
          {
            label: <Link to="/profile">{t('home.user.profile')}</Link >,
            key: 'profile',
          },
          {
            label: <Link to="/submission-paper">{t('home.user.my-papers')}</Link >,
            key: '/submission-paper',
          },
          {
            label: <Link to="#" onClick={() => {
              localStorageUtils.remove("user")
              window.location.reload()
            }}>{t('home.user.logout')}</Link >,
            key: 'logout',
          }]
      }
    }

    let menus
    if (mode == "web") {
      menus = [userMenu, ...this.generateNonSubMenuPublicMenus(t)]
    }
    else {
      menus = [userMenu, ...this.generatePublicMenus(t)]
    }
    if (isPublishReviewResult != 1) {
      menus = menus.filter(menu => {
        return menu.key != "/accepted-papers"
      })
    }
    return menus
  },
  generatePublicMenus: (t) => {
    const showTitle = true
    return [
      {
        label: <Link to="/announcements">{t('home.announcements')}</Link >,
        key: '/announcements',
      },
      {
        label: <Link to="/accepted-papers">{t('home.acceptedPapers')}</Link >,
        key: '/accepted-papers',
      },
      {
        label: <>{t('home.conferenceInfos')}</>,
        key: 'conference',
        icon: <AppstoreOutlined />,
        children: [
          {
            key: `/introdution`,
            showTitle,
            label: <Link to={`/introdution`}>{t('home.conferenceInfos.introdution')}</Link>,
          }, {
            key: `/lecturer`,
            showTitle,
            label: <Link to={`/lecturer`}>{t('home.conferenceInfos.lecturer')}</Link>,
          }, {
            key: `/agenda`,
            showTitle,
            label: <Link to={`/agenda`}>{t('home.conferenceInfos.agenda')}</Link>,
          }, {
            key: `/invite-info`,
            showTitle,
            label: <Link to={`/invite-info`}>{t('home.conferenceInfos.invite-info')}</Link>,
          }, {
            key: `/registration-fee`,
            showTitle,
            label: <Link to={`/registration-fee`}>{t('home.conferenceInfos.registration-fee')}</Link>,
          }, {
            key: `/files`,
            showTitle,
            label: <Link to={`/files`}>{t('home.conferenceInfos.files')}</Link>,
          }, {
            key: `/activities`,
            showTitle,
            label: <Link to={`/activities`}>{t('home.conferenceInfos.activities')}</Link>,
          }, {
            key: `/pasticei`,
            showTitle,
            label: <Link to={`https://sites.google.com/gapp.nthu.edu.tw/pasticei/`} target="_blank" rel="noopener noreferrer">{t('home.conferenceInfos.pasticei')}</Link>,
          }

        ],
      }
    ]
  },
  generateNonSubMenuPublicMenus: (t) => {
    const showTitle = true
    return [
      {
        label: <Link to="/announcements">{t('home.announcements')}</Link >,
        key: '/announcements',
      },
      {
        label: <Link to="/accepted-papers">{t('home.acceptedPapers')}</Link >,
        key: '/accepted-papers',
      },
      {
        key: `/introdution`,
        label: <Link to={`/introdution`}>{t('home.conferenceInfos.introdution')}</Link>,
      }, {
        key: `/lecturer`,
        label: <Link to={`/lecturer`}>{t('home.conferenceInfos.lecturer')}</Link>,
      }, {
        key: `/agenda`,
        label: <Link to={`/agenda`}>{t('home.conferenceInfos.agenda')}</Link>,
      }, {
        key: `/invite-info`,
        label: <Link to={`/invite-info`}>{t('home.conferenceInfos.invite-info')}</Link>,
      }, {
        key: `/registration-fee`,
        label: <Link to={`/registration-fee`}>{t('home.conferenceInfos.registration-fee')}</Link>,
      }, {
        key: `/files`,
        label: <Link to={`/files`}>{t('home.conferenceInfos.files')}</Link>,
      }, {
        key: `/activities`,
        label: <Link to={`https://forms.gle/H863Cdb43MHzLDuX7`} target="_blank" rel="noopener noreferrer">{t('home.conferenceInfos.activities')}</Link>,
      }, {
        key: `/pasticei`,
        label: <Link to={`https://sites.google.com/gapp.nthu.edu.tw/pasticei/`} target="_blank" rel="noopener noreferrer">{t('home.conferenceInfos.pasticei')}</Link>,
      }
    ]
  },
  generateScheduledDeadlineTime: function (config, t) {
    let keys = ["SubmissionDeadline", "ReviewDeadline", "PublishReviewResultDeadline", "FinalSubmissionDeadline", "RegistrationDeadline"]
    const lanMap = {
      "SubmissionDeadline": t('home.SubmissionDeadline'),
      "FinalSubmissionDeadline": t('home.FinalSubmissionDeadline'),
      "RegistrationDeadline": t('home.RegistrationDeadline'),
      "PublishReviewResultDeadline": t('home.PublishReviewResultDeadline'),
      "ReviewDeadline": t('home.ReviewDeadline')
    }
    let resultRows = []
    for (var key in keys) {
      if (config[keys[key]]) {
        resultRows.push(<Col>{`${lanMap[keys[key]]}: ${moment(config[keys[key]]).format("YYYY-MM-DD")}`}</Col>)
      }
    }
    return resultRows.length > 0 ? <Row className={"schedule-time"} gutter={16}>{resultRows}</Row> : <></>
  }
};
