import React, { useEffect, useState } from 'react';
// import 'antd/dist/antd.css';
import { Input, Button, message, Spin, List, Row, Col, Modal } from 'antd';
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom';
import {
    FileTextOutlined
} from '@ant-design/icons';
import efn from './FileFns'


function FileItem(props) {
    const [isItemLoading, setIsItemLoading] = useState(false)
    return (
        <Row onClick={() => { efn.downloadFile(props.id, setIsItemLoading) }} gutter={24} justify={"start"} className='File-item'>
            <Col span={1}>{isItemLoading ? <Spin></Spin> : <FileTextOutlined />}</Col>
            <Col>{props.name}</Col>
        </Row>
    );
}


export default FileItem;
