import React, { useState, useEffect } from 'react';
import { DataContext } from '../../../../Context/DataContext.js';
import efn from './CallForPapersTypeUpsertPageFns.js'
import { toolUtils } from '../../../../utils/toolUtils.js'
import { Spin, Form, Progress, Upload, DatePicker, Select, Input, Button, Row, Col } from 'antd';
import {
  UploadOutlined
} from '@ant-design/icons';

const { TextArea } = Input;
const { Option } = Select;

const CallForPapersTypeUpsertPage = (props) => {
  const {t} = React.useContext(DataContext)
  const [form] = Form.useForm();
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [fileList, setFileList] = useState([])
  const setFuncMap = {
    setIsModalLoading,
    setFileList,
    setFieldsValue: form.setFieldsValue, t,
    ...props.setFuncMap
  }
  const onFinish = values => {
    console.log('Form values:', values);
    if (props.id) {
      efn.updateCallForPapersType(props.conferenceUid, values, setFuncMap)
    }
    else {
      efn.createCallForPapersType(props.conferenceUid, values, setFuncMap)
    }
  };
  useEffect(() => {
    if (props.id) {
      setIsModalLoading(true)
      efn.getCallForPapersTypeById(props.conferenceUid, props.id, setFuncMap)
      setIsModalLoading(false)
    }
  }, [props.id])
  return (
    <Spin spinning={isModalLoading}>
      <Form
        form={form}
        layout="horizontal"
        onFinish={onFinish}
      >
        <Form.Item
          label="徵稿類型"
          name="name"
          rules={[
            {
              required: true,
              message: "This field is required."
            }
          ]}
        >
          <Input />
        </Form.Item>        
        <Row justify={"center"} gutter={16}>
          <Col>
            <Form.Item>
              <Button type="default" onClick={() => {
                setFuncMap.setIsModalOpen(false)
              }}>
                取消
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              {props.id ?
                <Button type="primary" htmlType="submit">
                  更新
                </Button> :
                <Button type="primary" htmlType="submit">
                  建立
                </Button>}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>{props.id && <Form.Item
            name="id"
            hidden
          >
            <Input type="hidden" />
          </Form.Item>}
          </Col>          
        </Row>
      </Form>
    </Spin>
  );
};

export default CallForPapersTypeUpsertPage;