import axios from 'axios';
import { toolUtils } from '../../../../../utils/toolUtils.js';
import pfn from './UserSubmissionPaperFns.js'
export default {
  createUserSubmissionPaper: async function (values, setFuncMap) {
    try {
      setFuncMap.setIsModalLoading(true)
      this.textjoinValueByTag(values, ["author", "keywordsEn", "keywordsCn"], ";")
      delete values.callForPaperTypeGroup
      console.log({ values })
      const res = await this.createUserSubmissionPaperAction(values)
      pfn.getUserSubmissionPapers(setFuncMap)
      setFuncMap.setIsModalLoading(false)
      setFuncMap.message.open({
        key: "create",
        type: 'success',
        content: setFuncMap.t(`system.updateSuccess`),
        duration: 2,
      });
      setFuncMap.navigate("/submission-paper")
    } catch (error) {
      setFuncMap.message.open({
        type: 'error',
        content: setFuncMap.t(`error.${error.response.status}`),
        duration: 2,
      });
      setFuncMap.setIsModalLoading(false)
      console.log(error)
    }
  },
  textjoinValueByTag: (data, attrs, tag) => {
    attrs.forEach(attr => {
      if (data[attr]) {
        data[attr] = data[attr].join(tag)
      }
    })
  },
  createUserSubmissionPaperAction: (payload) => {
    return axios.post(`/submission-paper`, payload, {
      headers: {
        "role": "user"
      }
    })
  },
  getUserSubmissionPaperById: async function (id, action, setFuncMap) {
    try {
      const res = await this.getUserSubmissionPaperByIdAction(id);
      let data = res.data

      if (data) {
        if ([1].indexOf(data.status) > -1 && action != "view") {
          setFuncMap.navigate(`/submission-paper/view/${id}`)
        }
        data.callForPaperTypeGroup = this.processCallForPaperAndReturnDefaultGroupId(data, setFuncMap)
        this.seperateValueByTag(data, ["author", "keywordsEn", "keywordsCn"], ";")
        this.initUserSubmissionPaper(data, setFuncMap)
        setFuncMap.setFieldsValue(data)
        setFuncMap.setUserSubmissionPaperData(data)
        // if (data.content) {
        //   setFuncMap.setEditorData(data.content)
        // }
      }
    }
    catch (e) {
      console.log(e)
    }

  },
  getUserSubmissionPaperByIdAction: (id) => {
    let url = id ? `/submission-paper/${id}` : `/submission-paper`
    return axios.get(url, {
      headers: {
        "role": "user"
      }
    })
  },
  seperateValueByTag: (data, attrs, tag) => {
    attrs.forEach(attr => {
      if (data[attr]) {
        data[attr] = data[attr].split(tag)
      }
    })
  },
  processCallForPaperAndReturnDefaultGroupId: (data, setFuncMap) => {
    let defaultGroupId = -1
    setFuncMap.setPaperTypeConfig({
      paperTypes: data.paperTypes,
      callForPaperGroups: data.callForPaperTypes,
    })
    let callForPaperTypes = []
    data.callForPaperTypes.forEach(group => {
      group.types.forEach(type => {
        callForPaperTypes.push({
          groupId: group.id, ...type,
        })
      })
    })
    let matchedCallForPaperType = callForPaperTypes.find(callForPaperType => {
      return callForPaperType.id === data.callForPaperType
    })
    if (matchedCallForPaperType) {
      defaultGroupId = matchedCallForPaperType.groupId
    }
    setFuncMap.setCallForPaperTypes(callForPaperTypes)
    return defaultGroupId
  },
  initUserSubmissionPaperFormValidation: function (setFuncMap) {
    setFuncMap.form.validateFields(['authorUnit2', 'authorUnit3', 'authorUnit4', 'authorUnit5', 'authorUnit6'
      , 'author2', 'author3', 'author4', 'author5', 'author6'
      , 'authorContactWay1', 'authorContactWay2', 'authorContactWay3', 'authorContactWay4', 'authorContactWay5', 'authorContactWay6'
    ]);
  },
  initUserSubmissionPaper: function (data, setFuncMap) {
    if (!data["author1"] && data["author"]) {
      data["author1"] = data["author"].toString()
    }
    this.initSelectedContactAuthor(data, setFuncMap)
    this.initAbstractHelpData(data, setFuncMap)
  },
  initSelectedContactAuthor: function (data, setFuncMap) {
    for (var key in data) {
      if (key.indexOf("authorIscontact") > -1 && data[key] > 0) {
        setFuncMap.setSelectedValue(key)
        return
      }
    }
  },
  initAbstractHelpData: (data, setFuncMap) => {
    let cnWordsCount = toolUtils.calculateWords(data.abstractCn)
    let enWordsCount = toolUtils.calculateWords(data.abstractEn)
    if (cnWordsCount) {
      setFuncMap.setCnAbstractHelp({
        status: "success",
        helpWord: `(${cnWordsCount}/${data.CNAbstractCount})`
      })
    }
    if (enWordsCount) {
      setFuncMap.setEnAbstractHelp({
        status: "success",
        helpWord: `(${enWordsCount}/${data.ENAbstractCount})`
      })
    }
  },
  updateUserSubmissionPaper: async function (payload, setFuncMap) {
    setFuncMap.setIsModalLoading(true)
    delete payload.callForPaperTypeGroup
    console.log({ payload })
    this.textjoinValueByTag(payload, ["author", "keywordsEn", "keywordsCn"], ";")
    const res = await this.updateUserSubmissionPaperAction(payload, payload.id)
    pfn.getUserSubmissionPapers(setFuncMap)
    setFuncMap.setIsModalLoading(false)
    setFuncMap.message.open({
      key: "update",
      type: 'success',
      content: setFuncMap.t(`system.updateSuccess`),
      duration: 2,
    });
    setFuncMap.navigate("/submission-paper")
  },
  updateUserSubmissionPaperAction: (payload, id) => {
    return axios.post(`/submission-paper/${id}`, payload, {
      headers: {
        "role": "user"
      }
    })
  },
  generateSubmissionPaperAttribute: function (paperTypeConfig, callForPaperTypes, userSubmissionPaperData, setFuncMap) {
    return [{
      label: setFuncMap.t("home.user.paper.content.attr.cnTitle"),
      type: "text",
      name: "titleCn",
      required: false,
      rules: []
    },
    {
      label: setFuncMap.t("home.user.paper.content.attr.enTitle"),
      type: "text",
      name: "titleEn",
      required: true,
      rules: []
    },
    {
      label: setFuncMap.t("home.user.paper.content.attr.cnAbstract"),
      type: "textarea",
      name: "abstractCn",
      required: false,
      validateStatus: setFuncMap.cnAbstractHelp.status,
      help: <div style={{ display: "block", textAlign: "right" }}>{setFuncMap.cnAbstractHelp.helpWord}</div>,
      rules: [({ getFieldValue }) => ({
        validator(_, value) {
          if (!value) {
            setFuncMap.setCnAbstractHelp({
              status: "success",
              helpWord: null
            })
            return Promise.resolve();
          }
          let removedBlankContent = value.replace(/\s+/g, '')
          if (!removedBlankContent) {
            setFuncMap.setCnAbstractHelp({
              status: "success",
              helpWord: null
            })
            return Promise.resolve();
          }
          let wordsCount = toolUtils.calculateWords(value)
          let maxCount = userSubmissionPaperData.CNAbstractCount || 0
          if (wordsCount > maxCount) {
            setFuncMap.setCnAbstractHelp({
              status: "error",
              helpWord: `${setFuncMap.t("home.user.paper.content.hint.overLengthOfWords")} (${wordsCount}/${maxCount})`
            })
            return Promise.reject(new Error(`${setFuncMap.t("home.user.paper.content.hint.overLengthOfWords")} (${wordsCount}/${maxCount})`));
          }
          setFuncMap.setCnAbstractHelp({
            status: "success",
            helpWord: `(${wordsCount}/${maxCount})`
          })
          return Promise.resolve();
        },
      })]
    },
    {
      label: setFuncMap.t("home.user.paper.content.attr.enAbstract"),
      type: "textarea",
      name: "abstractEn",
      required: true,
      validateStatus: setFuncMap.enAbstractHelp.status,
      help: <div style={{ display: "block", textAlign: "right" }}>{setFuncMap.enAbstractHelp.helpWord}</div>,
      rules: [({ getFieldValue }) => ({
        validator(_, value) {
          if (!value) {
            setFuncMap.setEnAbstractHelp({
              status: "success",
              helpWord: null
            })
            return Promise.resolve();
          }
          let wordsCount = toolUtils.calculateWords(value)
          let maxCount = userSubmissionPaperData.ENAbstractCount || 0
          if (wordsCount > maxCount) {
            setFuncMap.setEnAbstractHelp({
              status: "error",
              helpWord: `${setFuncMap.t("home.user.paper.content.hint.overLengthOfWords")} (${wordsCount}/${maxCount})`
            })
            return Promise.reject(new Error(`${setFuncMap.t("home.user.paper.content.hint.overLengthOfWords")} (${wordsCount}/${maxCount})`));
          }
          setFuncMap.setEnAbstractHelp({
            status: "success",
            helpWord: `(${wordsCount}/${maxCount})`
          })
          return Promise.resolve();
        },
      })]
    },
    {
      label: setFuncMap.t("home.user.paper.content.attr.cnKeywords"),
      type: "text-tag",
      name: "keywordsCn",
      placeHolder: setFuncMap.t("home.user.paper.content.attr.cnKeywords.tip"),
      required: false,
      rules: [({ getFieldValue }) => ({
        validator(_, value) {
          if (value && value.length > 5) {
            return Promise.reject(new Error('Keywords must less than 6'));
          }
          return Promise.resolve();
        },
      }),]
    },
    {
      label: setFuncMap.t("home.user.paper.content.attr.enKeywords"),
      type: "text-tag",
      name: "keywordsEn",
      placeHolder: setFuncMap.t("home.user.paper.content.attr.enKeywords.tip"),
      required: true,
      rules: [({ getFieldValue }) => ({
        validator(_, value) {
          if (value && value.length > 5) {
            return Promise.reject(new Error('Keywords must less than 6'));
          }
          return Promise.resolve();
        },
      }),]
    },
    {
      label: setFuncMap.t("home.user.paper.content.attr.CallForPaperTypeGroup"),
      type: "select",
      name: "callForPaperTypeGroup",
      required: false,
      onChange: (value) => {
        setFuncMap.setSelectedCallForPaperGroupId(value)
        setFuncMap.setFieldsValue({ callForPaperType: null })
      },
      options: [{
        value: -1,
        label: setFuncMap.t("home.user.paper.content.attr.option.all")
      }, ...paperTypeConfig.callForPaperGroups.map(paperType => {
        return {
          value: paperType.id,
          label: paperType.groupName
        }
      })],
      rules: []
    },
    {
      label: setFuncMap.t("home.user.paper.content.attr.CallForPaperType"),
      type: "select",
      name: "callForPaperType",
      required: true,
      options: [{
        value: "",
        label: ""
      }, ...callForPaperTypes.map(paperType => {
        return {
          value: paperType.id,
          label: paperType.name
        }
      })],
      rules: []
    },
    {
      label: setFuncMap.t("home.user.paper.content.attr.PaperType"),
      type: "select",
      name: "paperType",
      required: true,
      options: paperTypeConfig.paperTypes.map(paperType => {
        return {
          value: paperType.id,
          label: paperType.name
        }
      }),
      rules: []
    },
    {
      class: "authors",
      items: [{
        class: "author-card",
        items: [{
          label: setFuncMap.t("home.user.paper.content.attr.author1"),
          type: "text",
          name: "author1",
          required: true,
          rules: []
        },
        {
          label: setFuncMap.t("home.user.paper.content.attr.author1Unit"),
          type: "text",
          name: "authorUnit1",
          required: true,
          rules: []
        },
        {
          label: setFuncMap.t("home.user.paper.content.attr.author1ContactAuthor"),
          type: "checkbox",
          name: "authorIscontact1",
          required: false,
          rules: []
        },
        {
          label: setFuncMap.t("home.user.paper.content.attr.author1ContactWay"),
          type: "text",
          name: "authorContactWay1",
          required: false,
          rules: [({ getFieldValue }) => ({
            required: getFieldValue('defaultContactAuthor') == "authorIscontact1",
            message: `${setFuncMap.t("home.user.paper.content.attr.author1ContactWay")} ${setFuncMap.t("home.user.paper.content.attr.cantempty")}`,
          }), { type: "email", message: setFuncMap.t("home.user.paper.content.attr.email.format.error") }]
        },]
      },
      {
        class: "author-card",
        items: [{
          label: setFuncMap.t("home.user.paper.content.attr.author2"),
          type: "text",
          name: "author2",
          required: false,
          rules: [({ getFieldValue }) => ({
            required: getFieldValue('defaultContactAuthor') == "authorIscontact2",
            message: `${setFuncMap.t("home.user.paper.content.attr.author2")} ${setFuncMap.t("home.user.paper.content.attr.cantempty")}`,
          })]
        },
        {
          label: setFuncMap.t("home.user.paper.content.attr.author2Unit"),
          type: "text",
          name: "authorUnit2",
          required: false,
          rules: [({ getFieldValue }) => ({
            required: !!getFieldValue('author2'),
            message: `${setFuncMap.t("home.user.paper.content.attr.author2Unit")} ${setFuncMap.t("home.user.paper.content.attr.cantempty")}`,
          })]
        },
        {
          label: setFuncMap.t("home.user.paper.content.attr.author2ContactAuthor"),
          type: "checkbox",
          name: "authorIscontact2",
          required: false,
          rules: []
        },
        {
          label: setFuncMap.t("home.user.paper.content.attr.author2ContactWay"),
          type: "text",
          name: "authorContactWay2",
          required: false,
          rules: [({ getFieldValue }) => ({
            required: getFieldValue('defaultContactAuthor') == "authorIscontact2",
            message: `${setFuncMap.t("home.user.paper.content.attr.author2ContactWay")} ${setFuncMap.t("home.user.paper.content.attr.cantempty")}`,
          }), { type: "email", message: setFuncMap.t("home.user.paper.content.attr.email.format.error") }]
        },]
      },
      {
        class: "author-card",
        items: [{
          label: setFuncMap.t("home.user.paper.content.attr.author3"),
          type: "text",
          name: "author3",
          required: false,
          rules: [({ getFieldValue }) => ({
            required: getFieldValue('defaultContactAuthor') == "authorIscontact3",
            message: `${setFuncMap.t("home.user.paper.content.attr.author3")} ${setFuncMap.t("home.user.paper.content.attr.cantempty")}`,
          })]
        },
        {
          label: setFuncMap.t("home.user.paper.content.attr.author3Unit"),
          type: "text",
          name: "authorUnit3",
          required: false,
          rules: [({ getFieldValue }) => ({
            required: !!getFieldValue('author3'),
            message: `${setFuncMap.t("home.user.paper.content.attr.author3Unit")} ${setFuncMap.t("home.user.paper.content.attr.cantempty")}`,
          })]
        },
        {
          label: setFuncMap.t("home.user.paper.content.attr.author3ContactAuthor"),
          type: "checkbox",
          name: "authorIscontact3",
          required: false,
          rules: []
        },
        {
          label: setFuncMap.t("home.user.paper.content.attr.author3ContactWay"),
          type: "text",
          name: "authorContactWay3",
          required: false,
          rules: [({ getFieldValue }) => ({
            required: getFieldValue('defaultContactAuthor') == "authorIscontact3",
            message: `${setFuncMap.t("home.user.paper.content.attr.author3ContactWay")} ${setFuncMap.t("home.user.paper.content.attr.cantempty")}`,
          }), { type: "email", message: setFuncMap.t("home.user.paper.content.attr.email.format.error") }]
        },]
      },
      {
        class: "author-card",
        items: [{
          label: setFuncMap.t("home.user.paper.content.attr.author4"),
          type: "text",
          name: "author4",
          required: false,
          rules: [({ getFieldValue }) => ({
            required: getFieldValue('defaultContactAuthor') == "authorIscontact4",
            message: `${setFuncMap.t("home.user.paper.content.attr.author4")} ${setFuncMap.t("home.user.paper.content.attr.cantempty")}`,
          })]
        },
        {
          label: setFuncMap.t("home.user.paper.content.attr.author4Unit"),
          type: "text",
          name: "authorUnit4",
          required: false,
          rules: [({ getFieldValue }) => ({
            required: !!getFieldValue('author4'),
            message: `${setFuncMap.t("home.user.paper.content.attr.author4Unit")} ${setFuncMap.t("home.user.paper.content.attr.cantempty")}`,
          })]
        },
        {
          label: setFuncMap.t("home.user.paper.content.attr.author4ContactAuthor"),
          type: "checkbox",
          name: "authorIscontact4",
          required: false,
          rules: []
        },
        {
          label: setFuncMap.t("home.user.paper.content.attr.author4ContactWay"),
          type: "text",
          name: "authorContactWay4",
          required: false,
          rules: [({ getFieldValue }) => ({
            required: getFieldValue('defaultContactAuthor') == "authorIscontact4",
            message: `${setFuncMap.t("home.user.paper.content.attr.author4ContactWay")} ${setFuncMap.t("home.user.paper.content.attr.cantempty")}`,
          }), { type: "email", message: setFuncMap.t("home.user.paper.content.attr.email.format.error") }]
        },]
      },
      {
        class: "author-card",
        items: [{
          label: setFuncMap.t("home.user.paper.content.attr.author5"),
          type: "text",
          name: "author5",
          required: false,
          rules: [({ getFieldValue }) => ({
            required: getFieldValue('defaultContactAuthor') == "authorIscontact5",
            message: `${setFuncMap.t("home.user.paper.content.attr.author5")} ${setFuncMap.t("home.user.paper.content.attr.cantempty")}`,
          })]
        },
        {
          label: setFuncMap.t("home.user.paper.content.attr.author5Unit"),
          type: "text",
          name: "authorUnit5",
          required: false,
          rules: [({ getFieldValue }) => ({
            required: !!getFieldValue('author5'),
            message: `${setFuncMap.t("home.user.paper.content.attr.author5Unit")} ${setFuncMap.t("home.user.paper.content.attr.cantempty")}`,
          })]
        },
        {
          label: setFuncMap.t("home.user.paper.content.attr.author5ContactAuthor"),
          type: "checkbox",
          name: "authorIscontact5",
          required: false,
          rules: []
        },
        {
          label: setFuncMap.t("home.user.paper.content.attr.author5ContactWay"),
          type: "text",
          name: "authorContactWay5",
          required: false,
          rules: [({ getFieldValue }) => ({
            required: getFieldValue('defaultContactAuthor') == "authorIscontact5",
            message: `${setFuncMap.t("home.user.paper.content.attr.author5ContactWay")} ${setFuncMap.t("home.user.paper.content.attr.cantempty")}`,
          }), { type: "email", message: setFuncMap.t("home.user.paper.content.attr.email.format.error") }]
        },]
      },
      {
        class: "author-card",
        items: [{
          label: setFuncMap.t("home.user.paper.content.attr.author6"),
          type: "text",
          name: "author6",
          required: false,
          rules: [({ getFieldValue }) => ({
            required: getFieldValue('defaultContactAuthor') == "authorIscontact6",
            message: `${setFuncMap.t("home.user.paper.content.attr.author6")} ${setFuncMap.t("home.user.paper.content.attr.cantempty")}`,
          })]
        },
        {
          label: setFuncMap.t("home.user.paper.content.attr.author6Unit"),
          type: "text",
          name: "authorUnit6",
          required: false,
          rules: [({ getFieldValue }) => ({
            required: getFieldValue('author6'),
            message: `${setFuncMap.t("home.user.paper.content.attr.author6Unit")} ${setFuncMap.t("home.user.paper.content.attr.cantempty")}`,
          })]
        },
        {
          label: setFuncMap.t("home.user.paper.content.attr.author6ContactAuthor"),
          type: "checkbox",
          name: "authorIscontact6",
          required: false,
          rules: []
        },
        {
          label: setFuncMap.t("home.user.paper.content.attr.author6ContactWay"),
          type: "text",
          name: "authorContactWay6",
          required: false,
          rules: [({ getFieldValue }) => ({
            required: getFieldValue('defaultContactAuthor') == "authorIscontact6",
            message: `${setFuncMap.t("home.user.paper.content.attr.author6ContactWay")} ${setFuncMap.t("home.user.paper.content.attr.cantempty")}`,
          }), { type: "email", message: setFuncMap.t("home.user.paper.content.attr.email.format.error") }]
        },]
      },]
    },]
  }
}