import axios from 'axios';
import { toolUtils } from '../../../../utils/toolUtils.js'
import { Tag, Typography, Popconfirm, message, Spin, Badge, Button, Row, Col, Select } from 'antd';
import moment from 'moment';
import statusCode from '../../../../enum/submissionPaperStatusEnum.js'
import statusColor from '../../../../enum/submissionPaperStatusColorEnum.js'
import {
    CloseOutlined,
    CheckCircleOutlined,
    DragOutlined
} from '@ant-design/icons';

export default {
    generateSubmissionPaperMenu: function () {
        const { Text } = Typography;
        return [
            {
                title: '',
                dataIndex: 'status',
                key: 'status',
                width: 50,
                render: (status) => {
                    return <Tag color={statusColor[status]} key={status}>
                        {statusCode[status]}
                    </Tag>
                }
            },
            {
                title: '徵稿類別',
                dataIndex: 'type',
                key: 'type',
                width: 250,
                render: (_, render) => {
                    return <><div>{render.callForPaperType}</div><div><i>{render.paperType}</i></div></>
                }
            },
            {
                title: '標題',
                dataIndex: 'title',
                key: 'title',
                render: (_, render) => {
                    return <>
                        <div>
                            <div>{render.titleCn}</div><div>{render.titleEn}</div>
                        </div>
                        {render.reviewerSettings.map((setting, key) => {
                            return <Row style={{ fontWeight: "bold", borderBottom: "1px solid gray", marginBottom: "4px", paddingBottom: "4px" }}>
                                <Col>{setting.name}</Col><Col><Badge
                                    className="site-badge-count-109"
                                    count={setting.score}
                                    style={{
                                        backgroundColor: setting.score > 0 ? '#faad14' : '#d2d2d2',
                                        margin: "0 5px"
                                    }}
                                    showZero
                                /></Col><Col>{setting.result == -1 ? "不接受(Reject)" : setting.resultName}</Col>
                            </Row>
                        })}
                        <div>作者: {render.author}</div>
                    </>
                }
            },
            {
                title: '審查結果',
                dataIndex: 'result',
                key: 'result',
                width: 200,                
                render: (_, render) => {
                    return render.resultName
                }
            }]
    },
    getApprovedPapers: async function (conferenceUid, setFuncMap) {
        try {
            setFuncMap.setIsTableLoading(true)
            const res = await this.getApprovedPapersAction(conferenceUid)
            console.log({ data: res.data })
            setFuncMap.setSubmissionPapers(res.data)
            setFuncMap.setIsTableLoading(false)

        } catch (error) {
            console.log(error)
            setFuncMap.setIsTableLoading(false)
        }
    },
    getApprovedPapersAction: (conferenceUid) => {
        return axios({
            method: 'GET',
            url: `/conference/${conferenceUid}/submission-papers/approved`,
            headers: {
                "role": "topUser"
            }
        })
    },    
};
