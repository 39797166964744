import axios from 'axios';
export default {
  getAnnouncement: async function (id, setFuncMap) {
    try {
      setFuncMap.setIsPageLoading(true)
      const res = await this.getAnnouncementAction(id)
      let data = res.data
      if (data.id) {
        setFuncMap.setContent(data.content)
        setFuncMap.setModalTitle(data.title)
      }      
      else {
        setFuncMap.message.open({
          key: "announcement",
          type: 'error',
          content: setFuncMap.t(`system.notFoundAnnouncement`),
          duration: 2,
        });
        setFuncMap.navigate("/announcements")
      }
      setFuncMap.setIsPageLoading(false)
    } catch (error) {
      console.log(error)
      setFuncMap.navigate("/announcements")
      setFuncMap.setIsPageLoading(false)
    }
  },
  getAnnouncementAction: (id) => {
    return axios({
      method: 'GET',
      url: `/site/announcement/${id}`
    })
  }
};
