import React, { useCallback, useState, useEffect } from 'react';
// import 'antd/dist/antd.css';
import './Sponser.scss';
function Sponser({ links }) {
  return (
    <div className="Sponser">
      {links.map((link, key) => {
        return <div key={key}
          // style={{ backgroundImage: `url(${window.CONFS.cloudDomain}/${link.coverImage})` }}
          className="link-card"
          title={link.remark}
          onClick={() => {
            window.open(link.url, '_blank')
          }}><img src={`${window.CONFS.cloudDomain}/${link.coverImage}`} /></div>
      })}
    </div>
  );
}

export default Sponser;
