import React, { useState, useEffect } from 'react';
import efn from './UserUpsertPageFns.js'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Spin, Form, Typography, Select, Input, Button, Row, Col } from 'antd';

const { TextArea } = Input;
const { Option } = Select;

const UserUpsertPage = (props) => {
  const [form] = Form.useForm();
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [useInitValue, setUseInitValue] = useState({})
  const navigate = useNavigate()
  const location = useLocation()
  const setFuncMap = {
    setIsModalLoading,
    navigate, setUseInitValue,
    ...props.setFuncMap
  }
  const onFinish = values => {
    console.log({values})
    efn.updateUser(props.conferenceUid, {
      id: values.id,
      email: values.email,
      status: values.status
    }, setFuncMap)
  };
  useEffect(() => {
    if (props.id) {
      setIsModalLoading(true)
      efn.getUserById(props.id, props.conferenceUid, setFuncMap)
      setIsModalLoading(false)
    }
  }, [props.id])

  useEffect(() => {
    form.setFieldsValue(useInitValue)
  }, [useInitValue])

  return (
    <Spin spinning={isModalLoading}>
      <Form
        form={form}
        layout="horizontal"
        onFinish={onFinish}
      >
        <Row gutter={16}>
          <Col span={24}>
            {generateUserSignUpAttribute(useInitValue).map((item, key) => {
              return <Form.Item key={key}
                name={item.name}
                label={item.label}
                hidden={item.type === "hidden"}
                initialValue={item.defaultValue}
                rules={[{ required: item.required, message: `請輸入${item.label}` }, ...item.rules]}
              >
                {item.type === "text" && <Typography.Text size="large" style={{ whiteSpace: 'pre-wrap' }} >{useInitValue[item.name]}</Typography.Text>}
                {item.type === "input" && <Input placeholder={item.label} disabled={item.disabled} size="large" />}
                {item.type === "hidden" && <Input type="hidden" />}
                {item.type === "select" && <Select>
                  <Option value={0}>正常</Option>
                  <Option value={1}>禁用</Option>
                </Select>}
              </Form.Item>
            })}
          </Col>
        </Row>
        <Row justify={"center"} gutter={16}>
          <Col>
            <Form.Item>
              <Button type="default" onClick={() => {
                setFuncMap.setModalConfig({
                  isOpen: false,
                })
              }}>
                取消
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                更新
              </Button>
            </Form.Item>
          </Col>
        </Row>
        {props.id && <Form.Item
          name="id"
          hidden
        >
          <Input type="hidden" />
        </Form.Item>}
      </Form>
    </Spin>
  );
};



const generateUserSignUpAttribute = function (useInitValue) {
  return [{
    label: "帳號",
    type: "text",
    disabled: true,
    name: "account",
    required: false,
    rules: []
  },
  {
    label: "信箱",
    type: "input",
    name: "email",
    required: true,
    rules: [{ type: "email", message: 'It\'s not email format' }]
  },
  {
    label: "姓名",
    type: "text",
    name: "name",
    required: false,
    rules: []
  },
  {
    label: "單位",
    type: "text",
    name: "unit",
    required: false,
    rules: []
  },
  {
    label: "國籍",
    type: "text",
    name: "nationalityName",
    required: false,
    rules: []
  },
  {
    label: "部門",
    type: "text",
    name: "department",
    required: false,
    rules: []
  },
  {
    label: "電話",
    type: "text",
    name: "cellphoneNumber",
    required: false,
    rules: []
  },
  {
    label: "行動電話",
    type: "text",
    name: "telephoneNumber",
    required: false,
    rules: []
  },
  {
    label: "區號",
    type: "text",
    name: "zipCode",
    required: false,
    rules: []
  },
  {
    label: "地址",
    type: "text",
    name: "address",
    required: false,
    rules: []
  },
  {
    label: "狀態",
    type: "select",
    name: "status",
    required: true,
    rules: []
  },
  {
    label: " ",
    type: "hidden",
    name: "id",
    rules: []
  }]
}

export default UserUpsertPage;