import { Link } from 'react-router-dom';
import {
  SlackOutlined,
  SettingOutlined,
  PicRightOutlined,
  FormOutlined,
  MailOutlined,
  ApartmentOutlined
} from '@ant-design/icons';


export default function (conferenceUid, path) {
  if (path === '/mnt/top-user/conferences' || path === '/mnt/top-user/') {
    return [{
      key: '/mnt/top-user/conferences',
      icon: <SlackOutlined />,
      label: <Link to='/mnt/top-user/conferences'>研討會管理</Link>,
    }]
  }
  return [
    {
      key: '/mnt/top-user/conferences',
      icon: <SlackOutlined />,
      label: <Link to='/mnt/top-user/conferences'>研討會管理</Link>,
    },
    {
      key: `/mnt/top-user/conferences/${conferenceUid}/setting`,
      icon: <SettingOutlined />,
      label: '設定',
      children: [{
        key: `/mnt/top-user/conferences/${conferenceUid}/setting/announcements`,
        label: <Link to={`/mnt/top-user/conferences/${conferenceUid}/setting/announcements`}>公告管理</Link>,
      }, {
        key: `/mnt/top-user/conferences/${conferenceUid}/setting/configs`,
        label: <Link to={`/mnt/top-user/conferences/${conferenceUid}/setting/configs`}>組態設定</Link>,
      }, {
        key: `/mnt/top-user/conferences/${conferenceUid}/setting/call-for-papers-types`,
        label: <Link to={`/mnt/top-user/conferences/${conferenceUid}/setting/call-for-papers-types`}>徵稿類別</Link>,
      }, {
        key: `/mnt/top-user/conferences/${conferenceUid}/setting/submitted-paper-types`,
        label: <Link to={`/mnt/top-user/conferences/${conferenceUid}/setting/submitted-paper-types`}>稿件類別</Link>,
      },
      // {
      //   key: `/mnt/top-user/conferences/${conferenceUid}/setting/conference-sections`,
      //   label: <Link to={`/mnt/top-user/conferences/${conferenceUid}/setting/conference-sections`}>研討會場次設定</Link>,
      // }, 
      {
        key: `/mnt/top-user/conferences/${conferenceUid}/setting/nationalities`,
        label: <Link to={`/mnt/top-user/conferences/${conferenceUid}/setting/nationalities`}>國籍</Link>,
      }, {
        key: `/mnt/top-user/conferences/${conferenceUid}/setting/payment-ways`,
        label: <Link to={`/mnt/top-user/conferences/${conferenceUid}/setting/payment-ways`}>繳款方式</Link>,
      }, {
        key: `/mnt/top-user/conferences/${conferenceUid}/setting/banks`,
        label: <Link to={`/mnt/top-user/conferences/${conferenceUid}/setting/banks`}>金融機構</Link>,
      }]
    },
    {
      key: `/mnt/top-user/conferences/${conferenceUid}/website`,
      icon: <PicRightOutlined />,
      label: '網站內容維護',
      children: [{
        key: `/mnt/top-user/conferences/${conferenceUid}/website/introdution`,
        label: <Link to={`/mnt/top-user/conferences/${conferenceUid}/website/introdution`}>簡介</Link>,
      }, {
        key: `/mnt/top-user/conferences/${conferenceUid}/website/lecturer`,
        label: <Link to={`/mnt/top-user/conferences/${conferenceUid}/website/lecturer`}>大會講者</Link>,
      }, {
        key: `/mnt/top-user/conferences/${conferenceUid}/website/agenda`,
        label: <Link to={`/mnt/top-user/conferences/${conferenceUid}/website/agenda`}>大會議程</Link>,
      }, {
        key: `/mnt/top-user/conferences/${conferenceUid}/website/invite-info`,
        label: <Link to={`/mnt/top-user/conferences/${conferenceUid}/website/invite-info`}>邀稿通知</Link>,
      }, {
        key: `/mnt/top-user/conferences/${conferenceUid}/website/registration-fee`,
        label: <Link to={`/mnt/top-user/conferences/${conferenceUid}/website/registration-fee`}>註冊費用</Link>,
      }, {
        key: `/mnt/top-user/conferences/${conferenceUid}/website/files`,
        label: <Link to={`/mnt/top-user/conferences/${conferenceUid}/website/files`}>檔案下載</Link>,
      }, {
        key: `/mnt/top-user/conferences/${conferenceUid}/website/submission-note`,
        label: <Link to={`/mnt/top-user/conferences/${conferenceUid}/website/submission-note`}>投稿注意事項</Link>,
      }, {
        key: `/mnt/top-user/conferences/${conferenceUid}/website/review-note`,
        label: <Link to={`/mnt/top-user/conferences/${conferenceUid}/website/review-note`}>審稿注意事項</Link>,
      }, {
        key: `/mnt/top-user/conferences/${conferenceUid}/website/links`,
        label: <Link to={`/mnt/top-user/conferences/${conferenceUid}/website/links`}>連結區塊維護</Link>,
      }],
    }, {
      key: `/mnt/top-user/conferences/${conferenceUid}/review`,
      icon: <FormOutlined />,
      label: '審稿',
      children: [
        {
          key: `/mnt/top-user/conferences/${conferenceUid}/review/accounts`,
          label: <Link to={`/mnt/top-user/conferences/${conferenceUid}/review/accounts`}>審稿帳號維護</Link>,
        },
        {
          key: `/mnt/top-user/conferences/${conferenceUid}/review/submission-papers`,
          label: <Link to={`/mnt/top-user/conferences/${conferenceUid}/review/submission-papers`}>稿件分配與管理</Link>,
        },
        // {
        //   key: `/mnt/top-user/conferences/${conferenceUid}/review/paper-status`,
        //   label: <Link to={`/mnt/top-user/conferences/${conferenceUid}/review/paper-status`}>定稿上傳情形</Link>,
        // }
      ]
    },
    {
      key: '5',
      icon: <ApartmentOutlined />,
      label: '事務管理',
      children: [{
        key: `/mnt/top-user/conferences/${conferenceUid}/general/accounts`,
        label: <Link to={`/mnt/top-user/conferences/${conferenceUid}/general/accounts`}>一般帳號維護</Link>,
      }]
    }
    // ,{
    //   key: 6,
    //   icon: <MailOutlined />,
    //   label: '郵件'
    // }
  ]
}