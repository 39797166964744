import axios from 'axios';
import { toolUtils } from '../../../../../utils/toolUtils'
import { Tag, Typography, Popconfirm, message, Spin, Modal, Button, Row, Col, Card } from 'antd';
import UserSubmissionPaperUpsertPage from './UserSubmissionPaperUpsertPage.js'
import moment from 'moment';
import UserSubmissionPaperController from './UserSubmissionPaperController'
import statusCode from '../../../../../enum/submissionPaperStatusEnum.js'
import statusColor from '../../../../../enum/submissionPaperStatusColorEnum.js'
import FinalVersionPaper from './FinalVersionPaper'
import {
    CloseOutlined,
    CheckCircleOutlined,
    DragOutlined
} from '@ant-design/icons';

export default {
    generateUserSubmissionPaperMenu: function (props, setFuncMap, fn) {
        const { Text } = Typography;
        return [
            {
                title: '',
                dataIndex: 'status',
                key: 'status',
                width: 60,
                render: (_, render) => {
                    return <><Tag color={statusColor[render.status]} key={render.status}>
                        {statusCode[render.status]}
                    </Tag><div>{render.resultName}</div></>
                }
            },
            {
                title: setFuncMap.t('home.user.paper.attr.no'),
                dataIndex: 'paperKey',
                key: 'paperKey',
                width: 100,
            },
            {
                title: setFuncMap.t('home.user.paper.column.chinese'),
                dataIndex: 'titleCn',
                key: 'titleCn',
                render: (_, render) => {
                    let fileUrl = `${window.CONFS.cloudDomain}/${render.cnFinalVersionFilename}`
                    return <>
                        <div>{render.titleCn}</div>
                        {render.cnFinalVersionFilename && <FinalVersionPaper fileUrl={fileUrl} fileName={render.cnFinalVersionOriginFilename} />}
                    </>
                }
            },
            {
                title: setFuncMap.t('home.user.paper.column.english'),
                dataIndex: 'titleEn',
                key: 'titleEn',
                render: (_, render) => {
                    let fileUrl = `${window.CONFS.cloudDomain}/${render.enFinalVersionFilename}`
                    return <>
                        <div>{render.titleEn}</div>
                        {render.enFinalVersionFilename && <FinalVersionPaper fileUrl={fileUrl} fileName={render.enFinalVersionOriginFilename} />}
                    </>
                }
            },
            {
                title: "",
                dataIndex: 'id',
                key: 'id',
                width: 200,
                render: (_, render) => {
                    return <UserSubmissionPaperController render={render} setFuncMap={setFuncMap} fn={fn} />
                }
            }]
    },
    getUserSubmissionPapers: async function (setFuncMap) {
        try {
            setFuncMap.setIsTableLoading(true)
            const res = await this.getUserSubmissionPapersAction()
            let userSubmissionPapers = res.data
            setFuncMap.setUserSubmissionPapers(userSubmissionPapers)
            setFuncMap.setIsTableLoading(false)
            setFuncMap.setIsPageLoading(false)
        } catch (error) {
            setFuncMap.setIsPageLoading(false)
            setFuncMap.setIsTableLoading(false)
            // message.error(error.response.data)
        }
    }, getUserSubmissionPapersAction: () => {
        return axios({
            method: 'GET',
            url: `/submission-papers`,
            headers: {
                "role": "user"
            }
        })
    },
    getLangSortedUserSubmissionPapers: function (userSubmissionPapers, lang) {
        return userSubmissionPapers.filter(a => { return a.lang == lang }).sort((a, b) => a.sort - b.sort)
    },
    deleteUserSubmissionPaper: async function (id, setFuncMap) {
        try {
            setFuncMap.setIsTableLoading(true)
            const res = await this.deleteUserSubmissionPaperAction(id)
            await this.getUserSubmissionPapers(setFuncMap)
            setFuncMap.setIsTableLoading(false)
        } catch (error) {
            setFuncMap.setIsTableLoading(false)
        }
    },
    deleteUserSubmissionPaperAction: (id) => {
        return axios({
            method: 'DELETE',
            url: `/submission-paper/${id}`,
            headers: {
                "role": "user"
            }
        })
    },
    submitUserSubmissionPaper: async function (id, status, setFuncMap) {
        try {
            setFuncMap.setIsTableLoading(true)
            switch (status) {
                case 0:
                case 2:
                    await this.doUserSubmissionPaperAction(id, "sumbit-verify")
                    break;
                case 1:
                    await this.doUserSubmissionPaperAction(id, "cancel-sumbit-verify")
                    break;
                case 4:
                case 6:
                    await this.doUserSubmissionPaperAction(id, "submit-final-version-verify")
                    break;
            }
            await this.getUserSubmissionPapers(setFuncMap)
            setFuncMap.setIsTableLoading(false)
        } catch (error) {
            setFuncMap.setIsTableLoading(false)
        }
    },
    doUserSubmissionPaperAction: (id, action) => {
        return axios({
            method: 'PUT',
            url: `/submission-paper/${id}/${action}`,
            headers: {
                "role": "user"
            }
        })
    },
    generateStatusWord: function (status) {
        switch (status) {
            case 0:
                return {
                    color: "default",
                    code: "CREATED"
                }
            case 1:
                return {
                    color: "processing",
                    code: "WAIT_VERIFY"
                }
            case 2:
                return {
                    color: "error",
                    code: "VERIFY_REJECT"
                }
            case 3:
                return {
                    color: "processing",
                    code: "WAIT_REVIEW"
                }
            case 4:
                return {
                    color: "error",
                    code: "REVIEW_REJECT"
                }
        }
    },

};
