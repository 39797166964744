import axios from 'axios';
import { toolUtils } from '../../../../utils/toolUtils.js'
import pfn from './SubmissionPaperFns.js'
export default {
  getSubmissionPaperReviewerSettingsById: async function (id, conferenceUid, setFuncMap) {
    try {
      setFuncMap.setIsLoading(true)
      const res = await this.getSubmissionPaperReviewerSettingsByIdAction(conferenceUid, id);
      setFuncMap.setReviewers(res.data.reviewers)
      setFuncMap.setReviewerSettings(res.data.reviewerSettings.filter(setting => {
        return setting.isDelete == 0
      }))
      setFuncMap.setOriSettings(res.data.reviewerSettings.filter(setting => {
        return setting.isDelete == 0
      }))
      setFuncMap.setReviewResultTypes(res.data.reviewResultTypes)
      setFuncMap.setDefaultReviewerSettings(res.data.reviewerSettings)
      setFuncMap.setIsLoading(false)
    }
    catch (e) {
      setFuncMap.setIsLoading(false)
      console.log(e)
    }
  },
  getSubmissionPaperReviewerSettingsByIdAction: (conferenceUid, id) => {
    return axios.get(`/conference/${conferenceUid}/submission-paper/${id}/reviewer-settings`, {
      headers: {
        "role": "topUser"
      }
    })
  },
  addReviewerSetting: function (reviewerSettings, setFuncMap) {
    setFuncMap.setReviewerSettings([...reviewerSettings, {
      reviewerId: null,
    }])
  },
  removeReviewerSetting: function (reviewerSettings, index, setFuncMap) {
    reviewerSettings.splice(index, 1)
    setFuncMap.setReviewerSettings([...reviewerSettings])
  },
  changeReviewerSetting: function (data, setFuncMap) {
    let reviewerSettingsInstance = JSON.parse(JSON.stringify(data.reviewerSettings))
    let reviewerSetting = data.defaultReviewerSettings.find(setting => { return setting.reviewerId === data.reviewerId })
    if (reviewerSetting) {
      reviewerSettingsInstance[data.index] = reviewerSetting
    }
    else {
      reviewerSettingsInstance[data.index].reviewerId = data.reviewerId
    }
    setFuncMap.setReviewerSettings(reviewerSettingsInstance)
  },
  saveSubmissionPaperReviewerSettingsById: async function (reviewerSettings, params, setFuncMap) {
    // console.log({ reviewerSettings })
    try {
      setFuncMap.setIsLoading(true)
      let reviewerSettingsClean = reviewerSettings.filter(reviewerSetting => {
        return reviewerSetting.reviewerId != null
      })
      // console.log({ params })
      const res = await this.saveSubmissionPaperReviewerSettingsByIdAction(params.conferenceUid, params.id, {
        paperResult: params.paperResult,
        reviewerSettings: reviewerSettingsClean
      });
      setFuncMap.message.open({
        key: "update",
        type: 'success',
        content: 'Update success',
        duration: 2,
      });
      setFuncMap.setIsLoading(false)
      pfn.getSubmissionPapers(params.conferenceUid, setFuncMap.parentSetFuncMap)
      setFuncMap.parentSetFuncMap.setModalConfig({
        isOpen: false
      })
      // this.getSubmissionPaperReviewerSettingsById(params.id, params.conferenceUid, setFuncMap)

    }
    catch (e) {
      setFuncMap.setIsLoading(false)
      console.log(e)
    }
  },
  saveSubmissionPaperReviewerSettingsByIdAction: (conferenceUid, id, settingData) => {
    return axios.post(`/conference/${conferenceUid}/submission-paper/${id}/reviewer-settings`,
      settingData, {
      headers: {
        "role": "topUser"
      }
    })
  },
  compareSettingChange: (oriData, currentData) => {
    let oriDataIds = oriData.reviewSettings.filter(setting => {
      return setting.reviewerId != null
    }).map(setting => setting.reviewerId).sort()
    let currentDataIds = currentData.reviewSettings.filter(setting => {
      return setting.reviewerId != null
    }).map(setting => setting.reviewerId).sort()
    let oriCombineData = [oriData.result, ...oriDataIds]
    let currentCombineData = [currentData.result, ...currentDataIds]
    console.log({ oriCombineData, currentCombineData })
    return oriCombineData.join(";") != currentCombineData.join(";")
  },
  generatePaperResultTypes: (reviewResultTypes) => {
    return [{
      label: "未審",
      value: 0
    }, ...reviewResultTypes.map(paperType => {
      return {
        label: paperType.name,
        value: paperType.id
      }
    }), {
      label: "不接受(Reject)",
      value: -1
    }]
  },
  isShowPaperResultTypes: function (reviewerSettings) {
    let removeReviewerIdIsNull = reviewerSettings.filter(setting => setting.reviewerId != null)
    let reviewerSettingMap = {}
    removeReviewerIdIsNull.forEach(setting => {
      reviewerSettingMap[setting.reviewerId] = setting
    })

    let count = 0;
    for (var key in reviewerSettingMap) {
      let setting = reviewerSettingMap[key]
      if (setting.reviewerId != null, setting.score != null, setting.result != null, setting.comment)
        count++;
    }
    return count > 1
  }
}