import axios from 'axios';
import { Tag, Typography, Popconfirm, message, Spin, Modal, Button, Row, Col, Card } from 'antd';
import {
  CloseOutlined,
  CheckCircleOutlined,
  DragOutlined
} from '@ant-design/icons';

export default {
  getFiles: async function (setFuncMap) {
    try {
      const res = await this.getFilesAction()
      setFuncMap.setFiles(res.data)
      setFuncMap.setIsPageLoading(false)
    } catch (error) {
    }
  },
  getFilesAction: () => {
    return axios({
      method: 'GET',
      url: `/site/files`
    })
  },  
  downloadFile: async function (id, setIsItemLoading) {
    try {
      setIsItemLoading(true)
      const res = await this.getFileAction(id)
      let data = res.data
      const response = await axios({
        url: `${window.CONFS.cloudDomain}/${data.fileName}?time=${new Date().getTime()}`, // 替換為實際的檔案 URL
        method: 'GET',
        responseType: 'blob', // 重要，確保下載的文件是 blob
      });

      // 創建一個URL來表示下載的檔案
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${data.name}${data.ext}`); // 替換為實際的檔案名稱
      link.target = "_blank"
      document.body.appendChild(link);
      link.click();

      // 移除link元素和釋放URL對象
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      setIsItemLoading(false)
    } catch (error) {
      setIsItemLoading(false)
      console.error('下載檔案失敗:', error);
    }
  },
  getFileAction: (id) => {
    return axios({
      method: 'GET',
      url: `/site/files/${id}`
    })
  }
};
