import React, { useEffect, useState } from 'react';
// import 'antd/dist/antd.css';
import { Table, Typography, message, Spin, Modal, Button } from 'antd';
import { toolUtils } from '../../../../utils/toolUtils'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Conference.scss';
import {
  HighlightOutlined,
  CheckOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined
} from '@ant-design/icons';
import { render } from '@testing-library/react';
const { Text } = Typography;
function Conference(props) {
  const navigate = useNavigate()
  const [isPageLoading, setIsPageLoading] = useState(true)
  useEffect(() => {
    async function getConference() {
      try {
        const res = await getConferenceByUidAction(props.conferenceUid)
        props.setContentHeader(res.data.name)
        setIsPageLoading(false)
      } catch (error) {
      }
    }
    getConference()

  }, []);

  return (
    <Spin spinning={isPageLoading}>
      <div className="Conference">
        Conference
      </div>
    </Spin>
  );
}

const getConferenceByUidAction = function (conferenceUid) {
  return axios({
    method: 'GET',
    url: `/conference/${conferenceUid}`,
    headers: {
      "role": "topUser"
    }
  })
}

export default Conference;
