import React, { useEffect, useState } from 'react';
import { DataContext } from '../../../Context/DataContext';
// import 'antd/dist/antd.css';
import { Input, Button, message, Spin, List, Row, Col, Modal } from 'antd';
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom';
import {
    FileTextOutlined
} from '@ant-design/icons';
import FileItem from './FileItem'
import efn from './FileFns'
import './File.scss';


function File(props) {
    const { globalData } = React.useContext(DataContext)
    const navigate = useNavigate()
    const location = useLocation()
    const { action, id } = useParams();
    const { TextArea } = Input
    const [files, setFiles] = useState([])
    const [isPageLoading, setIsPageLoading] = useState(true)
    const [modalConfig, setModalConfig] = useState({
        isOpen: false,
        content: ""
    })

    const [modalTitle, setModalTitle] = useState("")
    const setFuncMap = {
        message, setIsPageLoading, setFiles, navigate, setModalTitle
    }
    useEffect(() => {
        efn.getFiles(setFuncMap)
    }, [globalData.langChangeTime]);

    return (
        <Spin spinning={isPageLoading}>
            <div className="File">
                {files.map(file => {
                    return <FileItem id={file.id} name={file.name} />
                })}
            </div>
            <Modal wrapClassName="modal-theme"
                width={"80%"}
                title={<Row justify={"center"}><Col>{modalTitle}</Col></Row>}
                open={modalConfig.isOpen}
                footer={[]}
                destroyOnClose
                onCancel={() => {
                    navigate("/files")
                }}>
                {modalConfig.content}
            </Modal>
        </Spin>
    );
}


export default File;
