import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Typography, Collapse, Button, Row, Col, Popconfirm, Badge } from 'antd';
import {
  CloseOutlined,
  CheckOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined
} from '@ant-design/icons';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import efn from './CallForPapersFns'
import update from 'immutability-helper';
const { Text } = Typography;
const ItemType = 'PANEL';

const DraggablePanel = ({ id, index, movePanel, header, children }) => {
  const ref = useRef(null);
 
  const [, drop] = useDrop({
    accept: ItemType,
    hover(item, monitor) {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      movePanel(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { type: ItemType, id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1, marginBottom: 8, backgroundColor: 'white', border: '1px solid #d9d9d9', borderRadius: 4 }}>
      <Collapse items={[{
        key: id,
        label: header,
        children: children
      }]} />      
    </div>
  );
};

const DraggableTable = (props) => {
  const { initialPanels, setFuncMap, conferenceUid } = props
  const [panels, setPanels] = useState(initialPanels);
  const [initialOrder] = useState(initialPanels.map(panel => `${panel.id}${panel.sort}`));
  const [currentOrder, setCurrentOrder] = useState(initialPanels.map(panel => `${panel.id}${panel.sort}`));

  useEffect(() => {
    setPanels(initialPanels)
  }, [initialPanels])

  const movePanel = useCallback((dragIndex, hoverIndex) => {
    const dragPanel = panels[dragIndex];
    const newPanels = [...panels];
    newPanels.splice(dragIndex, 1);
    newPanels.splice(hoverIndex, 0, dragPanel);
    setPanels(newPanels);
    // setCurrentOrder(newPanels.map(panel => panel.id));
    if (newPanels.map(item => `${item.id}${item.sort}`).join(",") === initialOrder.join(",")) {
      setFuncMap.setCallForPapersGroupsSortChange([])
    }
    else {
      setFuncMap.setCallForPapersGroupsSortChange(newPanels.map((item, index) => {
        return {
          id: item.id, sort: index + 1
        }
      }))
    }
  }, [panels]);


  const HeaderCell = function (props) {
    const { render, setFuncMap } = props
    return <Row style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} gutter={16}>
      <Col>
        {render.sequence}.
        <Badge
          className="site-badge-count-109"
          count={render.types.length}
          style={{
            backgroundColor: render.types.length > 0 ? '#faad14' : '#d2d2d2',
            margin: "0 5px"
          }}
          showZero
        />
        {render.name} [{render.types.length}]
      </Col>
      <Col>
        <Button size="small" style={{ marginRight: "5px" }}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            efn.openCallForPapersGroupUpdatePage(props.conferenceUid, render.id, "更新徵稿群組", setFuncMap)
          }}>編輯</Button>
        <Popconfirm
          placement="left"
          title={`確認要刪除 ${render.name}?`}
          onConfirm={(event) => {
            event.preventDefault();
            event.stopPropagation();
            efn.deleteCallForPapersGroup(render.id, props, setFuncMap)
          }}
          onCancel={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
          okText="Yes"
          cancelText="No"
        ><Button size="small" type="primary" onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }} danger><CloseOutlined /></Button>
        </Popconfirm>
      </Col>
    </Row>
  }

  return (
    <DndProvider backend={HTML5Backend}>
      {panels.map((panel, index) => (
        <DraggablePanel
          key={panel.id}
          index={index}
          id={panel.id}
          header={<HeaderCell
            render={panel}
            setFuncMap={setFuncMap}
            conferenceUid={conferenceUid} />}
          movePanel={movePanel}
        >
          <div className="types">
            {panel.types.map(type => {
              return efn.generateTypeIcon(conferenceUid, type, setFuncMap, efn)
            })}
          </div>
        </DraggablePanel>
      ))}
    </DndProvider>
  );
};



export default DraggableTable;