import React, { useState, useEffect } from 'react';
import efn from './SubmissionPaperAdminReviewerSettingFn.js'
import { toolUtils } from '../../../../utils/toolUtils.js'
import { Spin, message, Badge, Popconfirm, Select, Card, Button, Row, Col } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import {
  CloseOutlined,
} from '@ant-design/icons';

const SubmissionPaperAdminReviewerSetting = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [reviewerSettings, setReviewerSettings] = useState([])
  const [oriSettings, setOriSettings] = useState([])
  const [defaultReviewerSettings, setDefaultReviewerSettings] = useState([])
  const [reviewResultTypes, setReviewResultTypes] = useState([])
  const [paperResult, setPaperResult] = useState(props.result)
  const [reviewers, setReviewers] = useState([])
  const setFuncMap = {
    message, setIsLoading, setReviewerSettings, setOriSettings,
    setDefaultReviewerSettings, setReviewers, setReviewResultTypes,
    parentSetFuncMap: props.setFuncMap
  }
  useEffect(() => {
    efn.getSubmissionPaperReviewerSettingsById(props.id, props.conferenceUid, setFuncMap)
  }, []);
  return (
    <Spin spinning={isLoading} >
      <Row style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <Col span={24} style={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <Row gutter={16} justify={"space-between"}>
            <Col span={20}>
              <Row gutter={4}>
                <Col span={8}>
                  <Button type="default" onClick={() => {
                    efn.addReviewerSetting(reviewerSettings, setFuncMap)
                  }}>新增審核人員</Button>
                  {/* <div>reviewerSettings:{JSON.stringify(reviewerSettings)}</div>
              <div>oriSettings:{JSON.stringify(oriSettings)}</div> */}
                </Col>
                <Col span={10}>
                  {efn.isShowPaperResultTypes(reviewerSettings) &&
                    <Select
                      style={{ display: "flex" }}
                      options={efn.generatePaperResultTypes(reviewResultTypes)}
                      defaultValue={paperResult}
                      onChange={(val) => {
                        setPaperResult(val)
                      }}></Select>}
                </Col>
              </Row>
            </Col>
            <Col span={4}>
              {efn.compareSettingChange({ result: props.result, reviewSettings: oriSettings }, { result: paperResult, reviewSettings: reviewerSettings }) &&
                <Button type="primary" onClick={() => {
                  efn.saveSubmissionPaperReviewerSettingsById(reviewerSettings, {
                    paperResult,
                    id: props.id,
                    conferenceUid: props.conferenceUid
                  }, setFuncMap)
                }}>儲存</Button>}
            </Col>
          </Row>
          <div style={{ display: "flex", marginTop: "16px", maxHeight: "60vh", flex: 1, overflow: "auto", flexDirection: "column" }}>
            {reviewerSettings.map((reviewerSetting, index) => {
              let reviewResultType = reviewResultTypes.find(reviewResultType => {
                return reviewResultType.id === reviewerSetting.result
              })
              return <Row key={uuidv4()} style={{ marginTop: "8px" }}>
                <Col span={24}>
                  <Card
                    title={generateSelectArea(index, reviewerSetting, reviewerSettings, defaultReviewerSettings, reviewers, setFuncMap)}
                    style={{
                      width: "100%",
                    }}
                  >
                    <Row gutter={8} style={{ flexFlow: "noWrap", marginBottom: "4px" }}><Col span={3}>意見</Col><Col>{reviewerSetting.comment}</Col></Row>
                    <Row gutter={8} style={{ flexFlow: "noWrap", marginBottom: "4px" }}><Col>審查結果</Col>
                      <Col>{reviewerSetting.result == -1 ? "不接受(Reject)" : reviewResultType ? reviewResultType.name : reviewerSetting.result}</Col></Row>
                    <Row gutter={8} style={{ flexFlow: "noWrap", marginBottom: "4px" }}><Col>分數</Col><Col>{reviewerSetting.score}</Col></Row>
                  </Card>
                </Col>
              </Row>
            })}
          </div>
        </Col>
      </Row>
    </Spin>
  );
};

const generateSelectArea = function (index, reviewerSetting, reviewerSettings, defaultReviewerSettings, reviewers, setFuncMap) {
  return <Row>
    <Col span={2}>
      <Popconfirm
        placement="top"
        title={`確認要刪除 ${index + 1}?`}
        onConfirm={() => {
          efn.removeReviewerSetting(reviewerSettings, index, setFuncMap)
        }}
        okText="Yes"
        cancelText="No"
      ><Badge
          className="site-badge-count-109"
          count={<CloseOutlined />}
          style={{
            backgroundColor: '#ff4d4f',
            color: '#ffffff',
            marginRight: "10px",
            cursor: "pointer"
          }} />
      </Popconfirm>
    </Col>
    <Col span={2}>
      <Badge
        className="site-badge-count-109"
        count={index + 1}
        style={{
          backgroundColor: '#faad14',
          margin: "0 5px"
        }}
        showZero
      />
    </Col>
    <Col span={20}>
      <Select
        style={{ width: "100%" }}
        showSearch filterOption={toolUtils.filterOption}
        defaultValue={reviewerSetting.reviewerId}
        onChange={(reviewerId) => {
          efn.changeReviewerSetting({
            reviewerSettings,
            defaultReviewerSettings,
            index,
            reviewerId
          }, setFuncMap)
        }}
        options={[
          {
            value: null,
            label: " ",
          },
          ...reviewers.map(reviewer => {
            return {
              value: reviewer.id,
              label: `${reviewer.name} - ${reviewer.unit}`,
            }
          })
        ]}
      /></Col>
  </Row>
}

export default SubmissionPaperAdminReviewerSetting;