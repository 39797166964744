import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import './FinalVersionPaper.scss';
// import 'antd/dist/antd.css';
import { toolUtils } from '../../../../../utils/toolUtils';
import {
  CloudDownloadOutlined
} from '@ant-design/icons';

function FinalVersionPaper(props) {

  const [isLoading, setIsLoading] = useState(false)

  return (
    <span className="FinalVersionPaper" onClick={() => {
      toolUtils.downloadFile(props.fileUrl, props.fileName, setIsLoading)
    }}>{isLoading ? <Spin /> : <CloudDownloadOutlined />}{props.fileName}</span>
  );
}


export default FinalVersionPaper;
