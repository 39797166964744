export default {
    0: "CREATED",
    1: "WAIT_VERIFY",
    2: "VERIFY_REJECT",
    3: "REWAIT_VERIFY",
    4: "SUCCESS",
    5: "WAIT_FINALVERSION_VERIFY",
    6: "FINALVERSION_REJECT",
    7: "REWAIT_FINALVERSION_VERIFY",
    8: "PASSED",
}