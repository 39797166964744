import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Typography, Row, Col, Layout, Card, message } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  AppstoreOutlined, UserOutlined
} from '@ant-design/icons';
import efn from './UserLogicFns'
import './ForgotPwd.scss';
const { Title, Text } = Typography;
function ResetPwd(props) {
  const [isLogining, setIsLogining] = useState(false)
  const navigate = useNavigate()
  const location = useLocation();
  const setFuncMap = {
    setIsLogining, ...props.setFuncMap
  }
  const onFinish = (values) => {
    efn.forgotUserPwd(values, setFuncMap, {
      navigate, location
    })
  };
  return (
    <div className="ResetPwd">
      <Layout>
        <Row justify="center" align="middle">
          <Col span={24}>
            <Card style={{ borderRadius: '8px', display: "flex", flexDirection: "column" }}>
              <Title level={2} style={{ textAlign: 'center', marginBottom: '24px' }}>ICEI Forgot Password</Title>
              <Form
                name="google_login"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                layout="horizontal"
              >
                {generateForgotPwdAttribute().map((item, key) => {
                  return <Form.Item key={key}
                    name={item.name}
                    label={item.label}
                    rules={[{ required: item.required, message: `請輸入${item.label}` }, ...item.rules]}
                  >
                    {item.type === "text" && <Input placeholder={item.label} size="large" />}
                  </Form.Item>
                })}
                <div></div>
                <Form.Item>
                  <Row gutter={16} justify={'space-around'}>
                    <Col>
                      <Button loading={isLogining} type="primary" htmlType="submit" style={{ background: "#fea4a4" }} size="large">
                        Send
                      </Button>
                    </Col>
                    <Col>
                      <Button size="large" onClick={() => { navigate(location.pathname) }}>
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Layout>
    </div>
  );
}

const generateForgotPwdAttribute = function () {
  return [{
    label: "帳號",
    type: "text",
    name: "account",
    required: true,
    rules: [{ type: "email", message: 'It\'s not email format' }]
  }]
}

export default ResetPwd;
