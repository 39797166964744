import React, { useEffect, useState } from 'react';
// import 'antd/dist/antd.css';
import { Table, Typography, message, Spin, Row, Col, Modal, Button } from 'antd';
import { toolUtils } from '../../../../utils/toolUtils'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './ReviewSubmissionPaper.scss';
import efn from './ReviewSubmissionPapersFns'
import {
  HighlightOutlined,
  CheckOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined
} from '@ant-design/icons';
import { render } from '@testing-library/react';
const { Text } = Typography;
function ReviewSubmissionPaper(props) {
  const navigate = useNavigate()
  const [isInfoLoading, setIsInfoLoading] = useState(false)
  const [isContentLoading, setIsContentLoading] = useState(false)
  const [reviewNote, setReviewNote] = useState()
  const [submissionPapers, setSubmissionPapers] = useState([])
  const [modalConfig, setModalConfig] = useState({
    isOpen: false,
    title: "",
    content: ""
  })
  const setFuncMap = {
    message, setIsContentLoading, setIsInfoLoading,
    setReviewNote, setSubmissionPapers,
    setModalConfig
  }
  useEffect(() => {
    efn.getReviewSubmissionPaperInfo(setFuncMap)
    efn.getWaitReviewSubmissionPapers(setFuncMap)
  }, []);

  return (
    <div className="ReviewSubmissionPaper">
      <Spin spinning={isInfoLoading}>
        <Row justify={"center"}>
          <Col span={16}>
            <div className="main"
              style={{ justifyContent: "center", display: "flex" }}
              dangerouslySetInnerHTML={{ __html: reviewNote }}></div>
          </Col>
        </Row>
      </Spin>
      <Spin spinning={isContentLoading}>
        <Row justify={"center"}>
          <Col span={16}>
            <Table dataSource={submissionPapers} columns={
              efn.generateReviewSubmissionPaperColumns(setFuncMap)
            } />
          </Col>
        </Row>
      </Spin>
      <Modal wrapClassName="modal-theme"
        width={"80%"}
        title={modalConfig.title}
        open={modalConfig.isOpen}
        footer={[]}
        destroyOnClose
        onCancel={() => {
          setModalConfig({
            isOpen: false,
          })
        }}>
        {modalConfig.content}
      </Modal>
    </div>
  );
}


export default ReviewSubmissionPaper;
