import axios from 'axios';
import { Tag, Typography, Popconfirm, message, Spin, Modal, Button, Row, Col, Card } from 'antd';
import {
  CloseOutlined,
  CheckCircleOutlined,
  DragOutlined
} from '@ant-design/icons';
import { localStorageUtils } from '../../../../utils/localStorageUtils'

export default {
  registerUser: async function (payload, setFuncMap, fn) {
    try {
      payload.email = payload.account
      setFuncMap.setIsLogining(true)
      const res = await this.registerUserAction(payload)
      setFuncMap.message.open({
        type: 'success',
        content: setFuncMap.t(`system.signupSuccess`),
        duration: 2,
      });
      fn.navigate(`${fn.location.pathname}?action=login&account=${payload.account}`)
      setFuncMap.setIsLogining(false)
    } catch (error) {
      console.log(error.response)
      setFuncMap.message.open({
        type: 'error',
        content: setFuncMap.t(`error.${error.response.status}`),
        duration: 2,
      });
      setFuncMap.setIsLogining(false)
    }
  },
  registerUserAction: (payload) => {
    return axios({
      method: 'POST',
      url: `/user/registor`,
      data: payload
    })
  },
  loginUser: async function (payload, setFuncMap, fn) {
    try {
      setFuncMap.setIsLogining(true)
      const res = await this.loginUserAction(payload)
      let result = res.data
      console.log({ result })
      localStorageUtils.save("user", result)
      if (result.isChangePwd > 0) {
        fn.navigate(`${fn.location.pathname}?action=reset-password`)
      }
      else {
        fn.navigate(`${fn.location.pathname}`)
      }
      setFuncMap.setIsLogining(false)
    } catch (error) {
      setFuncMap.message.open({
        type: 'error',
        content: setFuncMap.t(`error.${error.response.status}`),
        duration: 2,
      });
      setFuncMap.setIsLogining(false)
    }
  },
  loginUserAction: (payload) => {
    return axios({
      method: 'POST',
      url: `/user/login`,
      data: payload
    })
  },
  resetUserPwd: async function (payload, setFuncMap, fn) {
    try {
      setFuncMap.setIsLogining(true)
      const res = await this.resetUserPwdAction(payload)
      let userData = localStorageUtils.get("user")
      userData.isChangePwd = 0
      localStorageUtils.save("user", userData)
      setFuncMap.message.open({
        type: 'success',
        content: setFuncMap.t(`system.updateSuccess`),
        duration: 2,
      });
      fn.navigate(`${fn.location.pathname}`)
      setFuncMap.setIsLogining(false)
    } catch (error) {
      setFuncMap.message.open({
        type: 'error',
        content: setFuncMap.t(`error.${error.response.status}`),
        duration: 2,
      });
      // fn.navigate(`${fn.location.pathname}?action=login`)
      setFuncMap.setIsLogining(false)
    }
  },
  resetUserPwdAction: (payload) => {
    return axios({
      method: 'PUT',
      url: `/user/update_pwd`,
      data: payload,
      headers: {
        "role": "user"
      }
    })
  },
  forgotUserPwd: async function (payload, setFuncMap, fn) {
    try {
      setFuncMap.setIsLogining(true)
      const res = await this.forgotUserPwdAction(payload)
      setFuncMap.message.open({
        type: 'success',
        content: setFuncMap.t(`system.sendNewPasswordSuccess`),
        duration: 2,
      });
      fn.navigate(`${fn.location.pathname}?action=login&account=${payload.account}`)
      setFuncMap.setIsLogining(false)
    } catch (error) {
      setFuncMap.message.open({
        type: 'error',
        content: setFuncMap.t(`error.${error.response.status}`),
        duration: 2,
      });
      // fn.navigate(`${fn.location.pathname}?action=login`)
      setFuncMap.setIsLogining(false)
    }
  },
  forgotUserPwdAction: (payload) => {
    return axios({
      method: 'PUT',
      url: `/user/forget_pwd`,
      data: payload,
      headers: {
        "role": "user"
      }
    })
  },
  getNationalities: async function (setFuncMap) {
    try {
      setFuncMap.setIsPageLoading(true)
      const res = await this.getNationalitiesAction()
      setFuncMap.setNationalities(res.data)
      setFuncMap.setIsPageLoading(false)
    } catch (error) {
      setFuncMap.message.open({
        type: 'error',
        content: setFuncMap.t(`error.${error.response.status}`),
        duration: 2,
      });
      setFuncMap.setIsPageLoading(false)
    }
  },
  getNationalitiesAction: () => {
    return axios({
      method: 'GET',
      url: `/site/nationalities`
    })
  },
};
