import React, { useEffect } from 'react';
import Login from './ICEI/Management/TopUser/Login/Login.js';
import ForgetPassword from './ICEI/Management/TopUser/Login/ForgetPassword.js';
import ResetPassword from './ICEI/Management/TopUser/Login/ResetPassword.js';
import Dashboard from './ICEI/Management/TopUser/Dashboard.js';
import ReviewerDashboard from './ICEI/Management/Reviewer/ReviewerDashboard.js'
import { default as ReviewerLogin } from './ICEI/Management/Reviewer/Login/Login.js';
import { default as ReviewerForgetPassword } from './ICEI/Management/Reviewer/Login/ForgetPassword.js';
import { default as ReviewerResetPassword } from './ICEI/Management/Reviewer/Login/ResetPassword.js';
// import {default as ReviewerDashboard} from './ICEI/Management/Reviewer/Dashboard.js';
import NotFound from './NotFound.js'
import HomePage from './ICEI/User/HomePage/HomePage.js';
import './App.scss';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import { DataContext } from './Context/DataContext.js';
import { DataReducer } from './Reducer/DataReducer.js';
import { localStorageUtils } from './utils/localStorageUtils'
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { initializeGA } from './utils/ga';



function App() {
  const [globalData, globalDataDispatch] = React.useReducer(DataReducer, {
    langChangeTime: new Date().getTime()
  });

  useEffect(() => {
    // 初始化 GA 并传入用户账户 ID
    initializeGA(localStorage);
  }, [localStorage]);
  const navigate = useNavigate()
  const location = useLocation()
  const { t, i18n } = useTranslation();
  if (process.env.REACT_APP_ENV === "production") {
    window.CONFS = {
      cloudDomain: "https://cloud.ce.nthu.edu.tw",
    }
    axios.defaults.baseURL = 'https://api.ce.nthu.edu.tw';
  }
  else if (process.env.REACT_APP_ENV === "sit") {
    window.CONFS = {
      cloudDomain: "https://sit-cloud.mywata.net",
    }
    axios.defaults.baseURL = 'https://sit-api.mywata.net';
  }
  else {
    window.CONFS = {
      cloudDomain: "http://cloud.icei.com",
    }
    axios.defaults.baseURL = 'http://api.icei.com';
  }


  axios.defaults.headers.post['Content-Type'] = 'application/json';
  // axios.defaults.baseURL = 'http://127.0.0.1:8765';

  if (!axios.interceptors.request.handlers.length) {
    axios.interceptors.request.use(request => {
      request.headers.lang = localStorageUtils.get("lang")
      getAuthorizeToken(request.headers)
      return request;
    }, error => {
      return Promise.reject(error);
    });
  }


  if (!axios.interceptors.response.handlers.length) {
    const resId = axios.interceptors.response.use(function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    }, function (error) {
      console.log({ error })
      if ([40101, 40100].indexOf(error.response.status) > -1) {
        // axios.interceptors.response.eject(interceptorsResponse)
        console.log({ errorPath: location.pathname })
        processLoginExpired(error.config.headers, location.pathname, navigate, t(`error.${error.response.status}`))
      }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    });
    localStorageUtils.save("sys", {
      resId: resId
    })
  }

  return (
    <DataContext.Provider value={{ globalData, globalDataDispatch, t }}>
      <Routes>
        <Route element={<Dashboard />} path='/mnt/top-user/conferences/:conferenceUid/:module/:feature'></Route>
        <Route element={<Dashboard />} path='/mnt/top-user/conferences/:conferenceUid'></Route>
        <Route element={<Dashboard />} path='/mnt/top-user/:module/:feature'></Route>
        <Route element={<Login />} path='/mnt/top-user/login/'></Route>
        <Route element={<ForgetPassword />} path='/mnt/top-user/forget-password/'></Route>
        <Route element={<ResetPassword />} path='/mnt/top-user/reset-password/'></Route>
        <Route element={<Dashboard />} path='/mnt/top-user/:module'></Route>
        <Route element={<Dashboard />} path='/mnt/top-user/'></Route>
        <Route element={<ReviewerLogin />} path='/mnt/reviewer/login/'></Route>
        <Route element={<ReviewerForgetPassword />} path='/mnt/reviewer/forget-password/'></Route>
        <Route element={<ReviewerResetPassword />} path='/mnt/reviewer/reset-password/'></Route>
        <Route element={<ReviewerDashboard />} path='/mnt/reviewer/'></Route>
        <Route element={<HomePage />} path='/:feature/:action/:id/:subAction/:subId'></Route>
        <Route element={<HomePage />} path='/:feature/:action/:id/:subAction'></Route>
        <Route element={<HomePage />} path='/:feature/:action/:id'></Route>
        <Route element={<HomePage />} path='/:feature/:action'></Route>
        <Route element={<HomePage />} path='/:feature'></Route>
        <Route element={<HomePage />} path='/'></Route>
        <Route element={<NotFound />} path='*'></Route>
      </Routes>
    </DataContext.Provider>
  );
}



const getAuthorizeToken = (headers) => {
  let role = headers.role
  if (role === "topUser") {
    headers.topAuthorize = localStorageUtils.get("topUser").token
    return
  }

  if (role === "reviewer") {
    if (localStorageUtils.get("reviewer")) {
      headers.ReviewAuthorize = localStorageUtils.get("reviewer").token
    }
    return
  }

  if (role === "user") {
    if (localStorageUtils.get("user")) {
      headers.Authorize = localStorageUtils.get("user").token
    }
    return
  }
}

const processLoginExpired = (headers, path, navigate, msg) => {

  let role = headers.role
  if (role === "topUser") {
    navigate(`/mnt/top-user/login?fromPath=${path}&status=${msg}`);
  }

  if (role === "reviewer") {
    console.log({ path })
    navigate(`/mnt/reviewer/login?fromPath=${path}&status=${msg}`);
  }

  if (role === "user") {
    localStorageUtils.remove("user")
    navigate(`${path}?action=login&status=${msg}`);
  }
}

export default App;
