import axios from 'axios';
import { toolUtils } from '../../../../utils/toolUtils.js'
import { Tag, Badge, Typography, Popconfirm, message, Spin, Modal, Button, Row, Col, Image } from 'antd';
import CallForPapersGroupCreatePage from './CallForPapersGroupCreatePage.js'
import CallForPapersGroupUpdatePage from './CallForPapersGroupUpdatePage.js'
import CallForPapersTypeUpsertPage from './CallForPapersTypeUpsertPage.js'
import moment from 'moment';
import {
    CloseOutlined, EditOutlined,
    PlusOutlined,
    DeleteOutlined
} from '@ant-design/icons';

export default {
    getCallForPapersGroupsComplete: async function (props, setFuncMap) {
        try {
            setFuncMap.setIsTableLoading(true)
            const res = await this.getCallForPapersGroupsCompleteAction(props.conferenceUid)
            let { groups, unsetTypes } = res.data
            groups = this.getSortedCallForPapersGroups(groups)
            groups.forEach(group => {
                group.types = group.types.sort((a, b) => a.sort - b.sort)
            })
            groups = groups.map((callForPapersGroup, i) => {
                return { sequence: (i + 1), ...callForPapersGroup }
            })
            unsetTypes = unsetTypes.map((unsetType, i) => {
                return { sequence: (i + 1), ...unsetType }
            })
            setFuncMap.setCallForPapersGroups(groups)
            setFuncMap.setUnsetCallForPapersTypes(unsetTypes)
            setFuncMap.setIsTableLoading(false)
            setFuncMap.setIsPageLoading(false)
        } catch (error) {
            setFuncMap.setIsTableLoading(false)
            // message.error(error.response.data)
        }
    },
    getCallForPapersGroupsCompleteAction: (conferenceUid) => {
        return axios({
            method: 'GET',
            url: `/conference/${conferenceUid}/call-for-papers/groups/complete`,
            headers: {
                "role": "topUser"
            }
        })
    },
    getConference: async function (props, setFuncMap) {
        try {
            const res = await this.getConferenceByUidAction(props.conferenceUid)
            props.setContentHeader(res.data.name)
            setFuncMap.setIsPageLoading(false)
        } catch (error) {
        }
    },
    getConferenceByUidAction: (conferenceUid) => {
        return axios({
            method: 'GET',
            url: `/conference/${conferenceUid}`,
            headers: {
                "role": "topUser"
            }
        })
    },
    openCallForPapersGroupCreatePage: (conferenceUid, modalName, setFuncMap) => {
        setFuncMap.setIsModalOpen(true)
        setFuncMap.setModalTitle(modalName)
        setFuncMap.setModalContent(<CallForPapersGroupCreatePage conferenceUid={conferenceUid} callForPapersGroupData={null} setFuncMap={setFuncMap} />)
    },
    openCallForPapersGroupUpdatePage: (conferenceUid, id, modalName, setFuncMap) => {
        setFuncMap.setIsModalOpen(true)
        setFuncMap.setModalTitle(modalName)
        setFuncMap.setModalContent(<CallForPapersGroupUpdatePage conferenceUid={conferenceUid} id={id} callForPapersGroups={null} setFuncMap={setFuncMap} />)
    },
    openCallForPapersTypeCreatePage: (conferenceUid, modalName, setFuncMap) => {
        setFuncMap.setIsModalOpen(true)
        setFuncMap.setModalTitle(modalName)
        setFuncMap.setModalContent(<CallForPapersTypeUpsertPage conferenceUid={conferenceUid} callForPapersTypeData={null} setFuncMap={setFuncMap} />)
    },
    openCallForPapersTypeUpdatePage: (conferenceUid, id, modalName, setFuncMap) => {
        setFuncMap.setIsModalOpen(true)
        setFuncMap.setModalTitle(modalName)
        setFuncMap.setModalContent(<CallForPapersTypeUpsertPage conferenceUid={conferenceUid} id={id} callForPapersTypes={null} setFuncMap={setFuncMap} />)
    },
    getSortedCallForPapersGroups: function (callForPapersGroups) {
        return callForPapersGroups.sort((a, b) => a.sort - b.sort)
    },
    updateCallForPapersGroupsSort: async function (payload, props, setFuncMap) {
        try {
            setFuncMap.setIsButtonSortLoading(true)
            setFuncMap.setIsTableLoading(true)
            const res = await this.updateCallForPapersGroupsSortAction(payload, props.conferenceUid)
            await this.getCallForPapersGroupsComplete(props, setFuncMap)
            setFuncMap.setCallForPapersGroupsSortChange([])
            setFuncMap.setIsButtonSortLoading(false)
            setFuncMap.setIsTableLoading(false)
        } catch (error) {
        }
    },
    updateCallForPapersGroupsSortAction: (payload, conferenceUid) => {
        return axios({
            method: 'POST',
            url: `/conference/${conferenceUid}/call-for-papers/groups/sort`,
            headers: {
                "role": "topUser"
            },
            data: payload
        })
    },
    deleteCallForPapersGroup: async function (id, props, setFuncMap) {
        try {
            setFuncMap.setIsTableLoading(true)
            const res = await this.deleteCallForPapersGroupAction(id, props.conferenceUid)
            await this.getCallForPapersGroupsComplete(props, setFuncMap)
            setFuncMap.setIsTableLoading(false)
        } catch (error) {
        }
    },
    deleteCallForPapersGroupAction: (id, conferenceUid) => {
        return axios({
            method: 'DELETE',
            url: `/conference/${conferenceUid}/call-for-papers/group/${id}`,
            headers: {
                "role": "topUser"
            }
        })
    },
    deleteCallForPapersType: async function (id, props, setFuncMap) {
        try {
            setFuncMap.setIsTableLoading(true)
            const res = await this.deleteCallForPapersTypeAction(id, props.conferenceUid)
            await this.getCallForPapersGroupsComplete(props, setFuncMap)
            setFuncMap.setIsTableLoading(false)
        } catch (error) {
            console.log(error)
        }
    },
    deleteCallForPapersTypeAction: function (id, conferenceUid) {
        return axios({
            method: 'DELETE',
            url: `/conference/${conferenceUid}/call-for-papers/type/${id}`,
            headers: {
                "role": "topUser"
            }
        })
    },
    generateTypeIcon: (conferenceUid, type, setFuncMap, efn) => {
        return <div className="type-item">
            <Tag color="gold">
                <Popconfirm
                    placement="top"
                    title={`確認要刪除 ${type.name}?`}
                    onConfirm={() => {
                        efn.deleteCallForPapersType(type.id, { conferenceUid }, setFuncMap)
                    }}
                    okText="Yes"
                    cancelText="No"
                ><Badge
                        className="site-badge-count-109"
                        count={<CloseOutlined />}
                        style={{
                            backgroundColor: '#ff4d4f',
                            color: '#ffffff',
                            marginRight: "10px"
                        }} />
                </Popconfirm>
                <Badge
                    className="site-badge-count-109"
                    count={<EditOutlined />}
                    onClick={() => {
                        efn.openCallForPapersTypeUpdatePage(conferenceUid, type.id, "更新徵稿類型", setFuncMap)
                    }}
                    style={{
                        backgroundColor: '#c7e3b5',
                        color: '#ffffff',
                        marginRight: "10px"
                    }} />
                {type.name}
            </Tag>
        </div>
    }
};
