import axios from 'axios';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { toolUtils } from '../../../../utils/toolUtils.js'
import { Tag, Typography, Popconfirm, message, Spin, Badge, Button, Row, Col, Select } from 'antd';
import SubmissionPaperDetailPage from './SubmissionPaperDetailPage.js'
import AdminSubmissionPaperUpsertPage from './AdminSubmissionPaperUpsertPage.js' 
import moment from 'moment';
import statusCode from '../../../../enum/submissionPaperStatusEnum.js'
import statusColor from '../../../../enum/submissionPaperStatusColorEnum.js'
import FinalVersionPaper from '../../../User/HomePage/User/UserSubmissionPaper/FinalVersionPaper.js';
import {
    CloseOutlined,
    CheckCircleOutlined,
    DragOutlined
} from '@ant-design/icons';

export default {
    generateSubmissionPaperMenu: function (props, setFuncMap, params) {
        let reviewResultTypes = params.reviewResultTypes
        let changedResultPapers = params.changedResultPapers
        const { Text } = Typography;
        return [{
            title: "",
            dataIndex: 'id',
            key: 'id',
            width: 100,
            render: (_, render) => {
                return <Row justify={"end"} gutter={4}>
                    <Col>
                        <Button size="small" style={{ marginRight: "5px" }}
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                this.openSubmissionPaperUpdatePage(props.conferenceUid, render.id, "", setFuncMap)
                            }}>查看</Button>
                        <Button size="small" style={{ marginRight: "5px" }}
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                this.openSubmissionPaperAdminUpdatePage(props.conferenceUid, render.id, "編輯稿件", setFuncMap)
                            }}>編輯</Button>
                    </Col>
                </Row>
            }
        },
        {
            title: '',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            render: (status) => {
                return <Tag color={statusColor[status]} key={status}>
                    {statusCode[status]}
                </Tag>
            },
            sorter: (a, b) => a.status - b.status,
        },
        {
            title: '稿件編號',
            dataIndex: 'paperKey',
            key: 'paperKey',
            width: 100,
        },
        {
            title: '徵稿類別',
            dataIndex: 'type',
            key: 'type',
            width: 250,
            render: (_, render) => {
                return <><div>{render.callForPaperType}</div><div><i>{render.paperType}</i></div></>
            },
            sorter: (a, b) => a.status - b.status,
        },
        {
            title: '標題',
            dataIndex: 'title',
            key: 'title',
            render: (_, render) => {
                return <div style={{ overflow: "auto" }}>
                    <div className="paper-item">
                        <div style={{ fontWeight: "bold" }}>{render.titleCn}</div>
                        {render.cnFinalVersionFilename &&
                            <>定稿:<FinalVersionPaper fileUrl={`${window.CONFS.cloudDomain}/${render.cnFinalVersionFilename}`}
                                fileName={render.cnFinalVersionOriginFilename} /></>}
                    </div>
                    <div className="paper-item">
                        <div style={{ fontWeight: "bold" }}>{render.titleEn}</div>
                        {render.enFinalVersionFilename &&
                            <>定稿:<FinalVersionPaper fileUrl={`${window.CONFS.cloudDomain}/${render.enFinalVersionFilename}`}
                                fileName={render.enFinalVersionOriginFilename} /></>}
                    </div>
                    {render.reviewerSettings.map((setting, key) => {
                        return <Row key={key} style={{ fontWeight: "bold", borderBottom: "1px solid gray", marginBottom: "4px", paddingBottom: "4px" }}>
                            <Col>{setting.name}</Col><Col><Badge
                                className="site-badge-count-109"
                                count={setting.score}
                                style={{
                                    backgroundColor: setting.score > 0 ? '#faad14' : '#d2d2d2',
                                    margin: "0 5px"
                                }}
                                showZero
                            /></Col><Col>{setting.result == -1 ? "不接受(Reject)" : setting.resultName}</Col>
                        </Row>
                    })}
                </div>
            }
        },
        {
            title: '審查結果',
            dataIndex: 'result',
            key: 'result',
            width: 200,
            sorter: (a, b) => a.result - b.result,
            onCell: ((render) => {
                if (changedResultPapers[render.id] && changedResultPapers[render.id] != render.result) {
                    return {
                        className: "changePaperResult"
                    }
                }
                return {}
            }),
            render: (_, render) => {
                let checkReviewerDone = true
                for (var k in render.reviewerSettings) {
                    let setting = render.reviewerSettings[k]
                    if (!(setting.score != null && setting.result != null && setting.isNotCommented == false)) {
                        checkReviewerDone = false
                        break;
                    }
                }
                if (render.status == 4 && checkReviewerDone) {
                    return <div onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                    }}><Select
                            defaultValue={render.result}
                            style={{
                                width: 150,
                            }}
                            onChange={(value) => {
                                changedResultPapers[render.id] = value
                                setFuncMap.setChangedResultPapers(JSON.parse(JSON.stringify(changedResultPapers)))
                            }}
                            options={[
                                {
                                    value: 0,
                                    label: "未審",
                                },
                                ...reviewResultTypes.map(item => {
                                    return {
                                        value: item.id,
                                        label: item.name,
                                    }
                                }),
                                {
                                    value: -1,
                                    label: "不接受",
                                }
                            ]}
                        />
                    </div>
                }

                if (render.status > 4) {
                    return reviewResultTypes.find(item => item.id == render.result).name
                }
            }
        },
        {
            title: '學生',
            dataIndex: 'userName',
            key: 'userName',
            width: 100,
            render: (_, render) => {
                return <>
                    <div>{render.userName}</div>
                    {/* <div>{render.email}</div> */}
                </>
            }
        },
        {
            title: '最後更新時間',
            dataIndex: 'lastUpdateAt',
            key: 'lastUpdateAt',
            width: 200,
            sorter: (a, b) => a.lastUpdateAt.localeCompare(b.lastUpdateAt),
            render: (_, render) => {
                return moment(render.lastUpdateAt).format("yyyy-MM-DD HH:mm:ss")
            }
        }]
    },
    getSubmissionPapers: async function (conferenceUid, setFuncMap) {
        try {
            setFuncMap.setIsTableLoading(true)
            setFuncMap.setSubmissionPapers([])
            setFuncMap.setIsRefreshButtonLoading(true)
            const res = await this.getSubmissionPapersAction(conferenceUid)
            setFuncMap.setLastGetDataTime(moment().format("yyyy-MM-DD HH:mm:ss"))
            res.data.submissionPapers.forEach(item => {
                item.key = item.id
            })
            setFuncMap.setSubmissionPapers(res.data.submissionPapers)
            setFuncMap.setReviewResultTypes(res.data.reviewResultTypes)
            setFuncMap.setIsTableLoading(false)
            setFuncMap.setIsPageLoading(false)
            setFuncMap.setIsRefreshButtonLoading(false)
        } catch (error) {
            setFuncMap.setIsPageLoading(false)
            setFuncMap.setIsTableLoading(false)
            setFuncMap.setIsRefreshButtonLoading(false)
            console.log(error)
            // message.error(error.response.data)
        }
    }, getSubmissionPapersAction: (conferenceUid) => {
        return axios({
            method: 'GET',
            url: `/conference/${conferenceUid}/submission-papers`,
            headers: {
                "role": "topUser"
            }
        })
    },
    getLangSortedSubmissionPapers: function (submissionPapers, lang) {
        return submissionPapers.filter(a => { return a.lang == lang }).sort((a, b) => a.sort - b.sort)
    },
    batchDownloadAllPaperResults: async function (conferenceUid, setFuncMap) {
        setFuncMap.setIsDownloadAllButtonLoading(true)
        const res = await this.batchDownloadAllPaperResultsAction(conferenceUid, {
            type: "all"
        })
        this.processDownloadAllPaperResults(res)
        setFuncMap.setIsDownloadAllButtonLoading(false)
    },
    batchDownloadSpecificPaperResults: async function (conferenceUid, paperIds, setFuncMap) {
        setFuncMap.setIsSpecificDownloadlButtonLoading(true)
        const res = await this.batchDownloadAllPaperResultsAction(conferenceUid, {
            paperIds
        })
        this.processDownloadAllPaperResults(res)
        setFuncMap.setIsSpecificDownloadlButtonLoading(false)
    },
    processDownloadAllPaperResults: async function (res) {
        let differenceNumber = 4
        try {
            let submissionPapers = res.data.submissionPapers
            let maxReviewerNumber = 2
            submissionPapers.forEach(submissionPaper => {
                if (submissionPaper.reviewerSettings.length > maxReviewerNumber) {
                    maxReviewerNumber = submissionPaper.reviewerSettings.length
                }
            })
            let excelRecords = submissionPapers.map((row, index) => {
                let contactDetail = this.getContactDetail(row)
                let excelData = {
                    "系統已分搞, 請勿更改審查委員": "",
                    "文章ID": row.paperKey,
                    "稿件語言": row.titleCn ? "ch" : "en",
                    "徵稿群組": row.callForPaperGroupName,
                    "徵稿類別": row.callForPaperType,
                    "希望發表方式": row.paperType,
                    "校內稿件": "",
                }

                excelData = this.generateReviewerName(excelData, row.reviewerSettings, maxReviewerNumber)

                excelData = {
                    ...excelData,
                    "ch主題": row.titleCn,
                    "en主題": row.titleEn,
                    "作者1": row.author1 ? row.author1 : row.author,
                    "單位1": row.authorUnit1,
                    "作者2": row.author2,
                    "單位2": row.authorUnit2,
                    "作者3": row.author3,
                    "單位3": row.authorUnit3,
                    "作者4": row.author4,
                    "單位4": row.authorUnit4,
                    "作者5": row.author5,
                    "單位5": row.authorUnit5,
                    "作者6": row.author6,
                    "單位6": row.authorUnit6,
                    "聯絡作者": contactDetail.authorName,
                    "聯絡方式": contactDetail.contactWay,
                    "上傳者": row.userName
                }

                excelData = this.generateReviewerSummary(excelData, row.reviewerSettings, maxReviewerNumber)

                excelData = {
                    ...excelData,
                    "最終評審": row.resultName,
                    [`分數差異大於${differenceNumber}分或需要再次檢視文章`]: { f: `IF(OR(ISBLANK(AC${index + 2}), ISBLANK(AF${index + 2})), "", IF(ABS(AC${index + 2} - AF${index + 2}) > ${differenceNumber}, "V", ""))` },
                    "定稿": ""
                }
                return excelData
            })
            const worksheet = XLSX.utils.json_to_sheet(excelRecords);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, '稿件資料');
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const file = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
            saveAs(file, `${res.data.conferenceName}${moment().format("yyyyMMDDHHmmss")}.xlsx`);

        } catch (error) {
            console.log(error)
            // message.error(error.response.data)
        }
    }
    , batchDownloadAllPaperResultsAction: (conferenceUid, paylaod) => {
        return axios({
            method: 'POST',
            url: `/conference/${conferenceUid}/submission-papers/download`,
            headers: {
                "role": "topUser"
            },
            data: paylaod
        })
    }
    , getContactDetail: (record) => {
        let authorName = ""
        let contactWay = ""
        if (record.authorIscontact1 == 1) {
            authorName = `作者1: ${record.author1}`
            contactWay = record.authorContactWay1
        }
        if (record.authorIscontact2 == 1) {
            authorName = `作者2: ${record.author2}`
            contactWay = record.authorContactWay2
        }
        if (record.authorIscontact3 == 1) {
            authorName = `作者3: ${record.author3}`
            contactWay = record.authorContactWay3
        }
        if (record.authorIscontact4 == 1) {
            authorName = `作者4: ${record.author4}`
            contactWay = record.authorContactWay4
        }
        if (record.authorIscontact5 == 1) {
            authorName = `作者5: ${record.author5}`
            contactWay = record.authorContactWay5
        }
        if (record.authorIscontact6 == 1) {
            authorName = `作者6: ${record.author6}`
            contactWay = record.authorContactWay6
        }

        return {
            authorName, contactWay
        }
    }
    , generateReviewerName: (excelData, reviewerSettings, maxReviewerNumber) => {
        for (var i = 0; i < maxReviewerNumber; i++) {
            if (reviewerSettings[i]) {
                excelData = {
                    ...excelData,
                    [`審查委員${i + 1}`]: reviewerSettings[i].name
                }
            }
            else {
                excelData = {
                    ...excelData,
                    [`審查委員${i + 1}`]: ""
                }
            }
        }
        return excelData
    }
    , generateReviewerSummary: (excelData, reviewerSettings, maxReviewerNumber) => {
        let reviewerScore = 0
        for (var i = 0; i < maxReviewerNumber; i++) {
            if (reviewerSettings[i]) {
                excelData = {
                    ...excelData,
                    [`評審意見${i + 1}`]: reviewerSettings[i].comment,
                    [`評審結果${i + 1}`]: reviewerSettings[i].resultName,
                    [`評審分數${i + 1}`]: reviewerSettings[i].score
                }
                reviewerScore = reviewerScore + reviewerSettings[i].score
            }
            else {
                excelData = {
                    ...excelData,
                    [`評審意見${i + 1}`]: "",
                    [`評審結果${i + 1}`]: "",
                    [`評審分數${i + 1}`]: null
                }
            }
        }

        if (reviewerScore > 0) {
            excelData = {
                ...excelData,
                "評審分數加總": reviewerScore
            }
        }
        else {
            excelData = {
                ...excelData,
                "評審分數加總": ""
            }
        }

        return excelData
    }
    , deleteSubmissionPaper: async function (conferenceUid, id, setFuncMap) {
        try {
            setFuncMap.setIsTableLoading(true)
            const res = await this.deleteSubmissionPaperAction(conferenceUid, id)
            await this.getSubmissionPapers(conferenceUid, setFuncMap)
            setFuncMap.setIsTableLoading(false)
        } catch (error) {
            setFuncMap.setIsTableLoading(false)
        }
    },
    deleteSubmissionPaperAction: (conferenceUid, id) => {
        return axios({
            method: 'DELETE',
            url: `/conference/${conferenceUid}/submission-paper/${id}`,
            headers: {
                "role": "topUser"
            }
        })
    },
    submitVerifySubmissionPaperAction: (id) => {
        return axios({
            method: 'PUT',
            url: `/submission-paper/${id}/sumbit-verify`,
            headers: {
                "role": "user"
            }
        })
    },
    openSubmissionPaperUpdatePage: (conferenceUid, id, modalName, setFuncMap) => {
        setFuncMap.setModalConfig({
            isOpen: true,
            title: modalName,
            content: <SubmissionPaperDetailPage id={id} conferenceUid={conferenceUid} setFuncMap={setFuncMap} />
        })
    },
    openSubmissionPaperAdminUpdatePage: (conferenceUid, id, modalName, setFuncMap) => {
        setFuncMap.setModalConfig({
            isOpen: true,
            title: modalName,
            content: <AdminSubmissionPaperUpsertPage id={id} conferenceUid={conferenceUid} setFuncMap={setFuncMap} />
        })
    },
    calculatePaperResultsDiff: function (oriPapers, changedPapers) {
        let diffItems = []
        for (var key in changedPapers) {
            let paper = oriPapers.find(p => p.id == key)
            if (paper && paper.result != changedPapers[key]) {
                diffItems.push({
                    id: key,
                    result: changedPapers[key]
                })
            }
        }
        return diffItems
    },
    batchUpdatePaperResults: async function (submissionPapers, changedResultPapers, conferenceUid, setFuncMap) {
        try {
            setFuncMap.setIsBatchUpdateResultButtonLoading(true)
            const res = await this.batchUpdatePaperResultsAction(
                this.calculatePaperResultsDiff(submissionPapers, changedResultPapers), conferenceUid)
            await this.getSubmissionPapers(conferenceUid, setFuncMap)
            setFuncMap.setIsBatchUpdateResultButtonLoading(false)
        } catch (error) {
            setFuncMap.setIsBatchUpdateResultButtonLoading(false)
        }
    },
    batchUpdatePaperResultsAction: (payload, conferenceUid) => {
        return axios({
            method: 'POST',
            url: `/conference/${conferenceUid}/submission-papers/status`,
            headers: {
                "role": "topUser"
            },
            data: payload
        })
    },
    getFilterSubmissionPapaers: (searchText, submissionPapers) => {
        // console.log({ searchText })
        if (!searchText) {
            return submissionPapers
        }

        return submissionPapers.filter(submissionPaper => {
            const re = new RegExp(searchText, "i")
            return re.test(JSON.stringify(submissionPaper))
        })
    }
    , batchDownloadAllFinalPapers: async function (conferenceUid, setFuncMap) {
        setFuncMap.setIsAllFinalPapersButtonLoading(true)
        const res = await this.batchDownloadAllFinalPapersAction(conferenceUid, {
            type: "all"
        })
        const type = res.headers['content-type']
        const blob = new Blob([res.data], { type: type })
        // console.log("Blob size:", blob.size);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `ICEI_${moment().format("yyyyMMDDHHmmss")}.zip`);
        document.body.appendChild(link);
        link.click();

        // 清理
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
        setFuncMap.setIsAllFinalPapersButtonLoading(false)
    }
    , batchDownloadAllFinalPapersAction: (conferenceUid, paylaod) => {
        return axios({
            method: 'POST',
            url: `/conference/${conferenceUid}/submission-papers/download/final-papers`,
            responseType: 'blob',
            headers: {
                "role": "topUser"
            },
            data: paylaod
        })
    }
    , batchDownloadSpecificFinalPapers: async function (conferenceUid, paperIds, setFuncMap) {
        setFuncMap.setIsSpecificFinalPapersButtonLoading(true)
        const res = await this.batchDownloadSpecificFinalPapersAction(conferenceUid, {
            paperIds
        })
        const type = res.headers['content-type']
        const blob = new Blob([res.data], { type: type })
        // console.log("Blob size:", blob.size);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `ICEI_${moment().format("yyyyMMDDHHmmss")}.zip`);
        document.body.appendChild(link);
        link.click();

        // 清理
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
        setFuncMap.setIsSpecificFinalPapersButtonLoading(false)
    }
    , batchDownloadSpecificFinalPapersAction: (conferenceUid, paylaod) => {
        return axios({
            method: 'POST',
            url: `/conference/${conferenceUid}/submission-papers/download/final-papers`,
            responseType: 'blob',
            headers: {
                "role": "topUser"
            },
            data: paylaod
        })
    }
};
