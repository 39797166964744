import React, { useEffect, useState } from 'react';
// import 'antd/dist/antd.css';
import { Table, Radio, message, Spin, Modal, Button, Row, Col, Card } from 'antd';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import efn from './ReviewerFns'
import './Reviewer.scss';
import ReviewerUpsertPage from './ReviewerUpsertPage'

function Reviewer(props) {
  const navigate = useNavigate()
  const location = useLocation()
  const [reviewers, setReviewers] = useState([])
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [modalConfig, setModalConfig] = useState({
    isOpen: false,
    title: "",
    content: ""
  })
  const [isButtonSortLoading, setIsButtonSortLoading] = useState(false)
  const setFuncMap = {
    message, setReviewers, setIsPageLoading, setModalConfig,
    setIsTableLoading, setIsButtonSortLoading
  }
  const columns = efn.generateReviewerMenu(props, setFuncMap, { navigate, location })
  const { feature, action } = useParams();

  useEffect(() => {
    efn.getReviewers(props.conferenceUid, setFuncMap)
  }, []);

  return (
    <Spin spinning={isPageLoading}>
      <div className="Reviewer">
        <Spin spinning={isTableLoading}>
          <Row justify={"end"}>
            <Col>
              <Button onClick={() => {
                efn.openReviewerCreatePage(props.conferenceUid, "建立審稿人員", setFuncMap)
              }}>建立審稿人員</Button>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table dataSource={reviewers} columns={columns} />
            </Col>
          </Row>
        </Spin>
        <Modal wrapClassName="modal-theme" title={modalConfig.title} open={modalConfig.isOpen} footer={[]} destroyOnClose
          onCancel={() => {
            setModalConfig({
              isOpen: false,
            })
          }}>
          {modalConfig.content}
        </Modal>
      </div>
    </Spin>
  );
}

export default Reviewer;
