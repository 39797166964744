import axios from 'axios';
import { toolUtils } from '../../../../utils/toolUtils.js'
import { Tag, Typography, Popconfirm, message, Spin, Modal, Button, Row, Col, Image } from 'antd';
import ReviewResultTypeUpsertPage from './ReviewResultTypeUpsertPage.js'
import moment from 'moment';
import {
    CloseOutlined,
    CheckCircleOutlined,
    DragOutlined
} from '@ant-design/icons';

export default {
    generateReviewResultTypeMoveMenu: function (props, setFuncMap) {
        let menu = this.generateReviewResultTypeMenu(props, setFuncMap)
        return [
            {
                title: ' ',
                dataIndex: 'sort',
                key: 'sortIcon',
                width: 60,
                render: (lang) => {
                    return <Tag color="#d8780a" key={lang}>
                        <DragOutlined />
                    </Tag>
                }
            }, ...menu]
    },
    generateReviewResultTypeMenu: function (props, setFuncMap) {
        const { Link } = Typography;
        return [
            {
                title: ' ',
                dataIndex: 'sequence',
                key: 'sequence',
                width: 20,
            },
            {
                title: '審稿結果類別',
                dataIndex: 'name',
                key: 'name',
                width: 400,
            },
            {
                title: "",
                dataIndex: 'id',
                key: 'id',
                width: 120,
                render: (_, render) => {
                    return <><Button size="small" style={{ marginRight: "5px" }}
                        onClick={() => { this.openReviewResultTypeUpdatePage(props.conferenceUid, render.id, "更新審稿結果類別", setFuncMap) }}>編輯</Button>
                        <Popconfirm
                            placement="left"
                            title={`確認要刪除 ${render.name}?`}
                            onConfirm={() => { this.deleteReviewResultType(render.id, props, setFuncMap) }}
                            okText="Yes"
                            cancelText="No"
                        ><Button size="small" type="primary" danger><CloseOutlined /></Button>
                        </Popconfirm>
                    </>
                }
            }]
    },
    getReviewResultTypes: async function (props, setFuncMap) {
        try {
            setFuncMap.setIsTableLoading(true)
            const res = await this.getReviewResultTypesAction(props.conferenceUid)
            let reviewResultTypes = res.data
            reviewResultTypes = this.getSortedReviewResultTypes(reviewResultTypes)
            reviewResultTypes = reviewResultTypes.map((reviewResultType, i) => {
                return { sequence: (i + 1), ...reviewResultType }
            })
            setFuncMap.setReviewResultTypes(reviewResultTypes)
            setFuncMap.setIsTableLoading(false)
            setFuncMap.setIsPageLoading(false)
        } catch (error) {
            setFuncMap.setIsTableLoading(false)
            // message.error(error.response.data)
        }
    }, getReviewResultTypesAction: (conferenceUid) => {
        return axios({
            method: 'GET',
            url: `/conference/${conferenceUid}/review-result-types`,
            headers: {
                "role": "topUser"
            }
        })
    },
    getConference: async function (props, setFuncMap) {
        try {
            const res = await this.getConferenceByUidAction(props.conferenceUid)
            props.setContentHeader(res.data.name)
            setFuncMap.setIsPageLoading(false)
        } catch (error) {
        }
    },
    getConferenceByUidAction: (conferenceUid) => {
        return axios({
            method: 'GET',
            url: `/conference/${conferenceUid}`,
            headers: {
                "role": "topUser"
            }
        })
    },
    openReviewResultTypeCreatePage: (conferenceUid, modalName, setFuncMap) => {
        setFuncMap.setIsModalOpen(true)
        setFuncMap.setModalTitle(modalName)
        setFuncMap.setModalContent(<ReviewResultTypeUpsertPage conferenceUid={conferenceUid} reviewResultTypeData={null} setFuncMap={setFuncMap} />)
    },
    openReviewResultTypeUpdatePage: (conferenceUid, id, modalName, setFuncMap) => {
        setFuncMap.setIsModalOpen(true)
        setFuncMap.setModalTitle(modalName)
        setFuncMap.setModalContent(<ReviewResultTypeUpsertPage conferenceUid={conferenceUid} id={id} reviewResultTypes={null} setFuncMap={setFuncMap} />)
    },
    getSortedReviewResultTypes: function (reviewResultTypes) {
        return reviewResultTypes.sort((a, b) => a.sort - b.sort)
    },
    updateReviewResultTypesSort: async function (payload, props, setFuncMap) {
        try {
            setFuncMap.setIsButtonSortLoading(true)
            setFuncMap.setIsTableLoading(true)
            const res = await this.updateReviewResultTypesSortAction(payload, props.conferenceUid)
            await this.getReviewResultTypes(props, setFuncMap)
            setFuncMap.setReviewResultTypesSortChange([])
            setFuncMap.setIsButtonSortLoading(false)
            setFuncMap.setIsTableLoading(false)
        } catch (error) {
        }
    },
    updateReviewResultTypesSortAction: (payload, conferenceUid) => {
        return axios({
            method: 'POST',
            url: `/conference/${conferenceUid}/review-result-types/sort`,
            headers: {
                "role": "topUser"
            },
            data: payload
        })
    },
    deleteReviewResultType: async function (id, props, setFuncMap) {
        try {
            setFuncMap.setIsTableLoading(true)
            const res = await this.deleteReviewResultTypeAction(id, props.conferenceUid)
            await this.getReviewResultTypes(props, setFuncMap)
            setFuncMap.setIsTableLoading(false)
        } catch (error) {
        }
    },
    deleteReviewResultTypeAction: (id, conferenceUid) => {
        return axios({
            method: 'DELETE',
            url: `/conference/${conferenceUid}/review-result-type/${id}`,
            headers: {
                "role": "topUser"
            }
        })
    }

};
