import axios from 'axios';
import { toolUtils } from '../../../../utils/toolUtils.js'
import { Tag, Typography, Popconfirm, message, Spin, Modal, Button, Row, Col, Card } from 'antd';
import UserUpsertPage from './UserUpsertPage.js'
import moment from 'moment';
import {
    CloseOutlined,
    CheckCircleOutlined,
    DragOutlined
} from '@ant-design/icons';

export default {
    generateUserMenu: function (props, setFuncMap, fn) {
        const { Text } = Typography;
        return [
            {
                title: '帳號',
                dataIndex: 'account',
                key: 'account',
                render: (_, render) => {
                    return <>
                        <div>{render.account}</div>
                        <div>{render.status == 0 ? <Tag color="#9dff75">正常</Tag> : <Tag color="#ffa2b1">禁用</Tag>}
                            {render.name}</div>
                    </>
                }
            },
            {
                title: '單位',
                dataIndex: 'unit',
                key: 'unit',
                render: (_, render) => {
                    return <>
                        <div>{`${render.unit}${render.department ? ` ${render.department}` : ""}${render.jobTitle ? ` ${render.jobTitle}` : ""}`}</div>
                        <div>{render.email}</div>
                    </>
                }
            },
            {
                title: ' ',
                dataIndex: 'conferences',
                key: 'conferences',
                render: (_, render) => {
                    return <>
                        {render.conferences.map(conf => {
                            return <div>{conf}</div>
                        })}
                    </>
                }
            },
            {
                title: "",
                dataIndex: 'id',
                key: 'id',
                width: 200,
                render: (_, render) => {
                    return <Row justify={"end"} gutter={4}>
                        <Col>
                            <Button size="small" style={{ marginRight: "5px" }}
                                onClick={() => { this.openUserUpdatePage(props.conferenceUid, render.id, "編輯使用者", setFuncMap) }}>編輯</Button>
                        </Col>
                        <Col>
                            <Popconfirm
                                placement="left"
                                title={`確認要刪除 ${render.name}?`}
                                onConfirm={() => {
                                    this.deleteUser(props.conferenceUid, render.id, setFuncMap)
                                }}
                                okText="Yes"
                                cancelText="No"
                            ><Button size="small" type="primary" danger><CloseOutlined /></Button>
                            </Popconfirm>
                        </Col>
                    </Row>
                }
            }]
    },
    generateSearchAttribute: function (defaultValue, conferenceUid, setFuncMap) {
        return [{
            label: "已投稿研討會",
            type: "select-multiple",
            key: "conferences",
            defaultValue: [conferenceUid],
            options: defaultValue.conferences.map(conf => {
                return {
                    value: conf.uid,
                    label: conf.name
                }
            }),
            rules: []
        }, {
            label: "投稿結果",
            type: "select-multiple",
            key: "submissionPaperResult",
            options: [{
                value: "不接受",
                label: "不接受"
            }, {
                value: "未審",
                label: "未審"
            }, ...defaultValue.submittedPaperTypes.map(paperType => {
                return {
                    value: paperType,
                    label: paperType
                }
            })],
            rules: []
        }]
    },
    getUsers: async function (conferenceUid, setFuncMap) {
        try {
            setFuncMap.setIsTableLoading(true)
            const res = await this.getUsersAction(conferenceUid, setFuncMap.form.getFieldsValue())
            let users = res.data
            setFuncMap.setUsers(users)
            setFuncMap.setIsTableLoading(false)
            setFuncMap.setIsPageLoading(false)
        } catch (error) {
            setFuncMap.setIsPageLoading(false)
            setFuncMap.setIsTableLoading(false)
            // message.error(error.response.data)
        }
    }, getUsersAction: (conferenceUid, payload) => {
        return axios({
            method: 'POST',
            url: `/conference/${conferenceUid}/users/list`,
            headers: {
                "role": "topUser"
            },
            data: payload
        })
    },
    getSearchUserCriteria: async function (conferenceUid, setFuncMap) {
        try {
            setFuncMap.setIsSearchFormLoading(true)
            const res = await this.getSearchUserCriteriaAction(conferenceUid)
            let criteria = res.data
            setFuncMap.setSearchFormCriteria(criteria)
            setFuncMap.setIsSearchFormLoading(false)
        } catch (error) {
            setFuncMap.setIsSearchFormLoading(false)
            // message.error(error.response.data)
        }
    }, getSearchUserCriteriaAction: (conferenceUid) => {
        return axios({
            method: 'GET',
            url: `/conference/${conferenceUid}/users/search-criteria`,
            headers: {
                "role": "topUser"
            }
        })
    },
    getLangSortedUsers: function (users, lang) {
        return users.filter(a => { return a.lang == lang }).sort((a, b) => a.sort - b.sort)
    },
    deleteUser: async function (conferenceUid, id, setFuncMap) {
        try {
            setFuncMap.setIsTableLoading(true)
            const res = await this.deleteUserAction(conferenceUid, id)
            await this.getUsers(conferenceUid, setFuncMap)
            setFuncMap.setIsTableLoading(false)
        } catch (error) {
            setFuncMap.setIsTableLoading(false)
        }
    },
    deleteUserAction: (conferenceUid, id) => {
        return axios({
            method: 'DELETE',
            url: `/conference/${conferenceUid}/user/${id}`,
            headers: {
                "role": "topUser"
            }
        })
    },
    submitVerifyUserAction: (id) => {
        return axios({
            method: 'PUT',
            url: `/submission-paper/${id}/sumbit-verify`,
            headers: {
                "role": "user"
            }
        })
    },
    openUserUpdatePage: (conferenceUid, id, modalName, setFuncMap) => {
        setFuncMap.setModalConfig({
            isOpen: true,
            title: modalName,
            content: <UserUpsertPage id={id} conferenceUid={conferenceUid} setFuncMap={setFuncMap} />
        })
    },
    getFilterUsers: (searchText, users) => {
        // console.log({ searchText })
        if (!searchText) {
            return users
        }

        return users.filter(user => {
            const re = new RegExp(searchText, "i")
            return re.test(JSON.stringify(user))
        })
    }

};
