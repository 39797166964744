import React, { useCallback, useRef, useState, useEffect } from 'react';
import { localStorageUtils } from '../../../utils/localStorageUtils.js';
import { socketUtils } from '../../../utils/socketUtils.js'
import { useLocation, useParams, Link as DOMLink, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import './ReviewerDashboard.scss';
import ReviewSubmissionPaper from './ReviewSubmissionPaper/ReviewSubmissionPaper.js'
import { Layout, Menu, Button, Tooltip, Typography, Dropdown, Avatar, Divider, Space, theme } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  LaptopOutlined,
  NotificationOutlined,
  DownOutlined,
} from '@ant-design/icons';

const { Header, Sider, Content, Footer } = Layout;
const { Text, Title } = Typography;
const { useToken } = theme;
const ReviewerDashboard = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [defaultValue, setDefaultValue] = useState({});
  const [contentHeader, setContentHeader] = useState(<></>);
  const { token } = useToken();
  const { module, conferenceUid, feature } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };
  const menuStyle = {
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 0'
  };
  const items = [
    {
      key: '1',
      label: defaultValue.account
    },
    {
      key: '2',
      label: (<a href="#" onClick={() => {
        localStorageUtils.remove("reviewer")
        navigate(`/mnt/reviewer/login`)
      }}>Logout</a>),
    }
  ];
  useEffect(() => {
    // 這段代碼只會在組件首次渲染後執行一次
    let reviewer = localStorageUtils.get("reviewer")
    if (reviewer) {
      if (reviewer.isChangePwd > 0) {
        navigate(`/mnt/reviewer/reset-password?fromPath=${location.pathname}`)
      }
      else {
        setDefaultValue({ account: reviewer.account })
      }
    }
    else {
      navigate(`/mnt/reviewer/login`)
    }
  }, []);
  return (
    <div className="ReviewerDashboard">
      <Layout style={{ height: '100vh' }}>
        <Layout className="site-layout">
          <Content style={{ margin: '4px', display: "flex", flexDirection: "column", flex: 1 }}>
            <Header className="site-layout-background"
              style={{ height: '32px', padding: '0 8px', background: '#fff', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Title level={5} style={{ margin: 0 }}>{contentHeader}</Title>
              <Dropdown placement="bottomRight"
                menu={{ items, }}
                dropdownRender={(menu) => (
                  <div style={contentStyle}>
                    {React.cloneElement(menu, {
                      style: menuStyle,
                    })}
                    {/* <Divider
                      style={{
                        margin: 0,
                      }}
                    />
                    <Space
                      style={{
                        padding: 8,
                      }}
                    >
                      <Button type="primary">Click me!</Button>
                    </Space> */}
                  </div>
                )}>
                <Button
                  type="link"
                  className="ant-dropdown-link"
                  style={{ display: 'flex', alignItems: 'center' }}
                  onClick={e => e.preventDefault()}>
                  <Avatar style={{ backgroundColor: '#87d068', width: '16px', height: '16px', fontSize: '0.5rem', marginRight: '10px' }} icon={<UserOutlined />} />
                  <Text ellipsis={true} style={{ width: '100px', color: '#434343', fontSize: '0.8rem' }}>{defaultValue.account}</Text>
                  <DownOutlined />
                </Button>
              </Dropdown>
            </Header>
            <div className="site-layout-background"
              style={{ display: "flex", flexDirection: "column", flex: 1, overflow: "auto", padding: 8 }}>
              <ReviewSubmissionPaper />
            </div>
          </Content>
          <Footer style={{ textAlign: 'center', padding: '4px 0' }}>©2024 Your Company Name. All Rights Reserved.</Footer>
        </Layout>
      </Layout>
    </div>
  );
};

export default ReviewerDashboard;

