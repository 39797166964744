import moment from 'moment';
import axios from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
export const toolUtils = {
    clone: function (obj) {
        return JSON.parse(JSON.stringify(obj))
    },
    convertFormToDatetime: function (data) {
        return data ? data.format() : null
    },
    convertFormToBool: function (data) {
        return data || false
    },
    convertStringToDatetime: function (data) {
        dayjs.extend(utc);
        return data ? dayjs(data).local() : null
        // return data ? dayjs(data, "YYYY-MM-DDTHH:mm:ss") : null
    },
    filterOption: (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
    ,
    calculateWords: (text) => {
        if (!text) {
            return
        }
        // 正則表達式，用於匹配中文字符
        let chineseRegex = /[\u4e00-\u9fa5]/g;

        // 正則表達式，用於匹配英文單詞
        let englishRegex = /\b[a-zA-Z0-9]+\b/g;

        // 匹配中文字符
        let chineseMatches = text.match(chineseRegex);

        // 匹配英文單詞
        let englishMatches = text.match(englishRegex);

        // 計算中文字符數量
        let chineseCount = chineseMatches ? chineseMatches.length : 0;

        // 計算英文單詞數量
        let englishCount = englishMatches ? englishMatches.length : 0;

        // console.log(`中文字符數量: ${chineseCount}`); // 中文字符數量: 10
        // console.log(`英文單詞數量: ${englishCount}`); // 英文單詞數量: 3
        return chineseCount + englishCount

    }
    , checkNumber: (text) => {
        const pattern = /^\d+$/;
        return pattern.test(text);
    },
    downloadFile: async function (fileUrl, fileName, setIsLoading) {
        try {
            setIsLoading(true)
            const response = await axios({
                url: `${fileUrl}?time=${new Date().getTime()}`, // 替換為實際的檔案 URL
                method: 'GET',
                responseType: 'blob', // 重要，確保下載的文件是 blob
            });

            // 創建一個URL來表示下載的檔案
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${fileName}`); // 替換為實際的檔案名稱
            link.target = "_blank"
            document.body.appendChild(link);
            link.click();

            // 移除link元素和釋放URL對象
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.error('下載檔案失敗:', error);
        }
    },
    handleUploadPreview: async function (file) {
        try {
            console.log({ file })
            const response = await axios({
                url: `${file.url}?time=${new Date().getTime()}`, // 替換為實際的檔案 URL
                method: 'GET',
                responseType: 'blob', // 重要，確保下載的文件是 blob
            });

            // 創建一個URL來表示下載的檔案
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${file.name}`); // 替換為實際的檔案名稱
            link.target = "_blank"
            document.body.appendChild(link);
            link.click();

            // 移除link元素和釋放URL對象
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('下載檔案失敗:', error);
        }
    }
}