import axios from 'axios';
import pfn from './InviteInfoFns.js'
import { toolUtils } from '../../../../utils/toolUtils.js'

export default {
  updateInviteInfo: async function (payload, props, setFuncMap) {
    try {
      setFuncMap.setIsContentLoading(true)
      if (payload.id) {
        await this.updateInviteInfoByIdAction(payload, props.conferenceUid)
      }
      else {
        await this.updateInviteInfoAction(payload, props.conferenceUid)
      }
      let res = await pfn.getInviteInfosAction(props.conferenceUid)
      setFuncMap.setIsContentLoading(false)
      setFuncMap.setLangInviteInfo(res.data.find(item => { return item.lang === payload.lang }))
    } catch (error) {
    }
  },
  updateInviteInfoAction: (payload, conferenceUid) => {
    return axios({
      method: 'POST',
      url: `/conference/${conferenceUid}/invite-info`,
      headers: {
        "role": "topUser"
      },
      data: payload
    })
  },
  updateInviteInfoByIdAction: (payload, conferenceUid) => {
    return axios({
      method: 'POST',
      url: `/conference/${conferenceUid}/invite-info/${payload.id}`,
      headers: {
        "role": "topUser"
      },
      data: payload
    })
  },
  getInviteInfo: async function (props, setFuncMap) {
    try {
      setFuncMap.setIsContentLoading(true)
      const res = await this.getInviteInfosAction(props.conferenceUid)
      let result = {}
      res.data.forEach(item => {
        result[item.lang] = item
      })
      setFuncMap.setInviteInfoMap(result)
      setFuncMap.setIsContentLoading(false)
    } catch (error) {
    }
  },
  getInviteInfoByIdAction: (conferenceUid) => {
    return axios({
      method: 'GET',
      url: `/conference/${conferenceUid}/invite-info`,
      headers: {
        "role": "topUser"
      }
    })
  },
};
