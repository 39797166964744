import axios from 'axios';
import { toolUtils } from '../../../../utils/toolUtils.js'
import { Tag, Typography, Popconfirm, message, Spin, Modal, Button, Row, Col, Card } from 'antd';
import {
    CloseOutlined,
    CheckCircleOutlined,
    DragOutlined
} from '@ant-design/icons';

export default {
    getConference: async function (props, setFuncMap) {
        try {
            const res = await this.getConferenceByUidAction(props.conferenceUid)
            props.setContentHeader(res.data.name)
            setFuncMap.setIsPageLoading(false)
        } catch (error) {
        }
    },
    getConferenceByUidAction: (conferenceUid) => {
        return axios({
            method: 'GET',
            url: `/conference/${conferenceUid}`,
            headers: {
                "role": "topUser"
            }
        })
    },
    getSubmissionNotes: async function (props, setFuncMap) {
        try {
            setFuncMap.setIsContentLoading(true)
            const res = await this.getSubmissionNotesAction(props.conferenceUid)
            let result = {}
            res.data.forEach(item => {
                result[item.lang] = item
            })
            setFuncMap.setSubmissionNoteMap(result)
            setFuncMap.setIsContentLoading(false)
        } catch (error) {
        }
    },
    getSubmissionNotesAction: (conferenceUid) => {
        return axios({
            method: 'GET',
            url: `/conference/${conferenceUid}/submission-note`,
            headers: {
                "role": "topUser"
            }
        })
    },
    updateSubmissionNote: async function (payload, props, setFuncMap) {
        try {
            setFuncMap.setIsContentLoading(true)
            const res = await this.updateSubmissionNoteAction(payload, props.conferenceUid)
            setFuncMap.setIsContentLoading(false)
        } catch (error) {
        }
    },
    updateSubmissionNoteAction: (payload, conferenceUid) => {
        return axios({
            method: 'POST',
            url: `/conference/${conferenceUid}/submission-note`,
            headers: {
                "role": "topUser"
            },
            data: payload
        })
    },
    getSubmissionNote: async function (props, setFuncMap) {
        try {
            setFuncMap.setIsContentLoading(true)
            const res = await this.getSubmissionNoteByIdAction(props.conferenceUid)
            let result = {}
            res.data.forEach(item => {
                result[item.lang] = item
            })
            setFuncMap.setSubmissionNoteMap(result)
            setFuncMap.setIsContentLoading(false)
        } catch (error) {
        }
    },
    getSubmissionNoteByIdAction: (conferenceUid) => {
        return axios({
            method: 'GET',
            url: `/conference/${conferenceUid}/submission-note`,
            headers: {
                "role": "topUser"
            }
        })
    },
};
