import React, { useEffect, useState } from 'react';
// import 'antd/dist/antd.css';

import { Input, Radio, message, Spin, Modal, Button, Row, Col, Form } from 'antd';
import CKEditorUtil from '../../../../utils/ckEditorUtil'
import efn from './ReviewNoteUpsertPageFns'
import moment from 'moment'

function ReviewNoteUpsertPage(props) {
  const { lang, reviewNoteMap } = props
  const [form] = Form.useForm();
  const { TextArea } = Input
  const [langReviewNote, setLangReviewNote] = useState()
  const [editorData, setEditorData] = useState();
  let setFuncMap = { setLangReviewNote, setEditorData, ...props.setFuncMap }
  const onFinish = values => {
    values["lang"] = lang
    values["content"] = editorData
    efn.updateReviewNote(values, props, setFuncMap)
  };

  useEffect(() => {
    setLangReviewNote(reviewNoteMap[lang])
    form.setFieldsValue(reviewNoteMap[lang])
  }, [reviewNoteMap])

  useEffect(() => {
    form.setFieldsValue(langReviewNote)
  }, [langReviewNote])

  return (
    <Form
      form={form}
      layout="horizontal"
      onFinish={onFinish}
    >
      <CKEditorUtil setFuncMap={setFuncMap} defaultValue={langReviewNote ? langReviewNote.content : null} />
      <Form.Item
        name="id"
        hidden
      >
        <Input type="hidden" />{langReviewNote && langReviewNote.lastUpdateAt && `最後更新時間: ${moment(langReviewNote.lastUpdateAt).format("yyyy-MM-DD HH:mm:ss")}`}
      </Form.Item>
      <Row justify={"center"} gutter={16}>
        <Col>
          <Button type="primary" htmlType="submit">
            更新
          </Button>
        </Col>
      </Row>

    </Form>
  );
}



export default React.memo(ReviewNoteUpsertPage);
