import axios from 'axios';
import { toolUtils } from '../../../../../utils/toolUtils.js'
import { Tag, Typography, Popconfirm, message, Spin, Modal, Button, Row, Col, Card } from 'antd';
import {
    CloseOutlined,
    CheckCircleOutlined,
    DragOutlined
} from '@ant-design/icons';

export default {
    generateProfileAttributes: function (nationalities) {
        return [{
            label: "帳號",
            type: "text",
            name: "account",
            required: true,
            rules: []
        },
        {
            label: "Email",
            type: "input",
            name: "email",
            required: true,
            rules: [{ type: "email", message: 'It\'s not email format' }]
        },
        {
            label: "姓名",
            type: "input",
            name: "name",
            required: true,
            rules: []
        },
        {
            label: "國籍",
            type: "select",
            name: "nationality",
            required: true,
            options: [{
                label: "",
                value: null
            }, ...nationalities.map(item => ({
                label: item.name,
                value: item.id
            }))],
            rules: []
        },
        {
            label: "單位",
            type: "input",
            name: "unit",
            required: true,
            rules: []
        },
        {
            label: "部門",
            type: "input",
            name: "department",
            required: false,
            rules: []
        },
        {
            label: "電話",
            type: "input",
            name: "telephoneNumber",
            required: true,
            rules: []
        },
        {
            label: "行動電話",
            type: "input",
            name: "cellphoneNumber",
            required: true,
            rules: []
        },
        {
            label: "ZIP",
            type: "input",
            name: "zipCode",
            required: true,
            rules: []
        },
        {
            label: "地址",
            type: "input",
            name: "address",
            required: true,
            rules: []
        },
        {
            label: "語系",
            type: "select",
            name: "lang",
            required: false,
            rules: [],
            options: [{
                label: "中文",
                value: "CN"
            }, {
                label: "English",
                value: "EN"
            }],
        },]
    },
    getUserProfile: async function (setFuncMap) {
        try {
            setFuncMap.setIsPageLoading(true)
            const res = await this.getUserProfileAction()
            setFuncMap.setFieldsValue(res.data)
            setFuncMap.setIsPageLoading(false)
        } catch (error) {
            setFuncMap.setIsPageLoading(false)
            // message.error(error.response.data)
        }
    }, getUserProfileAction: () => {
        return axios({
            method: 'GET',
            url: `/user/`,
            headers: {
                "role": "user"
            }
        })
    },
    getNationalities: async function (setFuncMap) {
        try {
            setFuncMap.setIsPageLoading(true)
            const res = await this.getNationalitiesAction()
            setFuncMap.setNationalities(res.data)
            setFuncMap.setIsPageLoading(false)
        } catch (error) {
            setFuncMap.message.open({
                type: 'error',
                content: setFuncMap.t(`error.${error.response.status}`),
                duration: 2,
            });
            setFuncMap.setIsPageLoading(false)
        }
    },
    getNationalitiesAction: () => {
        return axios({
            method: 'GET',
            url: `/site/nationalities`
        })
    },
    updateUserProfile: async function (payload, setFuncMap) {
        try {
            console.log({payload})
            setFuncMap.setIsPageLoading(true)
            setFuncMap.setIsButtonLoading(true)
            await this.updateUserProfileAction(payload)
            setFuncMap.setIsPageLoading(false)
            setFuncMap.setIsButtonLoading(false)
            setFuncMap.message.open({
                key: "update",
                type: 'success',
                content: setFuncMap.t(`system.updateSuccess`),
                duration: 2,
            });            
        } catch (error) {
            setFuncMap.message.open({
                type: 'error',
                content: setFuncMap.t(`error.${error.response.status}`),
                duration: 2,
            });
            setFuncMap.setIsPageLoading(false)
            setFuncMap.setIsButtonLoading(false)
        }
    },
    updateUserProfileAction: (payload) => {
        return axios({
            method: 'POST',
            url: `/user/update`,
            headers: {
                "role": "user"
            },
            data: payload
        })
    },
};
