import React, { useEffect, useState } from 'react';
import { DataContext } from '../../../Context/DataContext';
// import 'antd/dist/antd.css';
import { Input, Table, message, Spin, Modal, Button, Row, Col, Form } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import efn from './ApprovedPapersFns'
import './ApprovedPapers.scss';
import moment from 'moment';


function ApprovedPapers(props) {
    const { Search } = Input;
    const { globalData } = React.useContext(DataContext)
    const navigate = useNavigate()
    const location = useLocation()
    const { TextArea } = Input
    const [approvedPapers, setApprovedPapers] = useState([])
    const [searchKey, setSearchKey] = useState()
    const [isPageLoading, setIsPageLoading] = useState(true)
    const setFuncMap = {
        message, setIsPageLoading, setApprovedPapers
    }
    useEffect(() => {
        efn.getApprovedPapers(setFuncMap)
    }, [globalData.langChangeTime]);
    return (
        <Spin spinning={isPageLoading}>
            <div className="UserApprovedPapers">
                <Row>
                    <Col>
                        <Search onSearch={(val) => {
                            setSearchKey(val)
                        }} style={{ width: "500px" }} allowClear />
                    </Col>
                    <Col span={24}>
                        <Table showHeader={false} dataSource={filterApprovedPapers(approvedPapers, searchKey)} columns={efn.generateApprovedSubmissionPaperMenu()} />
                    </Col>
                </Row>
            </div>
        </Spin>
    );
}

const filterApprovedPapers = function (approvedPapers, searchKey) {
    if (searchKey) {
        return approvedPapers.filter(approvedPaper => {
            console.log("aa")
            return JSON.stringify(approvedPaper).match(new RegExp(searchKey,"i"))
        })
    }
    return approvedPapers
}



export default ApprovedPapers;
