import axios from 'axios';
import { toolUtils } from '../../../../utils/toolUtils.js'
import { Tag, Typography, Popconfirm, message, Spin, Modal, Button, Row, Col, Card } from 'antd';
import FileUpsertPage from './FileUpsertPage.js'
import moment from 'moment';
import {
    CloseOutlined,
    CheckCircleOutlined,
    DragOutlined
} from '@ant-design/icons';

export default {
    generateFileMoveMenu: function (props, setFuncMap) {
        let menu = this.generateFileMenu(props, setFuncMap)
        return [
            {
                title: ' ',
                dataIndex: 'sort',
                key: 'sortIcon',
                width: 60,
                render: (lang) => {
                    return <Tag color="#d8780a" key={lang}>
                        <DragOutlined />
                    </Tag>
                }
            }, ...menu]
    },
    generateFileMenu: function (props, setFuncMap) {
        const { Link } = Typography;
        return [
            {
                title: '語系',
                dataIndex: 'lang',
                key: 'lang',
                width: 60,
                render: (lang) => {
                    return <Tag color={lang === "EN" ? "green" : "purple"} key={lang}>
                        {lang ? lang.toUpperCase() : null}
                    </Tag>
                }
            },
            {
                title: '顯示檔名',
                dataIndex: 'name',
                key: 'name',
                render: (_, render) => {
                    return <Link href={`${window.CONFS.cloudDomain}/${render.fileName}`}
                    target='_blank'>
                        {`${render.name}${render.ext}`}
                        </Link>
                }
            },
            {
                title: '備註',
                dataIndex: 'remark',
                key: 'remark',
            }, {
                title: "",
                dataIndex: 'id',
                key: 'id',
                width: 120,
                render: (_, render) => {
                    return <><Button size="small" style={{ marginRight: "5px" }}
                        onClick={() => { this.openFileUpdatePage(props.conferenceUid, render.id, "更新檔案", setFuncMap) }}>編輯</Button>
                        <Popconfirm
                            placement="left"
                            title={`確認要刪除 ${render.name}?`}
                            onConfirm={() => { this.deleteFile(render.id, props, setFuncMap) }}
                            okText="Yes"
                            cancelText="No"
                        ><Button size="small" type="primary" danger><CloseOutlined /></Button>
                        </Popconfirm>
                    </>
                }
            }]
    },
    getFiles: async function (props, setFuncMap) {
        try {
            setFuncMap.setIsTableLoading(true)
            const res = await this.getFilesAction(props.conferenceUid)
            let files = res.data
            setFuncMap.setFiles(files)
            setFuncMap.setIsTableLoading(false)
            setFuncMap.setIsPageLoading(false)
        } catch (error) {
            setFuncMap.setIsTableLoading(false)
            // message.error(error.response.data)
        }
    }, getFilesAction: (conferenceUid) => {
        return axios({
            method: 'GET',
            url: `/conference/${conferenceUid}/files`,
            headers: {
                "role": "topUser"
            }
        })
    },
    getConference: async function (props, setFuncMap) {
        try {
            const res = await this.getConferenceByUidAction(props.conferenceUid)
            props.setContentHeader(res.data.name)
            setFuncMap.setIsPageLoading(false)
        } catch (error) {
        }
    },
    getConferenceByUidAction: (conferenceUid) => {
        return axios({
            method: 'GET',
            url: `/conference/${conferenceUid}`,
            headers: {
                "role": "topUser"
            }
        })
    },
    openFileCreatePage: (conferenceUid, modalName, setFuncMap) => {
        setFuncMap.setIsModalOpen(true)
        setFuncMap.setModalTitle(modalName)
        setFuncMap.setModalContent(<FileUpsertPage conferenceUid={conferenceUid} fileData={null} setFuncMap={setFuncMap} />)
    },
    openFileUpdatePage: (conferenceUid, id, modalName, setFuncMap) => {
        setFuncMap.setIsModalOpen(true)
        setFuncMap.setModalTitle(modalName)
        setFuncMap.setModalContent(<FileUpsertPage conferenceUid={conferenceUid} id={id} fileData={null} setFuncMap={setFuncMap} />)
    },
    getLangSortedFiles: function (files, lang) {
        return files.filter(a => { return a.lang == lang }).sort((a, b) => a.sort - b.sort)
    },
    updateFilesSort: async function (mode, payload, props, setFuncMap) {
        try {
            setFuncMap.setIsButtonSortLoading(true)
            setFuncMap.setIsTableLoading(true)
            const res = await this.updateFilesSortAction(payload, props.conferenceUid)
            await this.getFiles(props, setFuncMap)
            if (mode === "EN") {
                setFuncMap.setEnSortChange([])
            }
            if (mode === "EN") {
                setFuncMap.setCnSortChange([])
            }
            setFuncMap.setIsButtonSortLoading(false)
            setFuncMap.setIsTableLoading(false)
        } catch (error) {
        }
    },
    updateFilesSortAction: (payload, conferenceUid) => {
        return axios({
            method: 'POST',
            url: `/conference/${conferenceUid}/files/sort`,
            headers: {
                "role": "topUser"
            },
            data: payload
        })
    },
    deleteFile: async function (id, props, setFuncMap) {
        try {
            setFuncMap.setIsTableLoading(true)
            const res = await this.deleteFileAction(id, props.conferenceUid)
            await this.getFiles(props, setFuncMap)
            setFuncMap.setIsTableLoading(false)
        } catch (error) {
        }
    },
    deleteFileAction: (id, conferenceUid) => {
        return axios({
            method: 'DELETE',
            url: `/conference/${conferenceUid}/file/${id}`,
            headers: {
                "role": "topUser"
            }
        })
    }

};
