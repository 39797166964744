import React, { useEffect, useState } from 'react';
import { DataContext } from '../../../Context/DataContext';
import { Menu, Select, Row, Col } from 'antd';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { localStorageUtils } from '../../../utils/localStorageUtils'
import efn from './HomePageFns'
import cn from './icons/cn.png'
import en from './icons/en.png'
import './Navigation.scss';
import i18next from 'i18next';

function Navigation({ userData, isPublishReviewResult }) {
  const { globalData, globalDataDispatch, t } = React.useContext(DataContext)
  const [current, setCurrent] = useState('mail');
  const [scrollTopValue, setScrollTopValue] = useState(0);
  const navigate = useNavigate()
  const location = useLocation();
  const [currentLang, setCurrentLang] = useState(localStorageUtils.get("lang") || "CN");
  const [mobileMenus, setMobileMenus] = useState(efn.generateMenus(location, isPublishReviewResult, userData, t, "mobile"));
  const [webMenus, setWebMenus] = useState(efn.generateMenus(location, isPublishReviewResult, userData, t, "web"));


  useEffect(() => {
    const container = document.querySelector('.HomePage')
    const handleScroll = ({ target }) => {
      setScrollTopValue(target.scrollTop)
    }
    container.addEventListener('scroll', handleScroll, false)
    return () => container.removeEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
    setWebMenus(efn.generateMenus(location, isPublishReviewResult, localStorageUtils.get("user"), t, "web"))
    setMobileMenus(efn.generateMenus(location, isPublishReviewResult, localStorageUtils.get("user"), t, "mobile"))
  }, [location.search, location.pathname, globalData.langChangeTime, isPublishReviewResult])

  const onClick = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
  };
  return (
    <div className={`Navigation ${scrollTopValue > 200 ? "fixed" : ""}`}>
      <Row justify={"space-around"} style={{ alignItems: "center", flex: 1 }}>
        <div class="menu">
          <div class="menu-mobile">
            <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={mobileMenus} />
          </div>
          <div class="menu-web">
            <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={webMenus} />
          </div>
        </div>
        <div className="i18n-area">
          <div className="i18n-select-ui">
            <Select style={{ width: "100px" }}
              onChange={(value) => {
                localStorageUtils.save("lang", value)
                globalDataDispatch({ langChangeTime: new Date().getTime() })
                i18next.changeLanguage(value, (err, t) => {
                  if (err) return console.log('something went wrong loading', err);
                  t('key'); // -> same as i18next.t
                });
              }}
              defaultValue={currentLang}
              options={[{
                label: t('home.lang.chinese'),
                value: "CN"
              }, {
                label: t('home.lang.english'),
                value: "EN"
              }]}></Select>
          </div>
          <div className="i18n-select-h5">
            <Select style={{ width: "70px" }}
              onChange={(value) => {
                localStorageUtils.save("lang", value)
                globalDataDispatch({ langChangeTime: new Date().getTime() })
                i18next.changeLanguage(value, (err, t) => {
                  if (err) return console.log('something went wrong loading', err);
                  t('key'); // -> same as i18next.t
                });
              }}
              defaultValue={currentLang}
              options={[{
                label: <img src={cn} width="20px" />,
                value: "CN"
              }, {
                label: <img src={en} width="20px" />,
                value: "EN"
              }]}></Select>
          </div>
        </div>
      </Row>
    </div>
  );
}

export default Navigation;
