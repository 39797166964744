import React, { useCallback, useState, useEffect } from 'react';
// import 'antd/dist/antd.css';
import { Form, Input, Button, Typography, Row, Col, Layout, Card, message } from 'antd';
import { localStorageUtils } from '../../../../utils/localStorageUtils'
import axios from 'axios';
import './ResetPassword.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';

const { Title, Text } = Typography;
const { Footer } = Layout;


function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSending, setIsSending] = useState(false)
  let params = queryString.parse(location.search)
  let topUser = localStorageUtils.get("topUser")
  useEffect(()=>{
    if(!topUser){
      navigate("/mnt/top-user/login")
    }
  },[])
  const onFinish = (values) => {
    setIsSending(true)
    axios.put('/top_user/update_pwd', values, {
      headers: {
        role: "topUser"
      }
    }).then(function (response) {
      message.open({
        type: 'success',
        content: 'Update success',
        duration: 2,
      })      
      localStorageUtils.save("topUser", { ...topUser, isChangePwd: 0 })
      setIsSending(false)
      if (params.fromPath) {
        navigate(params.fromPath)
      }
      else {
        navigate(`/mnt/top-user/`);
      }
    }).catch(function (error) {
      message.open({
        type: 'error',
        content: error.response.data.message,
        duration: 2,
      })
      setIsSending(false)
      console.log(error);
    });
  };
  return (
    <div className="TopUserResetPassword">
      <Layout style={{ minHeight: '100vh' }}>
        <Row justify="center" align="middle" style={{ height: 'calc(100vh - 69px)', background: '#f5f5f5' }}>
          <Col xs={22} sm={16} md={12} lg={8} xl={6}>
            <Card style={{ borderRadius: '8px' }}>
              <Title level={2} style={{ textAlign: 'center', marginBottom: '24px' }}>ICEI Admin Forget Password</Title>
              <Form
                name="google_login"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                layout="vertical"
              >
                <Form.Item
                  name="oldPwd"
                  rules={[{ required: true, message: 'Please input your old password!' }]}
                >
                  <Input.Password placeholder="Old password" size="large" />
                </Form.Item>
                <Form.Item
                  name="pwd"
                  rules={[{ required: true, message: 'Please input your new password!' }]}
                >
                  <Input.Password placeholder="New password" size="large" />
                </Form.Item>
                <Form.Item
                  name="pwd_check"
                  rules={[{ required: true, message: 'Please input your new password!' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('pwd') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The two passwords do not match!'));
                    },
                  })
                  ]}
                >
                  <Input.Password placeholder="New password" size="large" />
                </Form.Item>
                <Form.Item>
                  <Button loading={isSending} type="primary" htmlType="submit" style={{ width: '100%', marginTop: '32px' }} size="large">
                    Update
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
        <Footer style={{ textAlign: 'center' }}>©2024 Your Company Name. All Rights Reserved.</Footer>
      </Layout>
    </div>
  );
}

export default ResetPassword;
